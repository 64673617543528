<template>
  <div class="tax">
    <h1>Tax Rates</h1>
    <template v-if="!isFilterActive">
      <BaseButton text="Filter" @click="filterToggle" />
    </template>
    <div v-else class="card">
      <div class="card-divider" style="justify-content: space-between;">
        <h3 class="no-margin">Filter</h3>
        <BaseButton text="X" class="button button--close" @click="filterToggle" />
      </div>
      <div class="card-section gid-container">
        <form @submit.prevent="filterResults" class="grid-x grid-margin-x">
          <div class="cell medium-4 large-3">
            <BaseInput
              v-model="filter.q"
              label="Search"
              type="search"
              name="q"
             />
          </div>

          <div class="cell medium-4 large-3">
            <BaseButton type="submit" text="Filter" style="margin-top: 27px;" />
          </div>
        </form>
      </div>
    </div>
    <router-link :to="{ name: 'tax-create'}">
      <BaseButton text="Add new tax rate" />
    </router-link>

    <p v-if="isLoading">Loading...</p>
    <template v-else >
      <div>
        <ListLoop v-for="(item) in tax.taxes" :key="item.id" :item="item" route="tax-detail" itemName="title" />
      </div>

      <Pagination :page="page" :perPage="perPage" routeName="tax" :total="totalTaxes" />
    </template>

  </div>
</template>

<script>
    import ListLoop from '@/components/ListLoop.vue'
    import Pagination from '@/components/Pagination.vue'
    import { mapState } from 'vuex'

    export default {
        data() {
          return {
            isFilterActive: false,
            filter: {
              q: '',
              perPage: 10,
              page: parseInt(this.$route.query.page) || 1
            }
          }
        },
        components: {
          ListLoop,
          Pagination
        },
        created() {
          this.$store.dispatch('tax/fetchTaxes', this.filter)
        },
        computed: {
          perPage(){
            return this.filter.perPage;
          },
          page() {
            return this.filter.page;
          },
          totalTaxes() {
            return this.tax.taxesTotal
          },
          isLoading() {
            return this.tax.isLoading
          },
          ...mapState(['tax'])
        },
        methods : {
          filterToggle() {
            this.isFilterActive = (this.isFilterActive) ? false : true
          },
          filterResults() {
            this.$store.dispatch('tax/fetchTaxes', this.filter)
          }
        }

    }
</script>
