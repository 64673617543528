<template v-if="!isLoading">
  <div class="card">
    <div class="card-divider" style="justify-content: space-between">
      <div>
        <BaseButton v-show="isParentActive" class="button button--minus" text="Minimise" @click="toggleItem()"/>
        <BaseButton v-show="!isParentActive" class="button button--plus" text="Expand" @click="toggleItem()"/>
        <BaseButton v-if="fieldKey" class="button button--up" text="Move Up" @click="orderItem('up')"/>
        <BaseButton v-if="!isLast" class="button button--down" text="Move Down" @click="orderItem('down')"/>
        <h4 @click="toggleItem()" >{{ setting.data[fieldKey].name }}</h4>
      </div>

      <div>
        <BaseButton class="button button--remove" text="Remove" @click="removeItem()"/>
      </div>
    </div>
    <div class="card-section" v-show="isParentActive">
      <BaseInput
        v-model="setting.data[fieldKey].name"
        label="Name"
        name="name"
        @blur="save"
      />
      <template v-if="modelName != 'list'">
        <BaseAutoComplete
          v-if="!setting.data[fieldKey].item"
          v-model="setting.data[fieldKey].item"
          :options="available"
          subTitle="title"
          placeholder="Search to assign..."
          v-on:update:modelValue="save"
         />
        <span class="tag" v-else >{{ setting.data[fieldKey].item.title || 'N/A' }} <BaseButton class="button button--remove" v-model="setting.data[fieldKey].item" @click.prevent="setting.data[fieldKey].item = null" text="Remove" /></span>
      </template>
    </div>
  </div>
</template>

<script>
    import { mapState } from 'vuex'


    export default {
      props: {

          modelName: {
            type: String,
            default: 'content'
          },
          field: {
            type: Object,
            required: true
          },
          fieldKey: {
            type: Number,
            required: true
          },
          fieldTotal: {
            type: Number,
            required: true
          },
      },
      data() {
          return {
            isActive: [],
            isParentActive: false,
          }
      },
      computed: {
        ...mapState({
          setting: state => state.setting.setting,
          isLoading: state => state.setting.isLoading,
          available (state) {
            return state[this.modelName] ? state[this.modelName].fullList : [];
          }
        }),
        fields() {
          var data = [];

          if (this.setting.data.length) {

            this.setting.data.forEach((field) => {
              console.log(field)
              data.push({ value: field.name, name: field.label })
            })

            console.log(data)
          }

          return data
        },
        fieldOptions() {
          var data = [];
          this.setting.data.forEach((field) => {
            data[field.name] = field.subItems
          })

          return data
        },
        isLast() {
          return (this.fieldTotal-1) === this.fieldKey
        }
      },
      methods: {
        save() {
          console.log(this.setting)
          this.$store.dispatch('error/clear')
          this.$store.dispatch('setting/updateSetting', {...this.setting})

          console.log(this.setting)
        },
        saveNewOption() {
          if (this.setting.data[this.fieldKey].subItems === null) {
            this.setting.data[this.fieldKey].subItems = []
          }
          this.setting.data[this.fieldKey].subItems.push(this.newSubItem)
          this.save()


          this.newSubItem = {
              value: '',
              name: ''
            }
        },
        removeOption(key) {
          this.setting.data[this.fieldKey].subItems.splice(key, 1)
          this.save()
        },
        editOption(key) {
          const index = this.isActive.indexOf(key);
          if (index > -1) {
            this.isActive.splice(index, 1);
          } else {
            this.isActive.push(key);
          }

        },

        removeItem() {
          this.setting.data.splice(this.fieldKey, 1);
          this.save();
        },
        toggleItem() {
          this.isParentActive = this.isParentActive ? false : true
        },
        orderItem(direction) {
          var toIndex = (direction == 'up') ? (this.fieldKey-1) : (this.fieldKey+1);
          var element = this.setting.data[this.fieldKey];
          this.setting.data.splice(this.fieldKey, 1);
          this.setting.data.splice(toIndex, 0, element);
          this.save();
        }
      }
    }
</script>

<style scoped lang="scss">
  .field-group {
    border-bottom: 1px #000 solid;
    padding: 10px 0;

    &:last-of-type {
      border-bottom: 0;
    }
  }

  .card-divider {
    display: flex;
    justify-content: space-between;
  }
</style>
