import axios from 'axios'

const apiClientAuth = axios.create({
    baseURL: process.env.VUE_APP_API_URL + 'api',
    withCredentials: false,
    headers : {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
})

export default {
    login(credentials) {
        return apiClientAuth.post('/auth/login', credentials)
    },
    getFormOptions() {
        return new apiClientAuth.get('/auth/formoptions')
    },
}
