<template v-if="!isLoading">
  <div>
    <template v-if="field.conditional === undefined || ((field.conditional !== undefined) && field.conditional.value.includes(content.blocks[templateKey].fields[field.conditional.parent][subFieldKey].value))">
      <template v-if="field.type === 'text'">
        <BaseInput
          v-model="content.blocks[templateKey].fields[field.name][subFieldKey].value"
          :label="field.label"
          :name="field.name"
          @change="save"
        />
      </template>
      <template v-if="field.type === 'textarea'">
        <BaseTextarea
          v-model="content.blocks[templateKey].fields[field.name][subFieldKey].value"
          :label="field.label"
          :name="field.name"
          @change="save"
        />
      </template>
      <template v-if="field.type === 'editor'">
        <BaseEditor
          v-model="content.blocks[templateKey].fields[field.name][subFieldKey].value"
          :label="field.label"
          :name="field.name"
          :save="save"
        />
      </template>
      <template v-if="field.type === 'colour'">
        <div class="grid-x grid-margin-x">
          <div class="cell small-10">
            <BaseInput
              v-model="content.blocks[templateKey].fields[field.name][subFieldKey].value"
              :label="field.label"
              :name="field.name"
              type="color"
              @change="save"
            />
          </div>
          <div class="cell small-2">
            <BaseButton text="Reset" @click="resetColour" class="button button--small button--margin" />
          </div>
        </div>
      </template>
      <template v-if="field.type === 'link'">
        <BaseInputLink
          v-model="content.blocks[templateKey].fields[field.name][subFieldKey].value"
          :label="field.label"
          :name="field.name"
          @change="save"
        />
      </template>
      <template v-if="field.type === 'image'">
        <span>Image:</span>
        <MediaReveal
          v-model="content.blocks[templateKey].fields[field.name][subFieldKey].value"
          :save="save"
          :uniqueKey="'block' + templateKey + '-' + field.name + '-' + subFieldKey"
        />
      </template>
      <template v-if="field.type === 'select'">
        <BaseSelect
          :options="field.options"
          v-model="content.blocks[templateKey].fields[field.name][subFieldKey].value"
          :label="field.label"
          optionValue="value"
          optionName="name"
          @change="save"
         />
      </template>
      <template v-if="field.type === 'repeater'">
        <div class="card">
          <div class="card-divider" style="justify-content: space-between">
            <div style="display: flex;">
              <BaseButton v-show="isActive" class="button button--minus" text="Minimise" @click="toggleBlock()"/>
              <BaseButton v-show="!isActive" class="button button--plus" text="Expand" @click="toggleBlock()"/>
              <h5 @click="toggleBlock()">{{ field.label }}</h5>
            </div>
            <BaseButton text="Add Row" @click="duplicateSelf" />
          </div>
          <div class="card-section" v-show="isActive">
            <div class="card" v-for="(subFieldArray, subFieldArrayKey) in field.fields" :key="subFieldArray.key">
              <div class="card-divider" style="justify-content: space-between">
                <div>
                  <BaseButton v-show="isActiveSub.includes(subFieldArrayKey)" class="button button--minus" text="Minimise" @click="toggleSubBlock(subFieldArrayKey)"/>
                  <BaseButton v-show="!isActiveSub.includes(subFieldArrayKey)" class="button button--plus" text="Expand" @click="toggleSubBlock(subFieldArrayKey)"/>
                  <BaseButton v-if="subFieldArrayKey && allowOrder" class="button button--up" text="Move Up" @click="orderSubBlock(subFieldArrayKey, 'up')"/>
                  <BaseButton v-if="field.fields.length-1 > subFieldArrayKey && allowOrder" class="button button--down" text="Move Down" @click="orderSubBlock(subFieldArrayKey, 'down')"/>
                  <span v-if="content.blocks[templateKey].fields['colour_combinations_column_title'] !== undefined">
                    <h5 v-if="content.blocks[templateKey].fields['colour_combinations_column_title'][subFieldArrayKey].value"  @click="toggleSubBlock(subFieldArrayKey)">{{ content.blocks[templateKey].fields['colour_direction_colour_title'][subFieldArrayKey].value }}</h5>
                    <h5 v-else>Column: {{ subFieldArrayKey + 1 }}</h5>
                  </span>
                </div>
                <BaseButton v-if="subFieldArrayKey && allowRemove" class="button button--remove" text="Remove" @click="removeSubBlock(subFieldArrayKey)"/>
              </div>
              <div class="card-section" v-show="isActiveSub.includes(subFieldArrayKey)">
                <ContentBlockField  v-for="(subField) in field.fields[subFieldArrayKey]" :key="subField.name" :templateKey="templateKey" :field="subField" :fieldKey="fieldKey" :subFieldKey="subFieldArrayKey" :allowOrder="allowOrder" :allowRemove="allowRemove" :model="model" />
              </div>
            </div>
            <div style="display: flex;">
              <BaseButton text="Add Row" @click="duplicateSelf" />
              <BaseButton class="button button--outline" :text="'Close '+field.label" @click="toggleBlock()" />
            </div>
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
    import { mapState } from 'vuex'
    import MediaReveal from '@/components/MediaReveal.vue'


    export default {
      data() {
          return {
            isActive: true,
            isActiveSub: []
          }
      },
      components: {
        MediaReveal
      },
      name: 'ContentBlockField',
        props: {
            field: {
              type: Object,
              required: true
            },
            fieldKey: {
              type: Number,
              required: true
            },
            templateKey: {
              type: Number,
              required: true
            },
            subFieldKey:{
              type: Number,
              default: 0
            },
            allowOrder: {
              type: Boolean,
              default: false
            },
            allowRemove: {
              type: Boolean,
              default: false
            },
            model: {
              type: String,
              default: 'content'
            }
        },
      computed: {
        ...mapState({
          content(state) {
            return state[this.model][this.model]
          },
          isLoading: state => state.isLoading,
          selectedMedia: state => state.media.selectedMedia
        })
      },
      methods: {
        duplicateSelf() {
          const data = {
            key: this.templateKey,
            field: this.field.fields[0],
            repeaterKey: this.fieldKey
          }
          this.$store.dispatch(this.model + '/duplicateField', data)

        },
        save() {
          this.$store.dispatch('error/clear')
          if (this.content.blocks[this.templateKey].id) {
            this.$store.dispatch(this.model + '/updateBlock', {key: this.templateKey, data: this.content.blocks[this.templateKey]})
          } else {
            this.$store.dispatch(this.model + '/saveBlock', {key: this.templateKey, data: this.content.blocks[this.templateKey]})
          }
        },
        removeSubBlock(subFieldArrayKey) {
          this.$store.dispatch(this.model + '/deleteSubBlock', { fieldKey: this.fieldKey, fieldName: this.field.name, templateKey: this.templateKey, subFieldKey: subFieldArrayKey })
        },
        orderSubBlock(subFieldArrayKey, direction) {
          this.$store.dispatch(this.model + '/orderSubBlock', { fieldKey: this.fieldKey, fieldName: this.field.name, templateKey: this.templateKey, subFieldKey: subFieldArrayKey, direction: direction })
        },
        toggleBlock() {
          this.isActive = this.isActive ? false : true
        },
        consoleLog(thing) {
          console.log(thing)
        },
        toggleSubBlock(subFieldArrayKey) {

          const index = this.isActiveSub.indexOf(subFieldArrayKey);
          if (index > -1) {
            this.isActiveSub.splice(index, 1);
          } else {
            this.isActiveSub.push(subFieldArrayKey);
          }
        },
        resetColour() {
          this.content.blocks[this.templateKey].fields[this.field.name][this.subFieldKey].value = ''
          this.save()
        }
      }
    }
</script>

<style scoped lang="scss">
  .button {
    &--margin {
      margin-top: 32px;
    }
  }
</style>
