import apiClient from './apiClient.js'

export default {
    getSettings(filters) {
        let filtersEncoded = new URLSearchParams(filters).toString();
        return new apiClient().get('/setting?' + filtersEncoded);
    },
    getSetting(id) {
        return new apiClient().get('/setting/'+id);
    },
    updateSetting(setting) {
        return new apiClient().patch('/setting/'+setting.id, setting);
    },
}
