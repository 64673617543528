<template>
  <div class="roles">
    <h1>Roles - Create a new role</h1>
    <div>
      <form @submit.prevent="createRole">

        <BaseInput
          v-model="role.name"
          label="Name"
          type="text"
          name="name"
         />

        <BaseInput
          v-model="role.display_name"
          label="Display Name"
          type="text"
          name="display_name"
         />
        <BaseInput
          v-model="role.description"
          label="Description"
          type="text"
          name="description"
         />


        <BaseCheckbox
          v-model="role.protected"
          label="Protected Role?"
          id="protected"
          name="protected" />


        <BaseButton type="submit" text="Add New Role" />

      </form>
    </div>
  </div>
</template>

<script>
    // import Datepicker from 'vue3-datepicker'

    export default {
        data() {
          return {
            role: this.createFreshRoleObject(),
            error: {},

          }
        },
        methods: {
          createRole() {
            this.$store.dispatch('error/clear')
            this.$store.dispatch('role/createRole', this.role).then(response => {
              this.$router.push({
                name: 'roles-detail',
                params: { id: response.id }
              })
              this.role = this.createFreshRoleObject()
            }).catch(err => {
                this.error = err
            })
          },
          createFreshRoleObject() {
            const role = this.$store.state.role
            const id = Math.floor(Math.random*10000)

            return {
              id: id,
              role: role
            }
          }
        }

    }
</script>
