import ShippingService from '@/services/ShippingService.js'

export const namespaced = true

export const state = {
        shippingRates: [],
        shippingRatesTotal: 0,
        shipping: {},
        isLoading: true,
        meta: {},
    }
export const mutations = {
    ADD(state, shipping) {
        state.shippingRates.push(shipping)
    },
    UPDATE(state, shipping) {
        state.shipping = shipping
    },
    DELETE(state, index) {
        state.shippingRates.splice(index)
    },
    SET_LIST(state, shippingRates) {
        state.shippingRates = shippingRates
    },
    SET_META(state, meta) {
        state.meta = meta
    },
    SET_LIST_TOTAL(state, total) {
        state.shippingRatesTotal = total
    },
    SET_ITEM(state, shipping) {
        state.shipping = shipping
    },
    SET_IS_LOADING(state, isLoading) {
        state.isLoading = isLoading
    }
  }

export const actions = {
    createShipping({ commit, dispatch}, shipping) {

        return ShippingService.postShipping(shipping).then(response => {
            commit('ADD', shipping)
            const notification = {
                type: 'success',
                message: 'New shipping rate has been created!'
            }
            dispatch('notification/add', notification, {root: true})

            return response.data.data
        }).catch(error => {
            const notification = {
                type: 'error',
                message: error.response.data.message
            }
            dispatch('notification/add', notification, {root: true})

            const errors = error.response.data.errors
            Object.keys(errors).forEach(key => {
                dispatch('error/add', {key, message: errors[key]}, {root: true})
            });

            throw error
        })
    },
    updateShipping({ commit, dispatch}, data) {

        return ShippingService.updateShipping(data).then(response => {
            commit('UPDATE', response.data.data)

            const notification = {
                type: 'success',
                message: 'Shipping rate has been updated!'
            }
            dispatch('notification/add', notification, {root: true})


        }).catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem updating the shipping rate: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})

            const errors = error.response.data.errors
            Object.keys(errors).forEach(key => {
                dispatch('error/add', {key, message: errors[key]}, {root: true})
            });

            throw error
        })
    },
    deleteShipping({ commit, dispatch}, id) {

      const shipping = getters.getShippingById(id)
      const shippingIndex = state.shippingRates.indexOf(shipping);

        return ShippingService.deleteShipping(id).then(() => {
            commit('DELETE', shippingIndex)

            const notification = {
                type: 'success',
                message: 'Shipping rate has been deleted!'
            }
            dispatch('notification/add', notification, {root: true})
        }).catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem deleting the shipping rate: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})
            throw error
        })
    },
    fetchShippingRates({commit, dispatch}, filters) {
      ShippingService.getShippingRates(filters)
      .then(response => {
        commit('SET_LIST', response.data.data)
        commit('SET_META', response.data.meta)
        if(response.data.meta !== undefined) {
            commit('SET_LIST_TOTAL', response.data.meta.total)
        }
        commit('SET_IS_LOADING', false)
      })
      .catch(error => {
        const notification = {
            type: 'error',
            message: 'There was a problem getting the shipping rates: ' + error.message
        }
        dispatch('notification/add', notification, {root: true})
      })
    },
    fetchShipping({commit, dispatch}, id) {

        ShippingService.getShipping(id)
        .then(response => {
          commit('SET_ITEM', response.data.data)
          commit('SET_IS_LOADING', false)
        })
        .catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem getting a shipping rate: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})
        })

    }
  }

export const getters = {
    getShippingById: state => id => {
      return state.shippingRates.find(shipping => shipping.id == id)
    }
  }
