import SettingService from '@/services/SettingService.js'
import ContentService from '@/services/ContentService.js'

export const namespaced = true

export const state = {
        settings: [],
        settingsTotal: 0,
        setting: {},
        isLoading: true,
        meta: {},
        templates: [],
        template: {
            mediaTypes: [],
            contentTypes: [],
        },
    }
export const mutations = {
    UPDATE(state, setting) {
        state.setting = setting
    },
    SET_LIST(state, settings) {
        state.settings = settings
    },
    SET_META(state, meta) {
        state.meta = meta
    },
    SET_LIST_TOTAL(state, total) {
        state.settingsTotal = total
    },
    SET_ITEM(state, setting) {
        state.setting = setting

        // JSON.parse(JSON.stringify( - Used for deep copy of object
        const types = JSON.parse(JSON.stringify(state.setting.types))
        const blocks = JSON.parse(JSON.stringify(state.setting.blocks))
        const typesKeys = Object.keys(types)
        const templates = []

        state.setting.blocks.forEach((blockData) => {

            const templateType = typesKeys.filter(type => {
                return types[type].id === blockData.type
            })

            const duplicateType = JSON.parse(JSON.stringify(types[templateType]))

            duplicateType.structure.forEach((fieldData, fieldKey) => {
                if (fieldData.fields !== undefined) {

                    let numberOfValues = 0;

                    fieldData.fields[0].forEach((subFieldData) => {

                        if (blockData.fields[subFieldData.name] !== undefined && blockData.fields[subFieldData.name].length > numberOfValues) {
                            numberOfValues = (blockData.fields[subFieldData.name] !== undefined) ? blockData.fields[subFieldData.name].length : 0
                        }
                    })

                    //Target first key as this is orignal structure we want to duplicate start loop at one because we already have the first one set!
                    const duplicateFields = fieldData.fields[0]
                    for (let i = 1; i < numberOfValues; i++) {
                        duplicateType.structure[fieldKey].fields.push(duplicateFields)
                    }
                }
            })

            templates.push(duplicateType)

        })

        // Goes thorugh templates and blocks incase new fields have been added to the content type and need to have a default value added
        templates.forEach((templateData, templateKey) => {
            const fields = Object.keys(blocks[templateKey].fields)
            templateData.structure.forEach((structureData) => {

                if (structureData.fields) {

                    structureData.fields[0].forEach((subField) => {
                        if (!fields.includes(subField.name)) {
                            for (let i = 0; i < structureData.fields.length; i++) {
                                if (blocks[templateKey].fields[subField.name] === undefined) {
                                    blocks[templateKey].fields[subField.name] = [{value: ''}]
                                } else {
                                    blocks[templateKey].fields[subField.name].push({value: ''})
                                }
                            }
                        }
                    })

                } else if (!fields.includes(structureData.name)) {
                    blocks[templateKey].fields[structureData.name] = [{value: ''}];
                }
            })
        })

        state.setting.blocks = blocks
        state.templates = templates
    },
    SET_IS_LOADING(state, isLoading) {
        state.isLoading = isLoading
    },
    ADD_TYPE(state, id) {
        const types = JSON.parse(JSON.stringify(state.setting.types))
        const typesKeys = Object.keys(types)

        const templateType = typesKeys.filter(type => {
            return types[type].id == id
        })

        state.templates.push(types[templateType])

        const data = { type: id, content: state.setting.id, fields:{} }

        types[templateType].structure.forEach(field => {
            if (field.type === 'repeater') {
                //Target the first array entry as this is the one we want to use
                field.fields[0].forEach(subField => {
                    const values = []

                    if (subField.default !== undefined) {
                        for (let i = 0; i < subField.default.length; i++) {
                            const value = subField.default !== undefined ? subField.default[i] : ''
                            values.push({value: value})
                        }
                    } else {
                        values.push({value: ''})
                    }

                    data.fields[subField.name] = values
                })
            } else {
                if (field.default !== undefined) {
                    const value = field.default !== undefined ? field.default[0] : ''
                    data.fields[field.name] = [{value: value}]
                } else {
                    data.fields[field.name] = [{value: ''}]
                }
            }
        })
        state.setting.blocks.push(data)
    },
    UPDATE_BLOCK(state, data) {
        state.setting.blocks[data.key] = data.data
    },
    DELETE_BLOCK(state, templateKey) {
        state.setting.blocks.splice(templateKey,1)
        state.templates.splice(templateKey,1)
    },
    DELETE_SUBBLOCK(state, data) {
        const template = state.templates[data.templateKey].structure[data.fieldKey].fields[data.subFieldKey]
        template.forEach(field => {
            state.setting.blocks[data.templateKey].fields[field.name].splice(data.subFieldKey,1)
        })
        state.templates[data.templateKey].structure[data.fieldKey].fields.splice(data.subFieldKey,1)
    },
    ORDER_BLOCK(state, data) {
        state.templates.splice(data.newKey, 0, state.templates.splice(data.key, 1)[0])
        state.setting.blocks.splice(data.newKey, 0, state.setting.blocks.splice(data.key, 1)[0])
    },
    ADD_FIELD(state, data) {
        state.templates[data.key].structure[data.repeaterKey].fields.push(data.field)

        data.field.forEach(fieldData => {
            const value = (state.templates[data.key].structure[data.repeaterKey].default !== undefined) ? state.templates[data.key].structure[data.repeaterKey].default[state.setting.blocks[data.key].fields[fieldData.name].length-1] : ''
            state.setting.blocks[data.key].fields[fieldData.name].push({value: value})
        })
    },
  }

export const actions = {

    updateSetting({ commit, dispatch}, data) {

        return SettingService.updateSetting(data).then(response => {
            commit('UPDATE', response.data)

            const notification = {
                type: 'success',
                message: 'Setting rate has been updated!'
            }
            dispatch('notification/add', notification, {root: true})


        }).catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem updating the setting rate: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})

            const errors = error.response.data.errors
            Object.keys(errors).forEach(key => {
                dispatch('error/add', {key, message: errors[key]}, {root: true})
            });

            throw error
        })
    },
    fetchSettings({commit, dispatch}, filters) {
      SettingService.getSettings(filters)
      .then(response => {
        commit('SET_LIST', response.data.data)
        commit('SET_META', response.data.meta)
        if(response.data.meta !== undefined) {
            commit('SET_LIST_TOTAL', response.data.meta.total)
        }
        commit('SET_IS_LOADING', false)
      })
      .catch(error => {
        const notification = {
            type: 'error',
            message: 'There was a problem getting the settings: ' + error.message
        }
        dispatch('notification/add', notification, {root: true})
      })
    },
    fetchSetting({commit, dispatch}, id) {

        SettingService.getSetting(id)
        .then(response => {
          commit('SET_ITEM', response.data)
          commit('SET_IS_LOADING', false)
        })
        .catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem getting a setting: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})
        })

    },
    duplicateField({commit}, data) {
        commit('ADD_FIELD', data)
    },
    duplicateType({commit, dispatch}, id) {
        commit('ADD_TYPE', id)

        const newKey = (state.setting.blocks.length-1)
        const data = {key: newKey, data: state.setting.blocks[newKey]}
        dispatch('saveBlock', data)
    },
    saveBlock({ commit, dispatch}, data) {

        const blockData = {type: data.data.type, content: state.setting.id, fields: data.data.fields, model: 'Setting'}

        return ContentService.postBlock(blockData).then(response => {

            commit('UPDATE_BLOCK', {key: data.key, data: { id: response.data.data.id, setting: state.setting.id, type: response.data.data.type, fields: response.data.data.fields}})

            const notification = {
                type: 'success',
                message: 'Saved'
            }
            dispatch('notification/add', notification, {root: true})


        }).catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem saving the block: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})

            const errors = error.response.data.errors
            Object.keys(errors).forEach(key => {
                dispatch('error/add', {key, message: errors[key]}, {root: true})
            });

            throw error
        })
    },
    updateBlock({ commit, dispatch}, data) {

        const blockData = {type: data.data.type, content: state.setting.id, fields: data.data.fields}

        return ContentService.updateBlock(data.data.id, blockData).then(response => {
            commit('UPDATE_BLOCK', {key: data.key, data: { id: data.data.id, setting: state.setting.id, type: data.data.type, fields: response.data.data.fields}})

            const notification = {
                type: 'success',
                message: 'Saved'
            }
            dispatch('notification/add', notification, {root: true})


        }).catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem saving the block: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})

            const errors = error.response.data.errors
            Object.keys(errors).forEach(key => {
                dispatch('error/add', {key, message: errors[key]}, {root: true})
            });

            throw error
        })
    },
    deleteBlock({ commit, dispatch }, data) {

        const block = state.setting.blocks[data.templateKey]

        if (block.id > 0) {
            return ContentService.deleteBlock({ id: block.id, contentID: state.setting.id}).then(response => {

                commit('DELETE_BLOCK', data.templateKey)
                const notification = {
                    type: 'success',
                    message: response.data.message
                }
                dispatch('notification/add', notification, {root: true})


            }).catch(error => {
                const notification = {
                    type: 'error',
                    message: 'There was a problem saving the block: ' + error.message
                }
                dispatch('notification/add', notification, {root: true})

                const errors = error.response.data.errors
                Object.keys(errors).forEach(key => {
                    dispatch('error/add', {key, message: errors[key]}, {root: true})
                });

                throw error
            })
        } else {
            commit('DELETE_BLOCK', data.templateKey)
        }
    },
    orderBlock({commit, dispatch}, data) {
        let newKey = ''
        if (data.direction !== undefined) {
            if (data.direction === 'up') {
                newKey = (data.key-1)
            }
            if (data.direction === 'down') {
                newKey = (data.key+1)
            }
        }
        if (data.newKey !== undefined) {
            newKey = data.newKey
        }

        const templates = JSON.parse(JSON.stringify(state.templates))
        const blockKeyOrder = Object.keys(templates)
        const newBlockOrder = []

        blockKeyOrder.forEach(templateKey => {
            newBlockOrder.push(state.setting.blocks[templateKey].id)
        })

        newBlockOrder.splice(newKey, 0, newBlockOrder.splice(data.key, 1)[0])

        return ContentService.orderBlock({ content: state.setting.id, order: newBlockOrder }).then(response => {
            commit('ORDER_BLOCK', {key: data.key, newKey: newKey})

            const notification = {
                type: 'success',
                message: response.message
            }
            dispatch('notification/add', notification, {root: true})


        }).catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem changing the order of the block: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})

            const errors = error.response.data.errors
            Object.keys(errors).forEach(key => {
                dispatch('error/add', {key, message: errors[key]}, {root: true})
            });

            throw error
        })

    },
    deleteSubBlock({commit, dispatch}, data) {

        const template = state.templates[data.templateKey].structure[data.fieldKey].fields[data.subFieldKey]
        const block = JSON.parse(JSON.stringify(state.setting.blocks[data.templateKey]))

        template.forEach(field => {
            block.fields[field.name].splice(data.subFieldKey,1)
        })

        dispatch('updateBlock', {key: data.templateKey, data: block})

        commit('DELETE_SUBBLOCK', data)

    },
  }

export const getters = {
    getSettingById: state => id => {
      return state.settings.find(setting => setting.id == id)
    }
  }
