<template>
  <div class="messages">
    <h1>Messages</h1>

    <p v-if="isLoading">Loading...</p>

    <template v-else >
      <div>
        <ListLoop v-for="(item) in message.messages" :key="item.id" :item="item" itemName="display_name" >
          <template v-slot:details>
            <div style="display: flex; justify-content: space-between">
              <p>
                <strong>{{ item.first_name }} {{ item.last_name }}</strong> - {{ item.email }}
              </p>
              <p><span style="font-weight: 500">Submitted: </span>{{ item.created_at }}</p>
            </div>
            <p class="message-detail" v-for="(value, key) in item.data" :key="'message-' + item.id+'-'+key"><span style="font-weight: 500">{{ key.replace('_', ' ') }}: </span>{{ value }}</p>
          </template>
        </ListLoop>
      </div>

      <Pagination :page="page" :perPage="perPage" :filter="filter" routeName="users" :total="totalUsers" />
    </template>

  </div>
</template>

<script>
import ListLoop from '@/components/ListLoop.vue'
import Pagination from '@/components/Pagination.vue'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      isFilterActive: false,
      exporting: false,
      filter: {
        q: this.$route.query.q || '',
        perPage: 40,
        page: parseInt(this.$route.query.page) || 1,
      }
    }
  },
  components: {
    ListLoop,
    Pagination
  },
  created() {
    this.$store.dispatch('message/fetchMessages', this.filter)
    console.log(this.message.messages)
  },
  computed: {
    perPage(){
      return this.filter.perPage;
    },
    page() {
      return this.filter.page;
    },
    totalUsers() {
      return this.message.messagesTotal
    },
    isLoading() {
      return this.message.isLoading
    },
    ...mapState(['message'])
  },
  methods : {
  }

}
</script>
<style lang="scss" >
// Loader Styles !Required!
$spinner_speed: .675s;
$spinner_radius: 80%;
$spinner_fill_radius: 90px;

$spinner_size: 20px;
$spinner_thickness: 5px;
$spinner_inner_circle: $spinner_size - $spinner_thickness;

$spinner_color: #FFFFFF;
$spinner_bg: #3adb76;
$spinner_inner_bg: #696969;

.active {

  span {
    text-indent: -2000px;
    overflow: hidden;
    border-radius: 80%;
    display: block;
    height: $spinner_size;
    width: $spinner_size;
    position: relative;
    animation: spin $spinner_speed linear 0s infinite normal;
    background: $spinner_bg;

    &:before,
    &:after {
      content: "";
      display: block;
      position: absolute;
    }

    &:before {
      border-radius: 0 $spinner_fill_radius $spinner_fill_radius 0;
      height: $spinner_size;
      width: 50%;
      top: 0; right: 0;
      z-index: 1;
      background: $spinner_color;
      background-image: linear-gradient( $spinner_bg, $spinner_color);
    }

    &:after {
      border-radius: $spinner_radius;
      height: $spinner_inner_circle;
      width: $spinner_inner_circle;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      background: $spinner_inner_bg;
    }


  }
}
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.messages .loop-list__details a {
  display: none;
}

.messages .loop-list__details {
  flex-direction: column;
}

.message-detail {
  display: block;
  margin-top: 10px;
}
</style>
