import apiClient from './apiClient.js'

export default {
    getPayments(perPage, page, q) {
        return new apiClient().get('/payment?limit=' + perPage + '&page=' + page + '&q=' + q);
    },
    getPayment(id) {
        return new apiClient().get('/payment/'+id);
    },
    postPayment(payment) {
        return new apiClient().post('/payment', payment);
    },
    updatePayment(payment) {
        return new apiClient().patch('/payment/'+payment.id, payment);
    }
}
