const localKey = 'RMAauthUser'
const item = localStorage.getItem(localKey)

export const localAuthData = (item !== null
           && item !== ''
           && item !== 'null'
           && item !== undefined
           && item !== 'undefined')
        ?
          JSON.parse(item)
        :
          null

export const localAuthUser =  (localAuthData !== null) ? localAuthData.user : null

export const localAuthToken =  (localAuthData !== null) ? localAuthData.access_token : null

export const localAuthDataRemove = () => {
    localStorage.removeItem(localKey)
}

export const localAuthDataSet = (data) => {
    localStorage.setItem(localKey, JSON.stringify(data))
}

