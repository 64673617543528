import RoleService from '@/services/RoleService.js'

export const namespaced = true

export const state = {
        roles: [],
        rolesTotal: 0,
        role: {},
        isLoading: true,
        meta: {}
    }
export const mutations = {
    ADD_ROLE(state, role) {
        state.roles.push(role)
    },
    UPDATE_ROLE(state, role) {
        state.role = role
    },
    DELETE_ROLE(state, index) {
        state.roles.splice(index)
    },
    SET_ROLES(state, roles) {
        state.roles = roles
    },
    SET_META(state, meta) {
        state.meta = meta
    },
    SET_ROLES_TOTAL(state, total) {
        state.rolesTotal = total
    },
    SET_ROLE(state, role) {
        state.role = role
    },
    SET_IS_LOADING(state, isLoading) {
        state.isLoading = isLoading
    }
  }

export const actions = {
    createRole({ commit, dispatch}, role) {

        return RoleService.postRole(role).then(response => {
            commit('ADD_ROLE', role)
            const notification = {
                type: 'success',
                message: 'New role has been created!'
            }
            dispatch('notification/add', notification, {root: true})

            return response.data.data
        }).catch(error => {
            const notification = {
                type: 'error',
                message: error.response.data.message
            }
            dispatch('notification/add', notification, {root: true})

            const errors = error.response.data.errors
            Object.keys(errors).forEach(key => {
                dispatch('error/add', {key, message: errors[key]}, {root: true})
            });

            throw error
        })
    },
    updateRole({ commit, dispatch}, role) {

        return RoleService.updateRole(role).then(() => {
            commit('UPDATE_ROLE', role)

            const notification = {
                type: 'success',
                message: 'Role has been updated!'
            }
            dispatch('notification/add', notification, {root: true})


        }).catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem updating the role: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})

            const errors = error.response.data.errors
            Object.keys(errors).forEach(key => {
                dispatch('error/add', {key, message: errors[key]}, {root: true})
            });

            throw error
        })
    },
    deleteRole({ commit, dispatch}, id) {

      const role = getters.getRoleById(id)
      const roleIndex = state.roles.indexOf(role);

        return RoleService.deleteRole(id).then(() => {
            commit('DELETE_ROLE', roleIndex)

            const notification = {
                type: 'success',
                message: 'Role has been delete!'
            }
            dispatch('notification/add', notification, {root: true})
        }).catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem deleting the role: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})
            throw error
        })
    },
    fetchRoles({commit, dispatch}, filters) {
      RoleService.getRoles(filters)
      .then(response => {
        commit('SET_ROLES', response.data.data)
        commit('SET_META', response.data.meta)
        commit('SET_ROLES_TOTAL', response.data.meta.total)
        commit('SET_IS_LOADING', false)
      })
      .catch(error => {
        const notification = {
            type: 'error',
            message: 'There was a problem getting roles: ' + error.message
        }
        dispatch('notification/add', notification, {root: true})
      })
    },
    fetchRole({commit, getters, dispatch}, id) {
      var role = getters.getRoleById(id)

      if (role) {
          commit('SET_ROLE', role)
          commit('SET_IS_LOADING', false)
      } else {
        RoleService.getRole(id)
        .then(response => {
          commit('SET_ROLE', response.data.data)
          commit('SET_IS_LOADING', false)
        })
        .catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem getting a role: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})
        })
      }

    }
  }

export const getters = {
    getRoleById: state => id => {
      return state.roles.find(role => role.id == id)
    }
  }
