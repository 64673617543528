<template>
  <h1 v-if="isLoading">Loading...</h1>

  <div class="grid-container" v-else style="margin-top: 30px">
    <router-link :to="'/users/'+id">
      <button class="button" style="margin: 0 0 30px 0;">Return to user profile</button>
    </router-link>

    <h1>User: Addresses</h1>

    <router-link :to="'/users/'+id+'/addresses/create'">
      <button class="button" @click="create" style="margin: 0 0 15px 0;">Add new address</button>
    </router-link>

    <div class="grid-x grid-margin-x large-up-3">
      <div class="cell" v-for="(address) in address.addresses" :key="address.id">
        <div class="card">
          <div class="card-divider" style="justify-content: space-between">
            <h4 style="color: black; text-transform: capitalize; margin-bottom: 0">{{ address.type }}</h4>

            <BaseButton class="button button--remove" text="Remove" @click="remove(address.id)"/>
          </div>
          <div class="card-section">
            <template v-if="!isEditing">
              <p>{{ address.street_address_1 }},</p>
              <p v-if="address.street_address_2 !== null">{{ address.street_address_2 }},</p>
              <p>{{ address.city }},</p>
              <p v-if="address.county !== null">{{ address.county }},</p>
              <p>{{ address.postcode }}</p>
              <p>{{ address.country }}</p>

              <router-link :to="'/users/'+address.user_id+'/addresses/'+address.id">
                <button class="button" @click="edit" style="margin: 30px 0 0 0;">Edit</button>
              </router-link>
            </template>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
  props: ["id"],
  data() {
    return {
      isEditing: false
    }
  },
  created() {
    this.$store.dispatch('error/clear')
    this.$store.dispatch('address/fetchAddresses', this.id)
  },
  computed: {
    isLoading() {
      return this.address.isLoading
    },
    ...mapState(['address'])
  },
  methods: {
    remove(addressID) {
      this.$store.dispatch('address/deleteAddress', {
        id: this.id,
        addressID
      }).then(() => {
        this.$router.go()
      }).catch(err => {
        this.error = err
      })
    }
  }
}
</script>

<style scoped>

</style>
