import apiClient from './apiClient.js'


export default {
    getNotifications(filters) {
        let filtersEncoded = new URLSearchParams(filters).toString();
        return new apiClient().get('/push-notifications?' + filtersEncoded);
    },
    getNotification(id) {
        return new apiClient().get('/push-notifications/'+id);
    },
    postNotification(record) {
        return new apiClient().post('/push-notifications', record);
    },
    updateNotification(record) {
        return new apiClient().patch('/push-notifications/'+record.id, record);
    },
    deleteNotification(id) {
        return new apiClient().delete('/push-notifications/'+id);
    },
    createNotification() {
        return new apiClient().get('/push-notifications/create');
    },
    authCheck(authToken) {

        if (authToken === null) {
            console.log('not logged in');
        }

    }
}
