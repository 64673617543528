<template>
  <div class="notifications">
    <h1>Create a new push notification</h1>

    <div class="grid-x grid-margin-x" >
      <div class="panel cell medium-4">
        <form @submit.prevent="createNotification">

          <BaseInput
            v-model="notification.title"
            label="Title"
            type="text"
            name="title"
           />

          <BaseTextarea
            v-model="notification.body"
            label="Text"
            type="text"
            name="body"
           />

          <BaseSelect
            :options="statuses"
            v-model="notification.status"
            label="Select a status"
            optionValue="id"
            optionName="title"
           />

          <BaseSelect
            :options="types"
            v-model="notification.type"
            label="Select a type"
            optionValue="id"
            optionName="title"
           />

          <BaseDate
            v-model="notification.send_at"
            label="Set send time"
            help="If a date and time in the past is entered the notifcation will be sent immediately - Note: Notifcations are scheduled to be sent every 5 minutes. Leave blank to set date and time to now."
           />

          <p style="margin: 40px 0 0 0;">Notifications can be sent to just one user or a whole group of users in a role. Select them below:</p>

          <h3 class="no-margin" style="margin-top: 20px;">Users</h3>
          <p>Select the users the notification should be sent to.</p>
          <template v-if="notification.users">
            <span v-for="(user) in notification.users" :key="user.id" :user="user" class="tag" >
              {{ user.email }}
              <BaseButton class="button button--remove" text="Remove" @click.prevent="removeUser(user.id)" />
            </span>
          </template>
          <p v-else>None have been assigned yet</p>

          <template v-if="usersAvailable">
            <form @submit.prevent="update" class="form-min">
              <BaseAutoComplete
                v-model="addUser"
                :options="usersAvailable"
                placeholder="Search to assign..."
                subTitle="email"
               />
              <BaseButton v-if="!addUser" text="Assign" disabled />
              <BaseButton v-else text="Assign" @click.prevent="addNewUser"  />
            </form>


          </template>

          <br/>

          <h3 class="no-margin">Roles</h3>
          <p>Select the role group the notification should be sent to.</p>
          <template v-if="notification.roles">
            <span v-for="(role) in notification.roles" :key="role.id" :role="role" class="tag" >
              {{ role.display_name }}
              <BaseButton class="button button--remove" text="Remove" @click.prevent="removeRole(role.id)" />
            </span>
          </template>
          <p v-else>None have been assigned yet</p>

          <template v-if="rolesAvailable">
            <form @submit.prevent="update" class="form-min">
              <BaseAutoComplete
                v-model="addRole"
                :options="rolesAvailable"
                placeholder="Search to assign..."
                subTitle="display_name"
               />
              <BaseButton v-if="!addRole" text="Assign" disabled />
              <BaseButton v-else text="Assign" @click.prevent="addNewRole"  />
            </form>


          </template>

          <br/>
          <BaseButton type="submit" text="Add Notification" />

        </form>
      </div>
      <div class="panel cell medium-8">
        <h3>Overview</h3>
        <p class="intro_text">Push notification will only be received by users who have enabled push notifcations on their phone for the RoadmapMBA app and when the app is not active. Push notifications will not be shown to users who are actively using the app.</p>
        <p class="intro_text">Users will also only receive the notifcations if they have enabled it for the type: progress, module updates or live sessions.</p>

        <h3>Status Options</h3>
        <p class="intro_text"><strong>Active</strong> : Setting up a notification here will send to all the users selected straight away if status is set to 'Active'.</p>
        <p class="intro_text"><strong>Draft</strong> : This allows a notification to be set up but it wont be sent. Send time will be ignored when this status is set.</p>
        <p class="intro_text"><strong>Sent</strong> : This can not be set manaully this status will be allocated when notifications have been sent. </p>

      </div>
    </div>
  </div>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        data() {
          return {
            notification: this.createFreshNotificationObject(),
            error: {},
            addRole: null,
            addUser: null,
            statuses: [{
                id: '',
                title: 'Please select a status'
              },
              {
                id: 'active',
                title: 'Active'
              },
              {
                id: 'draft',
                title: 'Draft'
              }
            ],
            types: [{
                id: '',
                title: 'Please select a type'
              },
              {
                id: 'modules',
                title: 'Module Updates'
              },
              {
                id: 'progress',
                title: 'Course Progress'
              },
              {
                id: 'sessions',
                title: 'Live Sessions'
              }
            ]
          }
        },
        created() {
          this.$store.dispatch('pushNotification/newNotification');
        },
        methods: {
          createNotification() {
            this.$store.dispatch('error/clear')
            this.$store.dispatch('pushNotification/createNotification', this.notification).then(response => {
              this.$router.push({
                name: 'push-notifications-detail',
                params: { id: response.id }
              })
              this.notification = this.createFreshNotificationObject()
            }).catch(err => {
                this.error = err
            })
          },
          createFreshNotificationObject() {
            const notification = this.$store.state.notification
            const id = Math.floor(Math.random*10000)

            return {
              id: id,
              notification: notification,
              users: [],
              roles: [],
            }
          },
          addNewUser() {
            this.notification.users.push(this.addUser);
            this.addUser = null;
          },
          addNewRole() {
            this.notification.roles.push(this.addRole);
            this.addRole = null;
          },
          removeUser(id) {
            const index = this.notification.users.findIndex(user => user.id == id);
            this.notification.users.splice(index,1);
          },
          removeRole(id) {
            const index = this.notification.roles.findIndex(role => role.id == id);
            this.notification.roles.splice(index,1);
          },
        },
        computed: {
          ...mapState({
            users: state => state.pushNotification.users,
            roles: state => state.pushNotification.roles,
          }),
          rolesAvailable() {
            return (this.roles) ? this.roles.available : []
          },
          usersAvailable() {
            return (this.users) ? this.users.available : []
          },
        }

    }
</script>
