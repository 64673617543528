<template>
    <button v-bind="$attrs" :class="buttonClass" ><span>{{ text }}</span></button>
</template>

<script>
export default {
    props: {
        text: {
            type: [String, Number],
            required: true
        },
        buttonClass: {
            type: String,
            default: 'button'
        }
    }
}
</script>

<style scoped>

</style>
