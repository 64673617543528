<template>
  <div class="product-tags">
    <h1>Product Tags</h1>
    <template v-if="!isFilterActive">
      <BaseButton text="Filter" @click="filterToggle" />
    </template>
    <div v-else class="card">
      <div class="card-divider" style="justify-content: space-between;">
        <h3 class="no-margin">Filter</h3>
        <BaseButton text="X" class="button button--close" @click="filterToggle" />
      </div>
      <div class="card-section gid-container">
        <form @submit.prevent="filterResults" class="grid-x grid-margin-x">
          <div class="cell medium-4 large-3">
            <BaseInput
              v-model="filter.q"
              label="Search"
              type="search"
              name="q"
             />
          </div>
          <div class="cell medium-4 large-3">
            <BaseButton type="submit" text="Filter" style="margin-top: 27px;" />
          </div>
        </form>
      </div>
    </div>
    <router-link :to="{ name: 'product-tags-create'}">
      <BaseButton text="Add new product tag" />
    </router-link>

    <p v-if="isLoading">Loading...</p>
    <template v-else >
      <div>
        <ListLoop v-for="(item) in productTag.productTags" :key="item.id" :item="item" route="product-tags-detail" itemName="title" />
      </div>

      <Pagination :page="page" :perPage="perPage" :filter="filter" routeName="product-tags" :total="totalProductTags" />
    </template>

  </div>
</template>

<script>
    import ListLoop from '@/components/ListLoop.vue'
    import Pagination from '@/components/Pagination.vue'
    import { mapState } from 'vuex'

    export default {
        data() {
          return {
            isFilterActive: false,
            filter: {
              q: this.$route.query.q || '',
              perPage: 10,
              page: parseInt(this.$route.query.page) || 1
            }
          }
        },
        components: {
          ListLoop,
          Pagination
        },
        created() {
            console.log(this.$store)
          this.$store.dispatch('productTag/fetchProductTags', this.filter)
        },
        computed: {
          perPage(){
            return this.filter.perPage;
          },
          page() {
            return this.filter.page;
          },
          totalProductTags() {
            return this.productTag.productTagsTotal
          },
          isLoading() {
            return this.productTag.isLoading
          },
          ...mapState(['productTag'])
        },
        methods : {
          filterToggle() {
            this.isFilterActive = (this.isFilterActive) ? false : true
          },
          filterResults() {
            this.$store.dispatch('productTag/fetchProductTags', this.filter)
          }
        }

    }
</script>
