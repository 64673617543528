<template>
  <div class="users">
    <div>
    <p v-if="isLoading">Loading...</p>
    <template v-else >
      <h1>Discount: {{ discount.title || '' }}</h1>

      <div class="grid-x grid-margin-x" >
        <div class="panel cell medium-6">
          <div class="panel__header">
            <h3>Rate Details</h3>
          </div>
            <form @submit.prevent="update">

              <BaseInput
                v-model="discount.title"
                label="Title"
                type="text"
                name="title"
               />
              <BaseInput
                v-model="discount.code"
                label="Code"
                type="text"
                name="code"
               />
              <BaseSelect
                :options="discount.types"
                v-model="discount.type"
                label="Select a type"
                optionValue="id"
                optionName="name"
                name="type"
               />
              <BaseInput
                v-model="discount.amount"
                label="Amount"
                type="text"
                name="tax"
               />
              <BaseInput
                v-model="discount.expiry"
                label="Expiry"
                type="date"
                name="expiry"
               />


              <BaseButton type="submit" text="Update" />
          </form>

        </div>

        <div class="cell medium-6" >
                <div class="panel cell medium-12" >
                    <h3>Product Options</h3>
                    <p>To limit this code to one or more options select them here. If no options are selected the code will be available to be used on all items.</p>
                    <template v-if="optionsAssigned">
                      <span v-for="(item) in optionsAssigned" :key="item.id" :item="item" class="tag" >
                        {{ item.fullTitle }}
                        <BaseButton class="button button--remove" text="Remove" @click.prevent="removeOption(item.id)" />
                      </span>
                    </template>
                    <p v-else>None have been assigned yet</p>

                    <template v-if="optionsAvailable">
                      <form @submit.prevent="update" class="form-min">
                        <BaseAutoComplete
                          v-model="discount.addOption"
                          :options="optionsAvailable"
                          placeholder="Search to assign..."
                          subTitle="fullTitle"
                         />
                        <BaseButton v-if="!discount.addOption" text="Save" disabled />
                        <BaseButton v-else text="Save" @click.prevent="update"  />
                      </form>


                    </template>
                    <p v-else>No more available to assign</p>

                </div>
        </div>

        <div class="panel cell medium-12">
          <p>Click below to delete this discount code.</p>
          <BaseButton text="Delete" @click="remove" class="button button--danger" />
        </div>

      </div>

    </template>
    </div>
  </div>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        props: ["id"],
        data() {
          return {
            isEditing: false,
            addOption: false,
          }
        },
        components : {

        },
        created() {
          this.$store.dispatch('error/clear')
          this.$store.dispatch('discount/fetchDiscount', this.id)

        },
        computed: {
          isDisabled() {
            return false
          },
          ...mapState({
            isLoading: state => state.discount.isLoading,
            discount: state => state.discount.discount
          }),
          optionsAssigned() {
            return (this.discount.productOptions) ? this.discount.productOptions.assigned : []
          },
          optionsAvailable() {
            return (this.discount.productOptions) ? this.discount.productOptions.available : []
          },
        },
        methods: {
          remove() {
            this.$store.dispatch('discount/deleteDiscount', this.id)
          },
          update() {
            this.$store.dispatch('error/clear')
            this.$store.dispatch('discount/updateDiscount', this.discount)
            this.isEditing = false
          },
          edit() {
            this.isEditing = (this.isEditing) ? false : true

          },
          removeOption(option) {
            this.discount.addOption = null
            this.discount.removeOption = option
            this.update()
          },
        }

    }
</script>
