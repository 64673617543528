import apiClient from './apiClient.js'

export default {
    getProductTags(filters) {
        let filtersEncoded = new URLSearchParams(filters).toString();
        return new apiClient().get('/product-tags?' + filtersEncoded);
    },
    getProductTag(id) {
        return new apiClient().get('/product-tags/'+id);
    },
    postProductTag(tag) {
        return new apiClient().post('/product-tags', tag);
    },
    updateProductTag(tag) {
        return new apiClient().patch('/product-tags/'+tag.id, tag);
    },
    deleteProductTag(id) {
        return new apiClient().delete('/product-tags/'+id);
    }
}
