<template>
  <div class="users">
    <h1>Roles : {{ role.display_name }}</h1>
    <div>
    <p v-if="isLoading">Loading...</p>
    <template v-else >

      <div class="grid-x grid-margin-x" >
        <div class="panel cell medium-4">
          <h3>Role Details</h3>
          <button class="button" @click="edit">Edit</button>
          <template v-if="!isEditing">
            <p>{{ role.display_name }}</p>
            <p>{{ role.name }}</p>
            <p>{{ role.created_at }}</p>
          </template>
          <template v-else>
            <form @submit.prevent="update">

              <BaseInput
                v-model="role.display_name"
                label="Name"
                type="text"
                name="display_name"
               />
              <BaseInput
                v-model="role.description"
                label="Description"
                type="text"
                name="description"
               />

              <BaseButton type="submit" text="Update" />
          </form>
          </template>

        </div>

      </div>
      <div class="grid-x grid-margin-x" >
        <div class="panel cell medium-6">
          <p>Click below to delete this role.</p>
          <template v-if="!role.protected">
            <BaseButton text="Delete" @click="remove" class="button button--danger" />
          </template>
          <template v-else>
            <p>This role can not be removed</p>
          </template>
        </div>
      </div>
    </template>
    </div>
  </div>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        props: ["id"],
        data() {
          return {
            isEditing: false
          }
        },
        components : {

        },
        created() {
          this.$store.dispatch('error/clear')
          this.$store.dispatch('role/fetchRole', this.id)

        },
        computed: {
          isLoading() {
            return this.role.isLoading
          },
          isDisabled() {
            return false
          },
          ...mapState({
            role: state => state.role.role
          })
        },
        methods: {
          remove() {
            this.$store.dispatch('role/deleteRole', this.id)
          },
          update() {
            this.$store.dispatch('error/clear')
            this.$store.dispatch('role/updateRole', this.role)
            this.isEditing = false
          },
          edit() {
            this.isEditing = (this.isEditing) ? false : true

          }
        }

    }
</script>
