<template>
  <div class="product">
    <h1>New Product</h1>
    <div class="panel">
      <form @submit.prevent="createProduct">

        <BaseInput
          v-model="product.title"
          label="Title"
          type="text"
          name="title"
         />
        <BaseRadioGroup
            v-model="product.type"
            :options="template.types"
            optionValue="id"
            optionName="title"
            name="type"
            label="Type"
            lozenge
         />

        <BaseButton type="submit" text="Add New Product" />

      </form>
    </div>
  </div>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        data() {
          return {
            product: this.createFreshRoleObject(),
            error: {},

          }
        },
        created() {
          this.$store.dispatch('error/clear')
          this.$store.dispatch('product/newProduct')
        },
        computed: {
          ...mapState({
            template: state => state.product.template
          })
        },
        methods: {
          createProduct() {
            this.$store.dispatch('error/clear')
            this.$store.dispatch('product/createProduct', this.product).then(response => {
              this.$router.push({
                name: 'product-detail',
                params: { id: response.id }
              })
              this.product = this.createFreshRoleObject()
            }).catch(err => {
                this.error = err
            })
          },
          createFreshRoleObject() {
            const product = this.$store.state.product
            const id = Math.floor(Math.random*10000)

            return {
              id: id,
              product: product
            }
          }
        }

    }
</script>
