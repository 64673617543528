<template>
    <div class="pagination">
        <div>
            <template v-if="page != 1">
                <router-link :to="{ name: routeName, query: {...filter, page: page - 1, perPage }}" rel="prev" >
                  <BaseButton text="Prev" />
                </router-link>
            </template>
            <template v-if="(total/perPage) >= page">
                <router-link :to="{ name: routeName, query: {...filter, page: page + 1, perPage }}" rel="next" >
                  <BaseButton text="Next" />
                </router-link>
            </template>
        </div>
        <div>
            <template v-if="(total/perPage) > 2">
                <template v-for="index in Math.ceil((total/perPage))" :key="index" >
                    <router-link :to="{ name: routeName, query: {...filter, page: index, perPage }}" :rel="index" :page="page" >
                      <BaseButton :text="index" :class="{'button--outline' : (page == index)}" class="button--small" :disabled="(page == index)" />
                    </router-link>
                </template>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        page: {
            type: Number,
            required: true
        },
        perPage: {
            type: Number,
            required: true
        },
        total: {
            type: Number,
            required: true
        },
        lastPage: {
            type: [Number, Boolean],
            default: false
        },
        routeName: {
            type: String,
            required: true
        },
        filter: {
            type: Object,
            default: ()=>{}
        }

    }
}
</script>

<style scoped lang="scss">
    .pagination {
        margin: 1em 0;
        padding: 1em 0;
        border-top: 1px #c7c7c7 solid;
        display: flex;
        justify-content: space-between;
    }
</style>
