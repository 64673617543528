<template>
  <h1>Content Views & Downloads</h1>

  <p v-if="isLoading">Loading...</p>

  <div v-else>
    <div>
      <ul style="list-style: none; display: flex; padding: 0; margin-left: 0;">
        <li><BaseButton @click.prevent="screen = 'views'" :class="{'button--outline': screen !== 'views'}" text="Views"/></li>
        <li><BaseButton @click.prevent="screen = 'downloads'" :class="{'button--outline': screen !== 'downloads'}" text="Downloads"/></li>
      </ul>
    </div>
    <div v-show="screen == 'views'" v-for="(user) in usersView" :key="user.id">
      <div class="loop-list loop-list--deleted">
        <div class="loop-list__details">
          <div>
            <p>{{ user.name }}</p>
            <p v-if="user.source">Source: {{ JSON.parse(user.source).type || 'web' }}</p>
            <p v-else>Source: web</p>
            <p>Content viewed: {{ user.created_at }}</p>
          </div>
          <div>
            <router-link :to="{ name: 'users-detail', params: { id: user.id }}"><BaseButton text="View Content" /></router-link>
          </div>
        </div>
      </div>
    </div>
    <div v-show="screen == 'downloads'" v-for="(user) in usersDownload" :key="user.id">
      <div class="loop-list loop-list--deleted">
        <div class="loop-list__details">
          <div>
            <p>{{ user.name }}</p>
            <p>Content downloaded: {{ user.created_at }}</p>
          </div>
          <div>
            <router-link :to="{ name: 'users-detail', params: { id: user.id }}"><BaseButton text="View Content" /></router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  props: ["id"],
  data() {
    return {
      screen: 'views'
    }
  },
  created() {
    this.$store.dispatch('content/fetchContentView', { id: this.id })
  },
  computed: {
    usersView() {
      return this.content.view ? this.content.view.usersView : [];
    },
    usersDownload() {
      return this.content.view ? this.content.view.usersDownload : [];
    },
    isLoading() {
      return this.content.isLoading
    },
    ...mapState(['content'])
  }
}
</script>

<style scoped>

</style>
