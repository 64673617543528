<template>
  <div class="users">
    <h1>Content Category : {{ contentCategory.title }}</h1>
    <div>
    <p v-if="isLoading">Loading...</p>
    <template v-else >

      <div class="grid-x grid-margin-x" >
        <div class="panel cell medium-4">
          <h3>Category Details</h3>
          <button class="button" @click="edit">Edit</button>
          <template v-if="!isEditing">
            <p>Title: {{ contentCategory.title }}</p>
            <p>Slug: {{ contentCategory.slug }}</p>
            <p>Full Title: {{ contentCategory.listTitle }}</p>
            <p>{{ contentCategory.intro }}</p>

            <p>
              <strong class="success badge badge--lozenge capitalize" v-if="contentCategory.status === 1">Active</strong>
              <strong class="warning badge badge--lozenge capitalize" v-if="contentCategory.status === 0">Inactive</strong>
            </p>
          </template>
          <template v-else>
            <form @submit.prevent="update">

              <BaseInput
                v-model="contentCategory.title"
                label="Title"
                type="text"
                name="title"
               />
              <BaseInput
                v-model="contentCategory.slug"
                label="Slug"
                type="text"
                name="slug"
               />
              <BaseSwitch
                v-model="contentCategory.status"
                :options="[{name: 'Inactive'},{name: 'Active'}]"
                optionName="name"
                label="Status"
               />
              <BaseInput
                  v-model="contentCategory.colour_1"
                  label="Primary Colour"
                  name="colour_1"
                  type="color"
              />
              <BaseInput
                  v-model="contentCategory.colour_2"
                  label="Secondary Colour"
                  name="colour_2"
                  type="color"
              />
              <BaseSelect
                :options="masters"
                v-model="contentCategory.master"
                label="Select main"
                optionValue="id"
                optionName="title"
               />
              <BaseInput
                v-model ="contentCategory.intro"
                label="Introduction"
                type="text"
                name="intro"
               />
              <BaseInput
                v-model ="contentCategory.video"
                label="Video (YT/Vimeo Embed)"
                type="text"
                name="video"
               />
              <BaseButton type="submit" text="Update" />
            </form>
          </template>

        </div>

        <div class="panel cell medium-4">
          <h3>Image </h3>
            <p>
              <strong>Featured Image: </strong>
              <MediaReveal
                  v-model="contentCategory.media"
                  :uniqueKey="'contentCategory_' + contentCategory.id"
                  :save="update"
              />
            </p>
        </div>
        <div class="panel cell medium-4">
          <h3>Sub Categories</h3>
          <ul v-if="children.length">
            <li v-for="(sub) in children" :key="sub.id" :sub="sub" >{{ sub.title }} <router-link :to="{ name: 'content-categories-detail', params: { id: sub.id }}"><BaseButton text="View" class="button--small" /></router-link></li>
          </ul>
          <ul v-else-if="grandChildren.length">
            <li v-for="(sub) in grandChildren" :key="sub.id" :sub="sub" >{{ sub.title }} <router-link :to="{ name: 'content-categories-detail', params: { id: sub.id }}"><BaseButton text="View" class="button--small" /></router-link></li>
          </ul>
          <ul v-else-if="greatGrandChildren.length">
            <li v-for="(sub) in greatGrandChildren" :key="sub.id" :sub="sub" >{{ sub.title }} <router-link :to="{ name: 'content-categories-detail', params: { id: sub.id }}"><BaseButton text="View" class="button--small" /></router-link></li>
          </ul>
          <p v-else>There are no sub categories assigned.</p>
        </div>
        <div class="panel cell medium-12">
          <h3 class="no-margin">Content</h3>
          <p style="margin-bottom: 30px;">Click and drag content title into order in which it should be shown.</p>
          <div v-if="content.length">
            <draggable class="dragArea list-group w-full" :list="content" @change="log">
              <ListLoop v-for="(item) in content" :key="item.id" :item="item" route="content-detail" itemName="title" draggable type updated >
                <template v-slot:details>
                  <div>
                    <p><strong>{{ item.title }}</strong></p>
                  </div>
                </template>
              </ListLoop>
            </draggable>
          </div>
          <p v-else>There is no content in this category/module.</p>
        </div>

        <div class="panel cell medium-12">
          <p>Click below to delete this content category.</p>
          <BaseButton text="Delete" @click="remove" class="button button--danger" />
        </div>
      </div>
    </template>
    </div>
  </div>
</template>

<script>
    import ListLoop from '@/components/ListLoop.vue'
    import { VueDraggableNext } from 'vue-draggable-next'
    import { mapState } from 'vuex'
    import MediaReveal from '@/components/MediaReveal.vue'

    export default {
        props: ["id"],
        data() {
          return {
            isEditing: false
          }
        },
        components : {
          MediaReveal,
          ListLoop,
          draggable: VueDraggableNext,
        },
        created() {
          this.$store.dispatch('error/clear')
          this.$store.dispatch('contentCategory/fetchContentCategory', this.id)

        },
        computed: {
          isLoading() {
            return this.contentCategory.isLoading
          },
          isDisabled() {
            return false
          },
          ...mapState({
            contentCategory: state => state.contentCategory.contentCategory
          }),
          allCategories() {
            return this.contentCategory.all || {}
          },
          childrenOwners() {
            return this.contentCategory.childrenOwners || {}
          },
          parents() {
            return this.contentCategory.parents || {}
          },
          masters() {
            return this.contentCategory.masters || {}
          },
          children() {
            return this.contentCategory.children || {}
          },
          grandChildren() {
            return this.contentCategory.grandChildren || {}
          },
          greatGrandChildren() {
            return this.contentCategory.greatGrandChildren || {}
          },
          content() {
            return this.contentCategory.content || {}
          }
        },
        methods: {
          remove() {
            this.$store.dispatch('contentCategory/deleteContentCategory', this.id)
          },
          update() {
            this.$store.dispatch('error/clear')
            this.$store.dispatch('contentCategory/updateContentCategory', this.contentCategory)
            this.isEditing = false
          },
          edit() {
            this.isEditing = (this.isEditing) ? false : true

          },
          log(data) {
            let order = [];

            data = (data.moved) ? this.content : data

            Object.keys(data).forEach(key => {
              order.push(data[key].id);
            })

            this.$store.dispatch('error/clear')
            this.$store.dispatch('content/updateContentOrder', { category: this.id, order: order})
          },
        }

    }
</script>
