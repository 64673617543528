<template>
  <component :is="comp" :block="block"></component>
</template>

<script>
    import {defineAsyncComponent} from "vue";
    import { mapState } from 'vuex'
    import upperFirst from 'lodash/upperFirst'
    import camelCase from 'lodash/camelCase'

    export default {
        name: "DynamicComponent",
        data() {
          return {
            isActive: false,
          }
        },
        props: {
            blockKey: {
                type: Number,
                required: true
            },
            block: {
                type: Object,
                required: true
            }
        },
        components : {
        },
        computed: {
          ...mapState({
            types: state => state.content.content.types
          }),
          isLast() {
            return (this.blocks.length-1) === this.templateKey
          },
          componentName() {
            const typesKeys = Object.keys(this.types)
            const templateType = typesKeys.filter(type => {
            return this.types[type].id == this.block.type
            })

            const componentName = upperFirst(
            camelCase(this.types[templateType].title.replace(/^\.\/(.*)\.\w+$/, '$1'))
            )

            return componentName
          },
          comp () {
              return defineAsyncComponent(() => import(`@/assets/templates/${this.componentName}.vue`))
          }
        },
        methods: {
        }
    }
</script>
<style type="text/css" lang="scss">
  .platform-block {
    p {
      margin-bottom: 20px;
    }
  }
</style>
