<template>
    <label :for="modelValue" :class="fieldErrorClass" >{{ label}}</label>
    <textarea
        v-bind="$attrs"
        :placeholder="label"
        :class="fieldErrorClass"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
    />
    <template v-if="errors.length > 0">
      <span class="message message--error" v-for="(message, keyMessage) in fieldErrors" :key="keyMessage">{{ message }}</span>
    </template>
</template>

<script>

import { mapState } from 'vuex'
export default {
    props: {
        label: {
            type: String,
            default: ''
        },
        modelValue: {
            type: [String, Number],
            default: ''
        },
        name: {
            type: String,
            default: ''
        }
    },
    computed: {
        ...mapState('error', ['errors']),
        fieldErrorClass() {
            if (this.errors.length > 0) {
                if (this.errors.find(error => error.key == this.name)) {
                    return 'error';
                }
            }
            return ''
        },
        fieldErrors() {
            if (this.errors.length > 0) {
                if (this.errors.find(error => error.key == this.name)) {
                    if (this.errors.find(error => error.key == this.name).message.length > 0) {
                        return this.errors.find(error => error.key == this.name).message
                    }
                }
            }
            return []
        },

    }
}
</script>

<style scoped>

</style>
