<template>
  <div class="reveal reveal--large" id="mediaLibrary" data-reveal>
    <div class="card"  @scroll="onScroll">
      <div class="card-divider" style="justify-content: space-between">
        <h3 class="no-margin">Media Library</h3>
        <BaseButton id="media-reveal" data-close aria-label="Close modal" text="close"
                    class="button button--remove"/>
      </div>
      <div class="card-section">
        <div class="library-header">
          <BaseButton @click="uploading" :text="isUploadingButton"/>
          <form @submit.prevent="filter" class="library-header__filter">
            <div>
              <BaseInput
                  v-model="setQuery"
                  label="Search media"
                  type="search"
                  name="q"
              />
            </div>
            <div>
              <BaseSelect
                  :options="['images', 'files']"
                  v-model="mediaType"
                  label="Select type of media to display"
              />
            </div>
              <BaseButton type="submit" text="Filter"/>

          </form>
        </div>
        <MultiFileUpload v-if="isUploading" inModal :closedReveal="closedReveal" />
        <div class="grid-x grid-margin-x small-up-2 medium-up-4 large-up-6 xxlarge-up-8">
          <MediaListLoop
              v-for="(media) in media.media"
              :mediaObject="media"
              :key="media.id"
              @close-reveal="closedReveal"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import $ from 'jquery';
import Foundation from 'foundation-sites';
import MultiFileUpload from '@/components/MultiFileUpload.vue';
import MediaListLoop from '@/components/MediaListLoop.vue'
import {mapState} from "vuex";

export default {
  data() {
    return {
      isUploading: false,
      isUploadingButton: 'Upload files',
      numberPerPage: null,
      setQuery: null,
      mediaType: null,
      isFiltering: false,
      isFilteringButton: 'Filter',
      pageBase: 1,
    }
  },
  props: {},
  components: {
    MediaListLoop,
    MultiFileUpload
  },
  computed: {
    perPage() {
      return (this.numberPerPage) || parseInt(this.$route.query.perPage) || 24
    },
    query() {
      return (this.setQuery) || (this.$route.query.q) || ''
    },
    type() {
      return (this.mediaType) || (this.$route.query.type) || ''
    },
    page() {
      return parseInt(this.$route.query.page) || this.pageBase
    },
    totalMedia() {
      return this.media.meta.total
    },
    ...mapState({
      isOpen: state => state.media.isOpen,
      media: state => state.media
    }),
  },
  mounted() {
    this.reveal = new Foundation.Reveal($(this.$el))
    $(this.$el).on("closed.zf.reveal", this.closedReveal)
  },
  methods: {
    uploading() {
      this.isUploading = (this.isUploading) ? false : true
      this.isUploadingButton = (this.isUploading) ? 'Cancel' : 'Upload files'
    },
    onScroll ({ target: { scrollTop, clientHeight, scrollHeight }}) {
      if (scrollTop + clientHeight >= scrollHeight) {
        this.pageBase++
        this.$store.dispatch('media/fetchMediaAppend', {
          perPage: this.perPage,
          page: this.page,
          q: this.query,
          type: this.type
        })
      }
    },
    fetchMedia() {
      this.$store.dispatch('media/fetchMedia', {
        perPage: this.perPage,
        page: this.page,
        q: this.query,
        type: this.type
      })
    },
    openReveal() {
      this.reveal.open();
      this.fetchMedia();
    },
    closedReveal() {
      this.isUploading = false
      this.$store.dispatch('media/closeReveal')
      const closeModalButton = document.getElementById('media-reveal');
      closeModalButton.click()
    },
    filtering() {
      this.isFiltering = (this.isFiltering) ? false : true
      this.isFilteringButton = (this.isFiltering) ? 'Cancel' : 'Filter'
    },
    filter() {
      this.$store.dispatch('error/clear');
      this.pageBase = 1;
      this.fetchMedia();
    },
  },
  watch: {
    isOpen(newValue) {
      if (newValue) {
        this.openReveal()
      } else {
        this.closedReveal()
      }
    },
  },
  unmounted() {
    $(this.$el).off("closed.zf.reveal", this.onModalClosed)
    this.reveal.destroy()
  }

}
</script>

<style scoped lang="scss">
  .card {
    position: fixed;
    overflow: scroll;
    height: 90vh;
    width: 90vw;
  }
  .library-header {
    display:flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;

    &__filter {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: flex-end;

      .button {
        margin-bottom: 1em;
      }

      > div {
        padding-right: 10px;
      }
    }
  }
  .reveal--large {
    width: 90vw;
    height: 90vh;
    padding:0px;
    top: 20px !important;
    border: 0px !important;

    &:focus {
      border: 0px;
      outline: none;
    }
  }

  .button--remove {
    vertical-align: top;
  }
</style>
