import ProductService from '@/services/ProductService.js'

export const namespaced = true

const blankProduct = {
            categories: {
                assigned: [],
                available: [],
                combined: []
            },
            tags: {
                assigned: [],
                available: []
            }

        }

export const state = {
        productOptionsFullList: [],
        productList: [],
        productListTotal: 0,
        product: blankProduct,
        isLoading: true,
        meta: {},
        template: {
        },
    }

export const mutations = {
    NEW(state, template) {
        state.template = template
    },
    ADD(state, product) {
        state.productList.push(product)
    },
    UPDATE(state, product) {
        state.product = product
    },
    DELETE(state, index) {
        state.productList.splice(index,1)
    },
    SET_OPTIONS_FULL_LIST(state, optionsList) {
        state.productOptionsFullList = optionsList
    },
    SET_LIST(state, productList) {
        state.productList = productList
    },
    SET_META(state, meta) {
        state.meta = meta
    },
    SET_LIST_TOTAL(state, total) {
        state.productListTotal = total
    },
    RESET_ITEM(state) {
        state.product = blankProduct
        state.templates = []
    },
    SET_ITEM(state, product) {
        state.product = product
    },
    SET_IS_LOADING(state, isLoading) {
        state.isLoading = isLoading
    },
    ADD_OPTION(state, data) {
        state.product.options.push(data)
    },
    UPDATE_OPTION(state, data) {
        state.product.options[data.optionKey] = data.option
    },
    DELETE_OPTION(state, optionKey) {
        state.product.options.splice(optionKey,1)
    },
    ORDER_OPTION(state, data) {
        state.templates.splice(data.newKey, 0, state.templates.splice(data.key, 1)[0])
        state.product.options.splice(data.newKey, 0, state.product.options.splice(data.key, 1)[0])
    }
}

export const actions = {
    newProduct({ commit, dispatch}) {

        return ProductService.newProduct().then(response => {
            commit('NEW', response.data.data)
        }).catch(error => {
            const notification = {
                type: 'error',
                message: error.response.data.message
            }
            dispatch('notification/add', notification, {root: true})

            const errors = error.response.data.errors
            Object.keys(errors).forEach(key => {
                dispatch('error/add', {key, message: errors[key]}, {root: true})
            });

            throw error
        })
    },
    createProduct({ commit, dispatch}, product) {

        return ProductService.postProduct(product).then(response => {
            commit('ADD', product)
            const notification = {
                type: 'success',
                message: 'New product has been created!'
            }
            dispatch('notification/add', notification, {root: true})

            return response.data.data
        }).catch(error => {
            const notification = {
                type: 'error',
                message: error.response.data.message
            }
            dispatch('notification/add', notification, {root: true})

            const errors = error.response.data.errors
            Object.keys(errors).forEach(key => {
                dispatch('error/add', {key, message: errors[key]}, {root: true})
            });

            throw error
        })
    },
    updateProduct({ commit, dispatch}, product) {

        return ProductService.updateProduct(product).then(response => {
            commit('UPDATE', response.data.data)

            const notification = {
                type: 'success',
                message: 'Product has been updated!'
            }
            dispatch('notification/add', notification, {root: true})


        }).catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem updating the product: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})

            const errors = error.response.data.errors
            Object.keys(errors).forEach(key => {
                dispatch('error/add', {key, message: errors[key]}, {root: true})
            });

            throw error
        })
    },
    deleteProduct({ commit, dispatch}, id) {

      const product = getters.getProductById(id)
      const productIndex = state.productList.indexOf(product);

        return ProductService.deleteProduct(id).then(() => {
            commit('DELETE', productIndex)

            const notification = {
                type: 'success',
                message: 'Product has been delete!'
            }
            dispatch('notification/add', notification, {root: true})
        }).catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem deleting the product: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})
            throw error
        })
    },
    fetchOptionsFullList({commit, dispatch}) {
        ProductService.getOptionsFullList()
        .then(response => {
            commit('SET_OPTIONS_FULL_LIST', response.data.data);
            commit('SET_IS_LOADING', false)
        })
        .catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem getting the product options list: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})
        })
    },
    fetchProductList({commit, dispatch}, filters) {
        commit('RESET_ITEM')

        ProductService.getProductList(filters)
        .then(response => {
            commit('SET_LIST', response.data.data)
            commit('SET_META', response.data.meta)
            if(response.data.meta !== undefined) {
                commit('SET_LIST_TOTAL', response.data.meta.total)
            }
            commit('SET_IS_LOADING', false)
        })
        .catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem getting the product list: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})
        })
    },
    fetchProduct({commit, dispatch}, id) {
        commit('SET_IS_LOADING', true)

        ProductService.getProduct(id)
        .then(response => {
          commit('SET_ITEM', response.data.data)
          commit('SET_IS_LOADING', false)
        })
        .catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem getting this product: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})
        })
    },
    createOption({commit, dispatch}, option) {
        return ProductService.postOption(option).then(response => {
            commit('ADD_OPTION', response.data.data)
            const notification = {
                type: 'success',
                message: 'New product option has been added!'
            }
            dispatch('notification/add', notification, {root: true})

            return response.data.data
        }).catch(error => {
            const notification = {
                type: 'error',
                message: error.response.data.message
            }
            dispatch('notification/add', notification, {root: true})

            const errors = error.response.data.errors
            Object.keys(errors).forEach(key => {
                dispatch('error/add', {key, message: errors[key]}, {root: true})
            });

            throw error
        })
    },
    updateOption({commit, dispatch}, data) {
        return ProductService.updateOption(data.option.id, data.option).then(response => {
            commit('UPDATE_OPTION', {optionKey: data.optionKey, option:response.data.data})
            const notification = {
                type: 'success',
                message: 'Option updated'
            }
            dispatch('notification/add', notification, {root: true})

            return response.data.data
        }).catch(error => {
            const notification = {
                type: 'error',
                message: error.response.data.message
            }
            dispatch('notification/add', notification, {root: true})

            const errors = error.response.data.errors
            Object.keys(errors).forEach(key => {
                dispatch('error/add', {key, message: errors[key]}, {root: true})
            });

            throw error
        })
    },
    deleteOption({ commit, dispatch }, data) {

        const option = state.product.options[data.optionKey]

        if (option.id > 0) {
            return ProductService.deleteOption({ id: option.id, productID: option.product_id}).then(response => {

                commit('DELETE_OPTION', data.optionKey)
                const notification = {
                    type: 'success',
                    message: response.data.message
                }
                dispatch('notification/add', notification, {root: true})


            }).catch(error => {
                const notification = {
                    type: 'error',
                    message: 'There was a problem saving the option: ' + error.message
                }
                dispatch('notification/add', notification, {root: true})

                const errors = error.response.data.errors
                Object.keys(errors).forEach(key => {
                    dispatch('error/add', {key, message: errors[key]}, {root: true})
                });

                throw error
            })
        } else {
            commit('DELETE_OPTION', data.optionKey)
        }
    },
    orderOption({commit, dispatch}, data) {
        let newKey = ''
        if (data.direction === 'up') {
            newKey = (data.key-1)
        }
        if (data.direction === 'down') {
            newKey = (data.key+1)
        }

        const templates = JSON.parse(JSON.stringify(state.templates))
        const optionKeyOrder = Object.keys(templates)
        const newBlockOrder = []

        optionKeyOrder.forEach(optionKey => {
            newBlockOrder.push(state.product.options[optionKey].id)
        })

        newBlockOrder.splice(newKey, 0, newBlockOrder.splice(data.key, 1)[0])

        return ProductService.orderBlock({ product: state.product.id, order: newBlockOrder }).then(response => {
            commit('ORDER_OPTION', {key: data.key, newKey: newKey})

            const notification = {
                type: 'success',
                message: response.message
            }
            dispatch('notification/add', notification, {root: true})


        }).catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem changing the order of the option: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})

            const errors = error.response.data.errors
            Object.keys(errors).forEach(key => {
                dispatch('error/add', {key, message: errors[key]}, {root: true})
            });

            throw error
        })

    },
  }

export const getters = {
    getProductById: state => id => {
      return state.productList.find(product => product.id == id)
    },
    getTypeByName: state => name => {
      return state.types.find(type => type.name == name)
    },
    getProductAvaCategoryById : state => id => {
      return state.product.categories.available.find(product => product.id == id)
    }
  }
