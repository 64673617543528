import PushNotificationService from '@/services/PushNotificationService.js'

export const namespaced = true

export const state = {
        pushNotifications: [],
        pushNotificationsTotal: 0,
        pushNotification: {},
        isLoading: true,
        users: { available: [], assigned: []},
        roles: { available: [], assigned: []},
        meta: {}
    }
export const mutations = {
    ADD_NOTIFICATION(state, pushNotification) {
        state.pushNotifications.push(pushNotification)
    },
    UPDATE_NOTIFICATION(state, pushNotification) {
        state.pushNotification = pushNotification
    },
    DELETE_NOTIFICATION(state, index) {
        state.pushNotifications.splice(index)
    },
    SET_NOTIFICATIONS(state, pushNotifications) {
        state.pushNotifications = pushNotifications
    },
    SET_META(state, meta) {
        state.meta = meta
    },
    SET_NOTIFICATIONS_TOTAL(state, total) {
        state.pushNotificationsTotal = total
    },
    SET_NOTIFICATION(state, pushNotification) {
        state.pushNotification = pushNotification
    },
    SET_IS_LOADING(state, isLoading) {
        state.isLoading = isLoading
    },
    SET_USERS(state, users) {
        state.users = users
    },
    SET_ROLES(state, roles) {
        state.roles = roles
    }
  }

export const actions = {
    newNotification({ commit, dispatch}) {

        return PushNotificationService.createNotification().then(response => {
            commit('SET_USERS', response.data.users)
            commit('SET_ROLES', response.data.roles)

        }).catch(error => {
            const notification = {
                type: 'error',
                message: error.response.data.message
            }
            dispatch('notification/add', notification, {root: true})

            const errors = error.response.data.errors
            Object.keys(errors).forEach(key => {
                dispatch('error/add', {key, message: errors[key]}, {root: true})
            });

            throw error
        })
    },
    createNotification({ commit, dispatch}, pushNotification) {

        return PushNotificationService.postNotification(pushNotification).then(response => {
            commit('ADD_NOTIFICATION', pushNotification)
            const notification = {
                type: 'success',
                message: 'New notification has been created!'
            }
            dispatch('notification/add', notification, {root: true})

            return response.data.data
        }).catch(error => {
            const notification = {
                type: 'error',
                message: error.response.data.message
            }
            dispatch('notification/add', notification, {root: true})

            const errors = error.response.data.errors
            Object.keys(errors).forEach(key => {
                dispatch('error/add', {key, message: errors[key]}, {root: true})
            });

            throw error
        })
    },
    updateNotification({ commit, dispatch}, pushNotification) {

        return PushNotificationService.updateNotification(pushNotification).then(() => {
            commit('UPDATE_NOTIFICATION', pushNotification)

            const notification = {
                type: 'success',
                message: 'Notification has been updated!'
            }
            dispatch('notification/add', notification, {root: true})


        }).catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem updating the notification: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})

            const errors = error.response.data.errors
            Object.keys(errors).forEach(key => {
                dispatch('error/add', {key, message: errors[key]}, {root: true})
            });

            throw error
        })
    },
    deleteNotification({ commit, dispatch}, id) {

      const pushNotification = getters.getNotificationById(id)
      const pushNotificationIndex = state.pushNotifications.indexOf(pushNotification);

        return PushNotificationService.deleteNotification(id).then(() => {
            commit('DELETE_NOTIFICATION', pushNotificationIndex)

            const notification = {
                type: 'success',
                message: 'Notification has been delete!'
            }
            dispatch('notification/add', notification, {root: true})
        }).catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem deleting the notification: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})
            throw error
        })
    },
    fetchNotifications({commit, dispatch}, filters) {
      PushNotificationService.getNotifications(filters)
      .then(response => {
        commit('SET_NOTIFICATIONS', response.data.data)
        commit('SET_META', response.data.meta)
        commit('SET_NOTIFICATIONS_TOTAL', response.data.meta.total)
        commit('SET_IS_LOADING', false)
      })
      .catch(error => {
        const notification = {
            type: 'error',
            message: 'There was a problem getting notifications: ' + error.message
        }
        dispatch('notification/add', notification, {root: true})
      })
    },
    fetchNotification({commit, getters, dispatch}, id) {
      var pushNotification = getters.getNotificationById(id)

      if (pushNotification) {
          commit('SET_NOTIFICATION', pushNotification)
          commit('SET_IS_LOADING', false)
      } else {
        PushNotificationService.getNotification(id)
        .then(response => {
          commit('SET_NOTIFICATION', response.data.data)
          commit('SET_IS_LOADING', false)
        })
        .catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem getting a notification: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})
        })
      }

    }
  }

export const getters = {
    getNotificationById: state => id => {
      return state.pushNotifications.find(pushNotification => pushNotification.id == id)
    }
  }
