import apiClient from './apiClient.js'

export default {
    getDiscounts(filters) {
        let filtersEncoded = new URLSearchParams(filters).toString();
        return new apiClient().get('/discount?' + filtersEncoded);
    },
    newDiscount() {
        return new apiClient().get('/discount/create');
    },
    getDiscount(id) {
        return new apiClient().get('/discount/'+id);
    },
    postDiscount(discount) {
        return new apiClient().post('/discount', discount);
    },
    updateDiscount(discount) {
        return new apiClient().patch('/discount/'+discount.id, discount);
    },
    deleteDiscount(id) {
        return new apiClient().delete('/discount/'+id);
    }
}
