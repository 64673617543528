<template>
  <div class="discount">
    <h1>Discount - Create</h1>
    <div v-if="!isLoading">
      <form @submit.prevent="createDiscount">

        <BaseInput
          v-model="discount.title"
          label="Title"
          type="text"
          name="title"
         />
        <BaseInput
          v-model="discount.code"
          label="Code"
          type="text"
          name="code"
         />
        <BaseSelect
          :options="template.types"
          v-model="discount.type"
          label="Select a type"
          optionValue="id"
          optionName="name"
          name="type"
         />
        <BaseInput
          v-model="discount.amount"
          label="Amount"
          type="text"
          name="tax"
         />
        <BaseInput
          v-model="discount.expiry"
          label="Expiry"
          type="date"
          name="expiry"
         />

        <BaseButton type="submit" text="Add New Discount" />

      </form>
    </div>
  </div>
</template>

<script>
    // import Datepicker from 'vue3-datepicker'
    import { mapState } from 'vuex'

    export default {
        data() {
          return {
            discount: this.createFreshObject(),
            error: {}
          }
        },
        created() {
          this.$store.dispatch('error/clear')
          this.$store.dispatch('discount/newDiscount')
        },
        computed: {
          ...mapState({
            isLoading: state => state.discount.isLoading,
            template: state => state.discount.template
          })
        },
        methods: {
          createDiscount() {
            this.$store.dispatch('error/clear')
            this.$store.dispatch('discount/createDiscount', this.discount).then(response => {
              this.$router.push({
                name: 'discount-detail',
                params: { id: response.id }
              })
              this.discount = this.createFreshObject()
            }).catch(err => {
                this.error = err
            })
          },
          createFreshObject() {
            const discount = this.$store.state.discount
            const id = Math.floor(Math.random*10000)

            return {
              id: id,
              discount: discount
            }
          }
        }

    }
</script>
