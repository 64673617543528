import apiClient from './apiClient.js'


export default {
    getForms(filters) {
        let filtersEncoded = new URLSearchParams(filters).toString();
        return new apiClient().get('/form?' + filtersEncoded);
    },
    getForm(id) {
        return new apiClient().get('/form/'+id);
    },
    postForm(form) {
        return new apiClient().post('/form', form);
    },
    updateForm(form) {
        return new apiClient().patch('/form/'+form.id, form);
    },
    deleteForm(id) {
        return new apiClient().delete('/form/'+id);
    },
    authCheck(authToken) {

        if (authToken === null) {
            console.log('not logged in');
        }

    }
}
