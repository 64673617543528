<template>
  <div class="users">
    <h1>Subscription : {{ subscription.id }}</h1>
    <div>
    <p v-if="isLoading">Loading...</p>
    <template v-else >

      <div class="grid-x grid-margin-x" >
        <div class="cell medium-8">
          <div class="panel">
            <h3>Subscription Details</h3>
            <p>Plan: <strong>{{ subscription.title }}</strong></p>
            <p v-if="subscription.subscribed_user">User: <strong>{{ subscription.subscribed_user.display_name }}</strong></p>
            <p>Expiry: <strong>{{ subscription.expiry }}</strong></p>
            <p>Created: <strong>{{ subscription.created_at }}</strong></p>
            <p>Renewal Notice Sent: <span :class="{ 'badge badge--lozenge success' : subscription.renewal, 'badge badge--lozenge warning' : !subscription.renewal }">{{ subscription.renewal ? 'Yes' : 'Not Due'}}</span></p>
            <p>Auto Renew: <span :class="{ 'badge badge--lozenge success' : subscription.auto_renew, 'badge badge--lozenge warning' : !subscription.auto_renew }">{{ subscription.auto_renew ? 'On' : 'Off'}}</span></p>
          </div>
          <div class="panel" v-if="orderDetails">
            <h3>Associated Payments</h3>
            <p class="left" v-if="order.monthly && subscription.auto_renew" ><strong class="info badge badge--lozenge">When all payments are marked as paid the renewal for the next subscription cycle will be automatically sent to user</strong></p>

            <template v-if="orderDetails.payments">
              <table class="payments">
                <thead>
                  <tr>
                    <th class="caps">Amount</th>
                    <th>Due</th>
                    <th></th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(payment, paymentkey) in order.payments" :payment="payment" :key="'payment_' + paymentkey">
                    <td><strong>{{ payment.amount }}</strong></td>
                    <td><strong>{{ payment.due }}</strong></td>
                    <td><strong>{{ payment.type }}</strong></td>
                    <td><strong :class="{'warning badge badge--lozenge': !payment.paid, 'success badge badge--lozenge': payment.paid}">{{ payment.paid ? 'Paid' : 'Unpaid'}}</strong></td>
                    <td>
                      <BaseButton v-if="!payment.paid" class="button button--small" text="Mark Paid" @click="paymentPaid(paymentkey, payment.id)" />
                      <BaseButton v-else class="button button--small button--outline" text="Mark Unpaid" @click="paymentUnPaid(paymentkey, payment.id)" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </template>
            <p v-else>No associated payments found</p>

          </div>

        </div>
        <div class="cell medium-4">
          <div class="panel">
            <h3>Subscription Status</h3>
            <p>
              <span :class="{ 'badge badge--lozenge success' : subscription.status, 'badge badge--lozenge warning' : !subscription.status }">{{ subscription.status ? 'Status: Active' : 'Status: Inactive'}}</span>
              <BaseButton text="Activate" @click="subscriptionActivate(subscription.id)" class="button button--small" v-if="!subscription.status" />
              <BaseButton text="Deactivate" @click="subscriptionDeactivate(subscription.id)" class="button button--outline button--small" v-if="subscription.status" />
            </p>
            <p>
              <span :class="{ 'badge badge--lozenge success' : subscription.auto_renew, 'badge badge--lozenge warning' : !subscription.auto_renew }">{{ subscription.auto_renew ? 'Auto Renew: On' : 'Auto Renew: Off'}}</span>
              <BaseButton text="Activate" @click="subscriptionAutoRenewOn(subscription.id)" class="button button--small" v-if="!subscription.auto_renew" />
              <BaseButton text="Deactivate" @click="subscriptionAutoRenewOff(subscription.id)" class="button button--outline button--small" v-if="subscription.auto_renew" />
            </p>
          </div>
          <div class="panel">
            <h3>Associated Order: {{ subscription.order_id }}</h3>
            <p>
              <router-link :to="{ name: 'orders-detail', params: { id: subscription.order_id }}">
                <BaseButton text="View Order" class="button button--outline" />
              </router-link>
            </p>
          </div>
          <div class="panel" v-if="subscription.subscribed_user">
            <h3>User: {{ subscription.subscribed_user.display_name }}</h3>
            <p>
              <router-link :to="{ name: 'users-detail', params: { id: subscription.user_id }}">
                <BaseButton text="View User" class="button button--outline" />
              </router-link>
            </p>
          </div>
        </div>
      </div>
    </template>
    </div>
  </div>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        props: ["id"],
        data() {
          return {
            isEditing: false
          }
        },
        components : {

        },
        created() {
          this.$store.dispatch('error/clear')
          this.$store.dispatch('subscription/fetchSubscription', this.id)
          this.$store.dispatch('order/clearOrder')

        },
        computed: {
          isLoading() {
            return this.subscription.isLoading
          },
          isDisabled() {
            return false
          },
          ...mapState({
            subscription: state => state.subscription.subscription,
            order: state => state.order.order
          }),
          orderDetails() {
            if (this.subscription.order_id !== undefined && this.order.payments == undefined && this.subscription.order_id) {
              this.$store.dispatch('order/fetchOrder', this.subscription.order_id)
            }
            return this.order || {}
          }
        },
        methods: {
          remove() {
            this.$store.dispatch('subscription/deleteSubscription', this.id)
          },
          update() {
            this.$store.dispatch('error/clear')
            this.$store.dispatch('subscription/updateSubscription', this.subscription)
            this.isEditing = false
          },
          edit() {
            this.isEditing = (this.isEditing) ? false : true

          },
          subscriptionActivate(id) {
            const data = {data: {id: id, status: 1}}
            this.$store.dispatch('error/clear')
            this.$store.dispatch('subscription/updateSubscription', data)
          },
          subscriptionDeactivate(id) {
            const data = {data: {id: id, status: 0}}
            this.$store.dispatch('error/clear')
            this.$store.dispatch('subscription/updateSubscription', data)
          },
          subscriptionAutoRenewOn(id) {
            const data = {data: {id: id, auto_renew: 1}}
            this.$store.dispatch('error/clear')
            this.$store.dispatch('subscription/updateSubscription', data)
          },
          subscriptionAutoRenewOff(id) {
            const data = {data: {id: id, auto_renew: 0}}
            this.$store.dispatch('error/clear')
            this.$store.dispatch('subscription/updateSubscription', data)
          },
          paymentPaid(key, id) {
            const data = {key: key, data: {id: id, paid: 1}}
            this.$store.dispatch('error/clear')
            this.$store.dispatch('payment/updatePayment', data)

            this.order.payments[key].paid = 1
          },
          paymentUnPaid(key, id) {
            const data = {key: key, data: {id: id, paid: 0}}
            this.$store.dispatch('error/clear')
            this.$store.dispatch('payment/updatePayment', data)

            this.order.payments[key].paid = 0

          },
        }

    }
</script>
<style lang="scss">

  .left .badge {
    text-align:left;
    margin-bottom: 10px;
  }
</style>
