<template>
  <h1>User: Create Address</h1>

  <form @submit.prevent="create">

    <BaseInput
        v-model="address.address.street_address_1"
        label="Street Address 1"
        type="text"
        name="street_address_1"
    />

    <BaseInput
        v-model="address.address.street_address_2"
        label="Street Address 2"
        type="text"
        name="street_address_2"
    />

    <BaseInput
        v-model="address.address.city"
        label="City"
        type="text"
        name="city"
    />

    <BaseInput
        v-model="address.address.county"
        label="County"
        type="text"
        name="county"
    />

    <BaseInput
        v-model="address.address.postcode"
        label="Postcode"
        type="text"
        name="postcode"
    />

    <BaseSelect
      :options="template.countries"
      v-model="address.address.country"
      label="Select a country"
     />

    <BaseButton type="submit" text="Update" />

    <BaseButton class="button" text="Cancel" @click="cancel" />
  </form>
</template>
<script>

import { mapState } from 'vuex'

export default {
  props: ["id"],
  data() {
    return {
      address: this.$store.state.address
    }
  },
  created() {
    this.$store.dispatch('error/clear')
    this.$store.dispatch('address/newAddress', this.id)
  },
  computed: {
    ...mapState({
      template: state => state.address.template
    })
  },
  methods: {
    create() {
      this.address.address.user_id = this.id
      this.$store.dispatch('address/createAddress', {
        id: this.id,
        address: this.address.address
      }).then(() => {
        this.$router.push({
          name: 'users-addresses',
          params: { id: this.id }
        })
      }).catch(err => {
        this.error = err
      })
    },
    cancel() {
      this.$router.push({
        name: 'users-addresses',
        params: { id: this.id }
      })
    }
  }
}
</script>

<style scoped>

</style>
