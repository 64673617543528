<template>
    <div class=" link-container" >
        <div class="grid-x grid-margin-x cell" >
            <div class="medium-8 cell">
                <label :for="modelValue" :class="fieldErrorClass" >
                    <span v-if="help" data-tooltip class="top" tabindex="2" :title="help">
                        {{ label}}
                    </span>
                    <template  v-if="!help" >
                        {{ label}}:
                    </template>
                </label>
                <input
                    v-bind="$attrs"
                    :placeholder="label"
                    :class="fieldErrorClass"
                    :value="link"
                    type="text"
                    @input="$emit('update:modelValue', $event.target.value + ':' + newWindow)"
                />
            </div>
            <div class="medium-4 cell">

              <BaseSwitch
                v-model="newWindow"
                :options="[{name: 'No'},{name: 'Yes'}]"
                optionName="name"
                label="Open in new window"
               />
           </div>
       </div>
        <template v-if="errors.length > 0">
          <span class="message message--error" v-for="(message, keyMessage) in fieldErrors" :key="keyMessage">{{ message }}</span>
        </template>
   </div>
</template>

<script>

import { mapState } from 'vuex'
export default {
    data() {
        return {
            newWindow: this.newWindowOnLoad()
        }
    },
    props: {
        label: {
            type: String,
            default: ''
        },
        modelValue: {
            type: [String],
            default: ''
        },
        name: {
            type: String,
            defualt: ''
        },
        help: {
            type: [String,Boolean],
            default: false
        }
    },
    computed: {
        ...mapState('error', ['errors']),
        fieldErrorClass() {
            if (this.errors.length > 0) {
                if (this.errors.find(error => error.key == this.name)) {
                    return 'error';
                }
            }
            return ''
        },
        fieldErrors() {
            if (this.errors.length > 0) {
                if (this.errors.find(error => error.key == this.name)) {
                    if (this.errors.find(error => error.key == this.name).message.length > 0) {
                        return this.errors.find(error => error.key == this.name).message
                    }
                }
            }
            return []
        },
        link() {
            var items = this.modelValue !== null ? this.modelValue.split(':true') : ['']
                items = items[0].split(':false')
            return items[0]
        }

    },
    methods: {
        newWindowOnLoad() {
            return this.modelValue !== null ? this.modelValue.includes(':true') : false
        }
    },
    watch: {
        newWindow(newValue) {
          this.$emit('update:modelValue', this.link + ':' + newValue)
        },
    }
}
</script>

<style lang="scss">
@import "@/assets/scss/_settings.scss";
    .link-container {
        background-color: $cotton;
        padding: rem-calc(0 10);
    }
</style>
