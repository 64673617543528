<template>
    <div data-alert class="callout" :class="notificationTypeClass">
        <a href="#" class="close" @click.prevent="remove(notification)">&times;</a>
        <p>{{ notification.message }}</p>
    </div>
</template>

<script>
    import {mapActions} from 'vuex'

    export default {
        props: {
            notification: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                timeout: null
            }
        },
        mounted() {
            this.timeout = setTimeout(() => this.remove(this.notification), 5000)

        },
        beforeUnmount() {
            clearTimeout(this.timeout)
        },
        computed: {
            notificationTypeClass() {
                return this.notification.type;
            }
        },
        methods: mapActions('notification', ['remove'])
    }

</script>

<style scoped></style>
