<template v-if="!isLoading && !isLoggedIn">
  <div class="login__form">
    <svg class="banner__logo" width="45" height="50" viewBox="0 0 45 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.05557 49.6357C14.9049 49.8998 19.6425 44.4506 18.579 38.7205C18.4828 38.0796 16.0117 21.7416 14.8736 14.2159L27.1015 11.5993C29.0857 13.5725 31.0644 15.5474 33.0374 17.5238L22.8427 21.0334C21.6685 21.4775 20.1959 21.6479 19.7147 23.021C19.2744 24.0917 19.8446 25.0279 20.3355 25.9257L29.7362 44.0281C29.8052 44.1412 29.8992 44.2371 30.011 44.3084C30.1227 44.3798 30.2494 44.4247 30.3813 44.4397C30.5131 44.4548 30.6467 44.4396 30.7717 44.3953C30.8968 44.351 31.0101 44.2787 31.1029 44.1841L43.8746 28.984C44.3196 28.4779 44.6457 27.879 44.8292 27.2312C45.0127 26.5833 45.0489 25.9027 44.9351 25.2391C44.8214 24.5755 44.5606 23.9456 44.1717 23.3954C43.7829 22.8452 43.2758 22.3885 42.6875 22.0587C42.0992 21.7289 41.4446 21.5343 40.7713 21.4891C40.098 21.4438 39.4231 21.5492 38.7958 21.7973C38.1685 22.0455 37.6047 22.4303 37.1455 22.9236C36.6862 23.4168 36.3431 24.0062 36.1413 24.6486L30.4556 41.3779C30.3377 41.037 24.9215 25.1743 24.8181 24.8694L34.7842 18.5561C34.9342 18.4857 35.0575 18.369 35.136 18.2234C35.2144 18.0777 35.2438 17.9107 35.2197 17.7471C34.9791 13.5822 34.7024 9.41483 34.4474 5.24751C34.3199 1.5723 30.2535 -1.05148 26.832 0.412841C25.7685 0.823332 10.9516 6.5582 9.84718 6.99269C8.83661 7.35998 7.83807 7.71285 7.15713 8.64906C6.4353 9.54206 6.35589 10.6319 6.09603 11.6881C5.73993 13.2989 0.653384 36.3848 0.350212 37.7651C-1.29076 43.4856 3.04026 49.5181 9.05557 49.6357Z" fill="white"/>
    </svg>
    <form @submit.prevent="login">
        <BaseInput
          v-model="email"
          label="Email"
          type="email"
          name="email"
         />
        <BaseInput
          v-model="password"
          label="Password"
          type="password"
          name="password"
         />

        <BaseButton type="submit" class="button button--outline--white" text="Login" />
 </form>
  </div>
</template>

<script>
  import {authComputed} from '@/store/helpers.js'

export default {
    name: 'Login',
    data() {
        return {
            email: '',
            password: '',
            error: null,
            isLoading: true
        }
    },
    created() {
       this.$store.dispatch('authentication/resetLogin').then(() => {
        this.isLoading = false
       })
    },
    computed: {
      ...authComputed
    },
    methods: {
        login () {
          this.isLoading = true
          this.$store.dispatch('error/clear')
          this.$store.dispatch('authentication/login', {
              email: this.email,
              password: this.password
            })
            .then(() => {
                location.reload()
            })
            .catch(() => {
                this.isLoading = false
            })
        }
    }
}
</script>
