<template>
  <h1>Content Views & Downloads</h1>
    <template v-if="!isFilterActive">
      <BaseButton text="Filter" @click="filterToggle" />
    </template>
    <div v-else class="card">
      <div class="card-divider" style="justify-content: space-between;">
        <h3 class="no-margin">Filter</h3>
        <BaseButton text="X" class="button button--close" @click="filterToggle" />
      </div>
      <div class="card-section gid-container">
        <form @submit.prevent="filterResults" class="grid-x grid-margin-x">
          <div class="cell medium-4 large-3">
            <BaseInput
              v-model="filter.q"
              label="Search"
              type="search"
              name="q"
             />
          </div>
          <div class="cell medium-4 large-3">
            <BaseSelect
              :options="content.meta.filters.status || []"
              v-model="filter.status"
              label="Select a status"
              optionValue="id"
              optionName="name"
             />
          </div>
          <div class="cell medium-4 large-3">
            <BaseSelect
              :options="content.meta.filters.allow_feature || []"
              v-model="filter.allow_feature"
              label="Filter by is featured"
              optionValue="id"
              optionName="name"
             />
          </div>
          <div class="cell medium-4 large-3">
            <BaseSelect
              :options="content.meta.filters.is_premium || []"
              v-model="filter.is_premium"
              label="Filter by Premium/Freemium"
              optionValue="id"
              optionName="name"
             />
          </div>
          <div class="cell medium-4 large-3">
            <BaseSelect
              :options="content.meta.filters.is_demo || []"
              v-model="filter.is_demo"
              label="Filter by demo"
              optionValue="id"
              optionName="name"
             />
           </div>
          <div class="cell medium-4 large-3">
            <BaseSelect
              :options="content.meta.filters.category || []"
              v-model="filter.category"
              label="Filter by category"
              optionValue="id"
              optionName="listTitle"
             />
           </div>
          <div class="cell medium-4 large-3">
            <BaseSelect
              :options="content.meta.filters.tags || []"
              v-model="filter.tags"
              label="Filter by tag"
              optionValue="id"
              optionName="title"
             />
           </div>
          <div class="cell medium-4 large-3">
            <BaseSelect
              :options="content.meta.filters.media || []"
              v-model="filter.media_type"
              label="Filter by media type"
              optionValue="id"
              optionName="name"
             />
           </div>
          <div class="cell medium-4 large-3">
            <BaseSelect
              :options="content.meta.filters.type || []"
              v-model="filter.content_type"
              label="Filter by type"
              optionValue="id"
              optionName="name"
             />
          </div>

          <div class="cell medium-4 large-3">
            <BaseDate
              v-model="filter.view_from"
              label="Accessed from"
             />
          </div>
          <div class="cell medium-4 large-3">
            <BaseDate
              v-model="filter.view_until"
              label="Accessed until"
             />
          </div>
          <div class="cell medium-4 large-3">
            <BaseButton type="submit" text="Filter" style="margin-top: 27px;" />
          </div>
        </form>
      </div>
    </div>

    <BaseButton text="Export CSV" class="button--outline" @click="exportCSV" :disabled="exporting" :class="{'active' : exporting}" />



  <p v-if="isLoading">Loading...</p>

  <div v-else>
    <div v-for="(content) in content.views" :key="content.id">
      <div class="loop-list" v-if="content.id">
        <div class="loop-list__details">
          <div>
            <p>{{ content.title }}</p>
            <p>Number of views: {{ content.total_views }}</p>
            <p>Number of downloads: {{ content.total_downloads }}</p>
          </div>
          <div>
            <router-link :to="{ name: 'content-detail', params: { id: content.id }}"><BaseButton text="View Content" /></router-link>
            <router-link :to="{ name: 'content-views-detail', params: { id: content.id }}"><BaseButton text="View Users" /></router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapState} from "vuex";

  export default {
    data() {
      return {
        isFilterActive: false,
        exporting: false,
        filter: {
          q: this.$route.query.q || '',
          perPage: 10,
          page: parseInt(this.$route.query.page) || 1,
          category: parseInt(this.$route.query.category) || 'all',
          tags: parseInt(this.$route.query.tags) || 'all',
          status: this.$route.query.status || 'all',
          allow_feature: this.$route.query.allow_feature || 'all',
          is_premium: this.$route.query.is_premium || 'all',
          is_demo: this.$route.query.is_demo || 'all',
          media: this.$route.query.media || 'all',
          type: this.$route.query.type || 'all',
          view_from: this.$route.query.view_from || '',
          view_until: this.$route.query.view_until || '',
        }
      }
    },
    created() {
      this.$store.dispatch('content/fetchContentViews', this.filter)
    },
    computed: {
      perPage(){
        return this.filter.perPage;
      },
      page() {
        return this.filter.page;
      },
      totalContentList() {
        return this.content.contentListTotal
      },
      isLoading() {
        return this.content.isLoading
      },
      ...mapState(['content'])
    },
    methods: {
      filterToggle() {
        this.isFilterActive = (this.isFilterActive) ? false : true
      },
      filterResults() {
        console.log(this.q);
        this.$store.dispatch('content/fetchContentViews', this.filter)
      },
      exportCSV() {
        this.exporting = true;
        this.$store.dispatch('content/exportContentViews', this.filter).then(() => {
          this.exporting = false;
        })
      }
    }
  }
</script>

<style scoped>

</style>
