import SubscriptionPlanService from '@/services/SubscriptionPlanService.js'
import ContentService from '@/services/ContentService.js'

export const namespaced = true

const blankPlan = {
            categories: {
                assigned: [],
                available: []
            },
            tags: {
                assigned: [],
                available: []
            },
            blocks: [],
            types: []
        }

export const state = {
        subscriptionPlans: [],
        subscriptionPlansTotal: 0,
        subscriptionPlan: blankPlan,
        isLoading: true,
        templates: [],
        template: {
            mediaTypes: [],
            contentTypes: [],
        },
        meta: {},
    }
export const mutations = {
    ADD(state, subscriptionPlan) {
        state.subscriptionPlans.push(subscriptionPlan)
    },
    UPDATE(state, subscriptionPlan) {
        state.subscriptionPlan = subscriptionPlan
    },
    SET_LIST(state, subscriptionPlans) {
        state.subscriptionPlans = subscriptionPlans
    },
    SET_META(state, meta) {
        state.meta = meta
    },
    SET_LIST_TOTAL(state, total) {
        state.subscriptionPlansTotal = total
    },
    SET_ITEM(state, subscriptionPlan) {
        state.subscriptionPlan = subscriptionPlan

        // JSON.parse(JSON.stringify( - Used for deep copy of object
        const types = JSON.parse(JSON.stringify(state.subscriptionPlan.types))
        const blocks = JSON.parse(JSON.stringify(state.subscriptionPlan.blocks))
        const typesKeys = Object.keys(types)
        const templates = []

        state.subscriptionPlan.blocks.forEach((blockData) => {

            const templateType = typesKeys.filter(type => {
                return types[type].id === blockData.type
            })

            const duplicateType = JSON.parse(JSON.stringify(types[templateType]))

            duplicateType.structure.forEach((fieldData, fieldKey) => {
                if (fieldData.fields !== undefined) {

                    let numberOfValues = 0;

                    fieldData.fields[0].forEach((subFieldData) => {

                        if (blockData.fields[subFieldData.name] !== undefined && blockData.fields[subFieldData.name].length > numberOfValues) {
                            numberOfValues = (blockData.fields[subFieldData.name] !== undefined) ? blockData.fields[subFieldData.name].length : 0
                        }
                    })

                    //Target first key as this is orignal structure we want to duplicate start loop at one because we already have the first one set!
                    const duplicateFields = fieldData.fields[0]
                    for (let i = 1; i < numberOfValues; i++) {
                        duplicateType.structure[fieldKey].fields.push(duplicateFields)
                    }
                }
            })

            templates.push(duplicateType)

        })

        // Goes thorugh templates and blocks incase new fields have been added to the content type and need to have a default value added
        templates.forEach((templateData, templateKey) => {
            const fields = Object.keys(blocks[templateKey].fields)
            templateData.structure.forEach((structureData) => {

                if (structureData.fields) {

                    structureData.fields[0].forEach((subField) => {
                        if (!fields.includes(subField.name)) {
                            for (let i = 0; i < structureData.fields.length; i++) {
                                if (blocks[templateKey].fields[subField.name] === undefined) {
                                    blocks[templateKey].fields[subField.name] = [{value: ''}]
                                } else {
                                    blocks[templateKey].fields[subField.name].push({value: ''})
                                }
                            }
                        }
                    })

                } else if (!fields.includes(structureData.name)) {
                    blocks[templateKey].fields[structureData.name] = [{value: ''}];
                }
            })
        })

        state.subscriptionPlan.blocks = blocks
        state.templates = templates

    },
    SET_IS_LOADING(state, isLoading) {
        state.isLoading = isLoading
    },
    ADD_TYPE(state, id) {
        const types = JSON.parse(JSON.stringify(state.subscriptionPlan.types))
        const typesKeys = Object.keys(types)

        const templateType = typesKeys.filter(type => {
            return types[type].id == id
        })

        state.templates.push(types[templateType])

        const data = { type: id, content: state.subscriptionPlan.id, fields:{} }

        types[templateType].structure.forEach(field => {
            if (field.type === 'repeater') {
                //Target the first array entry as this is the one we want to use
                field.fields[0].forEach(subField => {
                    const values = []

                    if (subField.default !== undefined) {
                        for (let i = 0; i < subField.default.length; i++) {
                            const value = subField.default !== undefined ? subField.default[i] : ''
                            values.push({value: value})
                        }
                    } else {
                        values.push({value: ''})
                    }

                    data.fields[subField.name] = values
                })
            } else {
                if (field.default !== undefined) {
                    const value = field.default !== undefined ? field.default[0] : ''
                    data.fields[field.name] = [{value: value}]
                } else {
                    data.fields[field.name] = [{value: ''}]
                }
            }
        })
        state.subscriptionPlan.blocks.push(data)
    },
    UPDATE_BLOCK(state, data) {
        state.subscriptionPlan.blocks[data.key] = data.data
    },
    ADD_FIELD(state, data) {
        state.templates[data.key].structure[data.repeaterKey].fields.push(data.field)

        data.field.forEach(fieldData => {
            const value = (state.templates[data.key].structure[data.repeaterKey].default !== undefined) ? state.templates[data.key].structure[data.repeaterKey].default[state.subscriptionPlan.blocks[data.key].fields[fieldData.name].length-1] : ''
            state.subscriptionPlan.blocks[data.key].fields[fieldData.name].push({value: value})
        })
    },
  }

export const actions = {
    createSubscriptionPlan({ commit, dispatch}, subscriptionPlan) {

        return SubscriptionPlanService.postSubscriptionPlan(subscriptionPlan).then(response => {
            commit('ADD', subscriptionPlan)
            const notification = {
                type: 'success',
                message: 'New plan has been created!'
            }
            dispatch('notification/add', notification, {root: true})

            return response.data.data
        }).catch(error => {
            const notification = {
                type: 'error',
                message: error.response.data.message
            }
            dispatch('notification/add', notification, {root: true})

            const errors = error.response.data.errors
            Object.keys(errors).forEach(key => {
                dispatch('error/add', {key, message: errors[key]}, {root: true})
            });

            throw error
        })
    },
    updateSubscriptionPlan({ commit, dispatch}, subscriptionPlan) {

        return SubscriptionPlanService.updateSubscriptionPlan(subscriptionPlan).then(response => {
            commit('UPDATE', response.data.data)

            const notification = {
                type: 'success',
                message: 'Plan has been updated!'
            }
            dispatch('notification/add', notification, {root: true})


        }).catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem updating the plan: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})

            const errors = error.response.data.errors
            Object.keys(errors).forEach(key => {
                dispatch('error/add', {key, message: errors[key]}, {root: true})
            });

            throw error
        })
    },

    fetchSubscriptionPlans({commit, dispatch}, filters) {
      SubscriptionPlanService.getSubscriptionPlans(filters)
      .then(response => {
        commit('SET_LIST', response.data.data)
        commit('SET_META', response.data.meta)
        if(response.data.meta !== undefined) {
            commit('SET_LIST_TOTAL', response.data.meta.total)
        }
        commit('SET_IS_LOADING', false)
      })
      .catch(error => {
        const notification = {
            type: 'error',
            message: 'There was a problem getting the plans: ' + error.message
        }
        dispatch('notification/add', notification, {root: true})
      })
    },
    fetchSubscriptionPlan({commit, dispatch}, id) {

        SubscriptionPlanService.getSubscriptionPlan(id)
        .then(response => {
          commit('SET_ITEM', response.data.data)
          commit('SET_IS_LOADING', false)
        })
        .catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem getting a plan: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})
        })

    },
    duplicateField({commit}, data) {
        commit('ADD_FIELD', data)
    },
    duplicateType({commit, dispatch}, id) {
        commit('ADD_TYPE', id)

        const newKey = (state.subscriptionPlan.blocks.length-1)
        const data = {key: newKey, data: state.subscriptionPlan.blocks[newKey]}
        dispatch('saveBlock', data)
    },
    saveBlock({ commit, dispatch}, data) {

        const blockData = {type: data.data.type, content: state.subscriptionPlan.id, fields: data.data.fields, model: 'SubscriptionPlan'}

        return ContentService.postBlock(blockData).then(response => {

            commit('UPDATE_BLOCK', {key: data.key, data: { id: response.data.data.id, subscriptionPlan: state.subscriptionPlan.id, type: response.data.data.type, fields: response.data.data.fields}})

            const notification = {
                type: 'success',
                message: 'Saved'
            }
            dispatch('notification/add', notification, {root: true})


        }).catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem saving the block: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})

            const errors = error.response.data.errors
            Object.keys(errors).forEach(key => {
                dispatch('error/add', {key, message: errors[key]}, {root: true})
            });

            throw error
        })
    },
    updateBlock({ commit, dispatch}, data) {

        const blockData = {type: data.data.type, content: state.subscriptionPlan.id, fields: data.data.fields}

        return ContentService.updateBlock(data.data.id, blockData).then(response => {
            commit('UPDATE_BLOCK', {key: data.key, data: { id: data.data.id, subscriptionPlan: state.subscriptionPlan.id, type: data.data.type, fields: response.data.data.fields}})

            const notification = {
                type: 'success',
                message: 'Saved'
            }
            dispatch('notification/add', notification, {root: true})


        }).catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem saving the block: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})

            const errors = error.response.data.errors
            Object.keys(errors).forEach(key => {
                dispatch('error/add', {key, message: errors[key]}, {root: true})
            });

            throw error
        })
    },
  }

export const getters = {
    getSubscriptionPlanById: state => id => {
      return state.subscriptionPlans.find(subscriptionPlan => subscriptionPlan.id == id)
    }
  }
