import SubscriptionService from '@/services/SubscriptionService.js'

export const namespaced = true

export const state = {
        subscriptions: [],
        subscriptionsTotal: 0,
        subscription: {},
        isLoading: true,
        meta: {},
    }
export const mutations = {
    ADD(state, subscription) {
        state.subscriptions.push(subscription)
    },
    UPDATE(state, subscription) {
        state.subscription = subscription
    },
    DELETE(state, index) {
        state.subscriptions.splice(index)
    },
    SET_LIST(state, subscriptions) {
        state.subscriptions = subscriptions
    },
    SET_META(state, meta) {
        state.meta = meta
    },
    SET_LIST_TOTAL(state, total) {
        state.subscriptionsTotal = total
    },
    SET_ITEM(state, subscription) {
        state.subscription = subscription
    },
    SET_IS_LOADING(state, isLoading) {
        state.isLoading = isLoading
    }
  }

export const actions = {
    createSubscription({ commit, dispatch}, subscription) {

        return SubscriptionService.postSubscription(subscription).then(response => {
            commit('ADD', subscription)
            const notification = {
                type: 'success',
                message: 'New subscription has been created!'
            }
            dispatch('notification/add', notification, {root: true})

            return response.data.data
        }).catch(error => {
            const notification = {
                type: 'error',
                message: error.response.data.message
            }
            dispatch('notification/add', notification, {root: true})

            const errors = error.response.data.errors
            Object.keys(errors).forEach(key => {
                dispatch('error/add', {key, message: errors[key]}, {root: true})
            });

            throw error
        })
    },
    updateSubscription({ commit, dispatch}, data) {

        return SubscriptionService.updateSubscription(data.data).then(response => {
            commit('UPDATE', response.data.data)

            const notification = {
                type: 'success',
                message: 'Subscription has been updated!'
            }
            dispatch('notification/add', notification, {root: true})


        }).catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem updating the subscription: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})

            const errors = error.response.data.errors
            Object.keys(errors).forEach(key => {
                dispatch('error/add', {key, message: errors[key]}, {root: true})
            });

            throw error
        })
    },
    deleteSubscription({ commit, dispatch}, id) {

      const subscription = getters.getSubscriptionById(id)
      const subscriptionIndex = state.subscriptions.indexOf(subscription);

        return SubscriptionService.deleteSubscription(id).then(() => {
            commit('DELETE', subscriptionIndex)

            const notification = {
                type: 'success',
                message: 'Subscription has been deleted!'
            }
            dispatch('notification/add', notification, {root: true})
        }).catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem deleting the subscription: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})
            throw error
        })
    },
    fetchSubscriptions({commit, dispatch}, filters) {
      SubscriptionService.getSubscriptions(filters)
      .then(response => {
        commit('SET_LIST', response.data.data)
        commit('SET_META', response.data.meta)
        if(response.data.meta !== undefined) {
            commit('SET_LIST_TOTAL', response.data.meta.total)
        }
        commit('SET_IS_LOADING', false)
      })
      .catch(error => {
        const notification = {
            type: 'error',
            message: 'There was a problem getting the subscriptions: ' + error.message
        }
        dispatch('notification/add', notification, {root: true})
      })
    },
    fetchSubscription({commit, dispatch}, id) {

        SubscriptionService.getSubscription(id)
        .then(response => {
          commit('SET_ITEM', response.data.data)
          commit('SET_IS_LOADING', false)
        })
        .catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem getting a subscription: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})
        })

    }
  }

export const getters = {
    getSubscriptionById: state => id => {
      return state.subscriptions.find(subscription => subscription.id == id)
    }
  }
