import apiClient from './apiClient.js'


export default {
    getMessages(filters) {
        let filtersEncoded = new URLSearchParams(filters).toString();
        return new apiClient().get('/message?' + filtersEncoded);
    },
    authCheck(authToken) {

        if (authToken === null) {
            console.log('not logged in');
        }

    }
}
