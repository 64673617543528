import DiscountService from '@/services/DiscountService.js'

export const namespaced = true

export const state = {
        discounts: [],
        discountsTotal: 0,
        discount: {},
        isLoading: true,
        meta: {},
        template: {},
    }
export const mutations = {
    ADD(state, discount) {
        state.discounts.push(discount)
    },
    UPDATE(state, discount) {
        state.discount = discount
    },
    DELETE(state, index) {
        state.discounts.splice(index)
    },
    SET_LIST(state, discounts) {
        state.discounts = discounts
    },
    SET_META(state, meta) {
        state.meta = meta
    },
    SET_LIST_TOTAL(state, total) {
        state.discountsTotal = total
    },
    SET_ITEM(state, discount) {
        state.discount = discount
    },
    SET_IS_LOADING(state, isLoading) {
        state.isLoading = isLoading
    },
    SET_TEMPLATE(state, template) {
        state.template = template
    }
  }

export const actions = {
    createDiscount({ commit, dispatch}, discount) {

        return DiscountService.postDiscount(discount).then(response => {
            commit('ADD', discount)
            const notification = {
                type: 'success',
                message: 'New discount has been created!'
            }
            dispatch('notification/add', notification, {root: true})

            return response.data.data
        }).catch(error => {
            const notification = {
                type: 'error',
                message: error.response.data.message
            }
            dispatch('notification/add', notification, {root: true})

            const errors = error.response.data.errors
            Object.keys(errors).forEach(key => {
                dispatch('error/add', {key, message: errors[key]}, {root: true})
            });

            throw error
        })
    },
    updateDiscount({ commit, dispatch}, data) {

        return DiscountService.updateDiscount(data).then(response => {
            commit('UPDATE', response.data.data)

            const notification = {
                type: 'success',
                message: 'Discount has been updated!'
            }
            dispatch('notification/add', notification, {root: true})


        }).catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem updating the discount: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})

            const errors = error.response.data.errors
            Object.keys(errors).forEach(key => {
                dispatch('error/add', {key, message: errors[key]}, {root: true})
            });

            throw error
        })
    },
    deleteDiscount({ commit, dispatch}, id) {

      const discount = getters.getDiscountById(id)
      const discountIndex = state.discounts.indexOf(discount);

        return DiscountService.deleteDiscount(id).then(() => {
            commit('DELETE', discountIndex)

            const notification = {
                type: 'success',
                message: 'Discount rate has been deleted!'
            }
            dispatch('notification/add', notification, {root: true})
        }).catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem deleting the discount rate: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})
            throw error
        })
    },
    fetchDiscounts({commit, dispatch}, filters) {
      DiscountService.getDiscounts(filters)
      .then(response => {
        commit('SET_LIST', response.data.data)
        commit('SET_META', response.data.meta)
        if(response.data.meta !== undefined) {
            commit('SET_LIST_TOTAL', response.data.meta.total)
        }
        commit('SET_IS_LOADING', false)
      })
      .catch(error => {
        const notification = {
            type: 'error',
            message: 'There was a problem getting the discount rates: ' + error.message
        }
        dispatch('notification/add', notification, {root: true})
      })
    },
    fetchDiscount({commit, dispatch}, id) {

        DiscountService.getDiscount(id)
        .then(response => {
          commit('SET_ITEM', response.data.data)
          commit('SET_IS_LOADING', false)
        })
        .catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem getting a discount rate: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})
        })

    },
    newDiscount({commit, dispatch}) {
        DiscountService.newDiscount()
        .then(response => {
          commit('SET_TEMPLATE', response.data)
          commit('SET_IS_LOADING', false)
        })
        .catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem loading form data: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})
        })
    }
  }

export const getters = {
    getDiscountById: state => id => {
      return state.discounts.find(discount => discount.id == id)
    }
  }
