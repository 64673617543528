<template>
  <div class="baskets">
    <h1>Basket Data</h1>
    <template v-if="!isFilterActive">
      <BaseButton text="Filter" @click="filterToggle" />
    </template>
    <div v-else class="card">
      <div class="card-divider" style="justify-content: space-between;">
        <h3 class="no-margin">Filter</h3>
        <BaseButton text="X" class="button button--close" @click="filterToggle" />
      </div>
      <div class="card-section gid-container">
        <form @submit.prevent="filterResults" class="grid-x grid-margin-x">

          <div class="cell medium-4 large-3">
            <BaseSelect
              :options="basket.meta.filters.user || []"
              v-model="filter.user"
              label="User status"
              optionValue="id"
              optionName="name"
             />
          </div>
          <div class="cell medium-4 large-3">
            <BaseDate
              v-model="filter.created_from"
              label="Created from"
             />
          </div>
          <div class="cell medium-4 large-3">
            <BaseDate
              v-model="filter.created_until"
              label="Created until"
             />
          </div>

          <div class="cell medium-4 large-3">
            <BaseButton type="submit" text="Filter" style="margin-top: 27px;" />
          </div>
        </form>
      </div>
    </div>

    <BaseButton text="Export CSV" class="button--outline" @click="exportCSV" :disabled="exporting" :class="{'active' : exporting}" />


    <p v-if="isLoading">Loading...</p>
    <template v-else >
      <div>
        <ListLoop v-for="(item) in basket.baskets" :key="item.id" :item="item" :route="baskets" itemName="title">
          <template v-slot:buttons>
            &nbsp;
          </template>
          <template v-slot:details>
            <p>ID: <strong>{{ item.id }}</strong> - Token: <strong>{{ item.token }}</strong></p>
            <p>Product: <strong>{{ item.product.title }}</strong> - Option: <strong>{{ item.option.title }}</strong></p>
            <p>Status: <strong>{{ item.status }}</strong> - User: <strong>{{ item.user.display_name || item.user }}</strong></p>
            <p>Created: <strong>{{ item.created_at }}</strong></p>
          </template>
        </ListLoop>
      </div>

      <Pagination :page="page" :perPage="perPage" :filter="filter" routeName="baskets" :total="totalBaskets" />
    </template>

  </div>
</template>

<script>
    import ListLoop from '@/components/ListLoop.vue'
    import Pagination from '@/components/Pagination.vue'
    import { mapState } from 'vuex'

    export default {
        data() {
          return {
            isFilterActive: false,
            exporting: false,
            filter: {
              q: this.$route.query.q || '',
              perPage: 50,
              page: parseInt(this.$route.query.page) || 1,
              status: this.$route.query.status || 'all',
              user: this.$route.query.user || 'all',
              created_from: this.$route.query.created_from || '',
              created_until: this.$route.query.created_until || '',
            }
          }
        },
        components: {
          ListLoop,
          Pagination
        },
        created() {
          this.$store.dispatch('basket/fetchBaskets', this.filter)
        },
        computed: {
          perPage(){
            return this.filter.perPage;
          },
          page() {
            return this.filter.page;
          },
          totalBaskets() {
            return this.basket.basketsTotal
          },
          isLoading() {
            return this.basket.isLoading
          },
          stats() {
            return this.basket.stats
          },
          ...mapState(['basket'])
        },
        methods : {
          filterToggle() {
            this.isFilterActive = (this.isFilterActive) ? false : true
          },
          filterResults() {
            this.$store.dispatch('basket/fetchBaskets', this.filter)
          },
          exportCSV() {
            this.exporting = true;
            this.$store.dispatch('basket/exportBaskets', this.filter).then(() => {
              this.exporting = false;
            })
          }
        }

    }
</script>
