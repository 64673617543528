import PaymentService from '@/services/PaymentService.js'

export const namespaced = true

export const state = {
        payments: [],
        paymentsTotal: 0,
        payment: {},
        isLoading: true,
    }
export const mutations = {
    ADD(state, payment) {
        state.payments.push(payment)
    },
    UPDATE(state, payment) {
        state.payment = payment
    },
    DELETE(state, index) {
        state.payments.splice(index)
    },
    SET_LIST(state, payments) {
        state.payments = payments
    },
    SET_LIST_TOTAL(state, total) {
        state.paymentsTotal = total
    },
    SET_ITEM(state, payment) {
        state.payment = payment
    },
    SET_IS_LOADING(state, isLoading) {
        state.isLoading = isLoading
    }
  }

export const actions = {
    createpayment({ commit, dispatch}, payment) {

        return PaymentService.postPayment(payment).then(response => {
            commit('ADD', payment)
            const notification = {
                type: 'success',
                message: 'New payment has been created!'
            }
            dispatch('notification/add', notification, {root: true})

            return response.data.data
        }).catch(error => {
            const notification = {
                type: 'error',
                message: error.response.data.message
            }
            dispatch('notification/add', notification, {root: true})

            const errors = error.response.data.errors
            Object.keys(errors).forEach(key => {
                dispatch('error/add', {key, message: errors[key]}, {root: true})
            });

            throw error
        })
    },
    updatePayment({ commit, dispatch}, data) {

        return PaymentService.updatePayment(data.data).then(response => {
            commit('UPDATE', response.data.data)

            const notification = {
                type: 'success',
                message: 'payment has been updated!'
            }
            dispatch('notification/add', notification, {root: true})


        }).catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem updating the payment: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})

            const errors = error.response.data.errors
            Object.keys(errors).forEach(key => {
                dispatch('error/add', {key, message: errors[key]}, {root: true})
            });

            throw error
        })
    },
    deletePayment({ commit, dispatch}, id) {

      const payment = getters.getpaymentById(id)
      const paymentIndex = state.payments.indexOf(payment);

        return PaymentService.deletePayment(id).then(() => {
            commit('DELETE', paymentIndex)

            const notification = {
                type: 'success',
                message: 'payment has been deleted!'
            }
            dispatch('notification/add', notification, {root: true})
        }).catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem deleting the payment: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})
            throw error
        })
    },
    fetchpayments({commit, dispatch}, {perPage, page, q}) {
      PaymentService.getpayments(perPage, page, q)
      .then(response => {
        commit('SET_LIST', response.data.data)
        if(response.data.meta !== undefined) {
            commit('SET_LIST_TOTAL', response.data.meta.total)
        }
        commit('SET_IS_LOADING', false)
      })
      .catch(error => {
        const notification = {
            type: 'error',
            message: 'There was a problem getting the payments: ' + error.message
        }
        dispatch('notification/add', notification, {root: true})
      })
    },
    fetchPayment({commit, dispatch}, id) {

        PaymentService.getPayment(id)
        .then(response => {
          commit('SET_ITEM', response.data.data)
          commit('SET_IS_LOADING', false)
        })
        .catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem getting a payment: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})
        })

    }
  }

export const getters = {
    getpaymentById: state => id => {
      return state.payments.find(payment => payment.id == id)
    }
  }
