<template v-if="options.length == 0 || options.length == 2">
  <p>{{ label }}:</p>
  <div class="switch">
    <input v-bind="$attrs"
           type="checkbox"
           :checked="modelValue"
           :class="fieldErrorClass"
           @change="$emit('update:modelValue', $event.target.checked)"
           :id="inputId" />
    <label :for="inputId" :class="fieldErrorClass" v-if="label">{{ label }}</label>
    <p>{{ options[modelValue ? 1 : 0][optionName] }}</p>
  </div>
  <template v-if="errors.length > 0">
    <span class="message message--error" v-for="(message, keyMessage) in fieldErrors" :key="keyMessage">{{ message }}</span>
  </template>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data() {
    return {
      inputId: this.madeID(32)
    }
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    modelValue: {
      type: [Number, Boolean],
      default: false
    },
    name: String,
    options: {
        type: Array,
        required: true
    },
    optionName: {
        type: String,
        default: 'option'
    },
  },
  computed: {
    ...mapState('error', ['errors']),
    fieldErrorClass() {
      if (this.errors.length > 0) {
        if (this.errors.find(error => error.key == this.name)) {
          return 'error';
        }
      }
      return ''
    },
    fieldErrors() {
      if (this.errors.length > 0) {
        if (this.errors.find(error => error.key == this.name)) {
          if (this.errors.find(error => error.key == this.name).message.length > 0) {
            return this.errors.find(error => error.key == this.name).message
          }
        }
      }
      return []
    },

  },
  methods: {
    madeID(length) {
       var result           = '';
       var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
       var charactersLength = characters.length;
       for ( var i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
       }
       return result;
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/_settings.scss";
  .switch {
    margin-top: rem-calc(10);
    margin-bottom: rem-calc(2);
    display: block;
  }
  input[type=checkbox]{
    height: 0;
    width: 0;
    visibility: hidden;
  }

  label {
    cursor: pointer;
    text-indent: -9999px;
    width: 50px;
    max-width: 50px !important;
    height: 30px;
    background: grey;
    border-radius: 30px;
    position: relative;
    margin-right: 10px !important;
    display: inline-block !important;
    padding-left: 0 !important;
  }

  label:after {
    content: '' !important;
    position: absolute !important;
    top: 2px !important;
    left: 2px !important;
    width: 26px !important;
    height: 26px !important;
    background: #fff !important;
    border: none !important;
    border-radius: 28px !important;
    transition: 0.3s !important;
    opacity: 1 !important;
  }

  input:checked + label {
    background: #009eb5;
  }

  input:checked + label:after {
    left: calc(100% - 2px) !important;
    transform: translateX(-100%) !important;
  }

  label:active:after {
    width: 26px;
  }

  label:before {
    display: none !important;
  }

  p {
    display: inline-block;
    vertical-align: middle;
  }
</style>
