<template>
  <div class="forms">
    <h1>Forms - Create a new form</h1>
    <div>
      <form @submit.prevent="createForm">

        <BaseInput
          v-model="form.title"
          label="Name"
          type="text"
          name="title"
         />

        <BaseButton type="submit" text="Add New Form" />

      </form>
    </div>
  </div>
</template>

<script>
    // import Datepicker from 'vue3-datepicker'

    export default {
        data() {
          return {
            form: this.createFreshFormObject(),
            error: {},

          }
        },
        methods: {
          createForm() {
            this.$store.dispatch('error/clear')
            this.$store.dispatch('form/createForm', this.form).then(response => {
              this.$router.push({
                name: 'forms-detail',
                params: { id: response.id }
              })
              this.form = this.createFreshFormObject()
            }).catch(err => {
                this.error = err
            })
          },
          createFreshFormObject() {
            const form = this.$store.state.form
            const id = Math.floor(Math.random*10000)

            return {
              id: id,
              form: form
            }
          }
        }

    }
</script>
