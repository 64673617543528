export const namespaced = true

export const state = {
    errors : []
}

let nextId = 1

export const mutations = {
    PUSH(state, error) {
        state.errors.push({
            ...error,
            id: nextId++
        })
    },
    DELETE(state, errorToRemove) {
        state.errors = state.errors.filter(
            error => error.id !== errorToRemove.id
        )
    },
    CLEAR(state) {
        state.errors = [];
    }
}

export const actions = {
    add({commit}, error) {
        commit('PUSH', error)
    },
    remove({commit}, errorToRemove) {
        commit('DELETE', errorToRemove)
    },
    clear({commit}) {
        commit('CLEAR')
    }
}
