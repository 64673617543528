<template>
  <div class="content-category">
    <h1>Courses & Modules - Create</h1>
    <div>
      <form @submit.prevent="createContentCategory">

        <BaseInput
          v-model="contentCategory.title"
          label="Title"
          type="text"
          name="title"
         />

        <BaseSelect
          :options="template.master"
          v-model="contentCategory.master"
          label="Select main"
          optionValue="id"
          optionName="listTitle"
         />

        <BaseButton type="submit" text="Add" />

      </form>
    </div>
  </div>
</template>

<script>
    // import Datepicker from 'vue3-datepicker'
    import { mapState } from 'vuex'

    export default {
        data() {
          return {
            contentCategory: this.createFreshObject(),
            error: {}
          }
        },
        created() {
          this.$store.dispatch('error/clear')
          this.$store.dispatch('contentCategory/newContentCategory')
        },
        computed: {
          ...mapState({
            template: state => state.contentCategory.template
          })
        },
        methods: {
          createContentCategory() {
            this.$store.dispatch('error/clear')
            this.$store.dispatch('contentCategory/createContentCategory', this.contentCategory).then(response => {
              this.$router.push({
                name: 'content-category-detail',
                params: { id: response.id }
              })
              this.contentCategory = this.createFreshObject()
            }).catch(err => {
                this.error = err
            })
          },
          createFreshObject() {
            const contentCategory = this.$store.state.contentCategory
            const id = Math.floor(Math.random*10000)

            return {
              id: id,
              parent: 0,
              contentCategory: contentCategory
            }
          }
        }

    }
</script>
