import apiClient from './apiClient.js'


export default {
    getRoles(filters) {
        let filtersEncoded = new URLSearchParams(filters).toString();
        return new apiClient().get('/roles?' + filtersEncoded);
    },
    getRole(id) {
        return new apiClient().get('/roles/'+id);
    },
    postRole(role) {
        return new apiClient().post('/roles', role);
    },
    updateRole(role) {
        return new apiClient().patch('/roles/'+role.id, role);
    },
    deleteRole(id) {
        return new apiClient().delete('/roles/'+id);
    },
    authCheck(authToken) {

        if (authToken === null) {
            console.log('not logged in');
        }

    }
}
