import ProductTagService from '@/services/ProductTagService.js'

export const namespaced = true

export const state = {
        productTags: [],
        productTagsTotal: 0,
        productTag: {
            categories: {
                assigned: [],
                available: []
            },
            tags: {
                assigned: [],
                available: []
            }
        },
        isLoading: true,
        meta: {},
    }
export const mutations = {
    ADD(state, productTag) {
        state.productTags.push(productTag)
    },
    UPDATE(state, productTag) {
        state.productTag = productTag
    },
    DELETE(state, index) {
        state.productTags.splice(index)
    },
    SET_LIST(state, productTags) {
        state.productTags = productTags
    },
    SET_META(state, meta) {
        state.meta = meta
    },
    SET_LIST_TOTAL(state, total) {
        state.productTagsTotal = total
    },
    SET_ITEM(state, productTag) {
        state.productTag = productTag
    },
    SET_IS_LOADING(state, isLoading) {
        state.isLoading = isLoading
    }
  }

export const actions = {
    createProductTag({ commit, dispatch}, productTag) {

        return ProductTagService.postProductTag(productTag).then(response => {
            commit('ADD', productTag)
            const notification = {
                type: 'success',
                message: 'New product has been created!'
            }
            dispatch('notification/add', notification, {root: true})

            return response.data.data
        }).catch(error => {
            const notification = {
                type: 'error',
                message: error.response.data.message
            }
            dispatch('notification/add', notification, {root: true})

            const errors = error.response.data.errors
            Object.keys(errors).forEach(key => {
                dispatch('error/add', {key, message: errors[key]}, {root: true})
            });

            throw error
        })
    },
    updateProductTag({ commit, dispatch}, productTag) {

        return ProductTagService.updateProductTag(productTag).then(response => {
            commit('UPDATE', response.data.data)

            const notification = {
                type: 'success',
                message: 'Product tag has been updated!'
            }
            dispatch('notification/add', notification, {root: true})


        }).catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem updating the product tag: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})

            const errors = error.response.data.errors
            Object.keys(errors).forEach(key => {
                dispatch('error/add', {key, message: errors[key]}, {root: true})
            });

            throw error
        })
    },
    deleteProductTag({ commit, dispatch}, id) {

      const productTag = getters.getProductTagById(id)
      const productTagIndex = state.productTags.indexOf(productTag);

        return ProductTagService.deleteProductTag(id).then(() => {
            commit('DELETE', productTagIndex)

            const notification = {
                type: 'success',
                message: 'Product tag has been deleted!'
            }
            dispatch('notification/add', notification, {root: true})
        }).catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem deleting the product tag: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})
            throw error
        })
    },
    fetchProductTags({commit, dispatch}, filters) {
      ProductTagService.getProductTags(filters)
      .then(response => {
        commit('SET_LIST', response.data.data)
        commit('SET_META', response.data.meta)
        if(response.data.meta !== undefined) {
            commit('SET_LIST_TOTAL', response.data.meta.total)
        }
        commit('SET_IS_LOADING', false)
      })
      .catch(error => {
        const notification = {
            type: 'error',
            message: 'There was a problem getting the product tags: ' + error.message
        }
        dispatch('notification/add', notification, {root: true})
      })
    },
    fetchProductTag({commit, dispatch}, id) {

        ProductTagService.getProductTag(id)
        .then(response => {
          commit('SET_ITEM', response.data.data)
          commit('SET_IS_LOADING', false)
        })
        .catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem getting a product tag: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})
        })

    }
  }

export const getters = {
    getProductTagById: state => id => {
      return state.productTags.find(productTag => productTag.id == id)
    }
  }
