<template>
    <label :for="modelValue" :class="fieldErrorClass" >
        <span v-if="help" style="background-color:#eee; padding: 2px 10px; font-size: 11px; display: block;">
            {{ help }}
        </span>

            {{ label }}:

    </label>
    <input
        v-bind="$attrs"
        :placeholder="now"
        :class="fieldErrorClass"
        :value="modelValue"
        type="text"
        @input="$emit('update:modelValue', $event.target.value)"
    />
    <template v-if="errors.length > 0">
      <span class="message message--error" v-for="(message, keyMessage) in fieldErrors" :key="keyMessage">{{ message }}</span>
    </template>
</template>

<script>
    import { mapState } from 'vuex'
    export default {
        props: {
            label: {
                type: String,
                default: ''
            },
            modelValue: {
                type: [String, Number],
                default: ''
            },
            name: {
                type: String,
                defualt: ''
            },
            help: {
                type: [String,Boolean],
                default: false
            }
        },
        computed: {
            ...mapState('error', ['errors']),
            fieldErrorClass() {
                if (this.errors.length > 0) {
                    if (this.errors.find(error => error.key == this.name)) {
                        return 'error';
                    }
                }
                return ''
            },
            fieldErrors() {
                if (this.errors.length > 0) {
                    if (this.errors.find(error => error.key == this.name)) {
                        if (this.errors.find(error => error.key == this.name).message.length > 0) {
                            return this.errors.find(error => error.key == this.name).message
                        }
                    }
                }
                return []
            },
            now() {
                const dateTime = new Date();
                return dateTime.toISOString().slice(0,10) + ' 00:00:00';
            }

        }
    }
</script>

<style scoped>

</style>
