<template>
  <div class="users">
    <h1>Tax Rate : {{ tax.title }}</h1>
    <div>
    <p v-if="isLoading">Loading...</p>
    <template v-else >

      <div class="grid-x grid-margin-x" >
        <div class="panel cell medium-8">
          <h3>Rate Details</h3>
          <button class="button" @click="edit">Edit</button>
          <template v-if="!isEditing">
            <p><strong>{{ tax.title }}</strong></p>
            <p><strong class="info badge badge--lozenge badge--margin">Default Rate: {{ tax.rate }}%</strong></p>
          </template>
          <template v-else>
            <form @submit.prevent="update">

              <BaseInput
                v-model="tax.title"
                label="Title"
                type="text"
                name="title"
               />
              <BaseInput
                v-model="tax.rate"
                label="Default Rate"
                type="text"
                name="rate"
               />

              <BaseButton type="submit" text="Update" />
          </form>
          </template>

        </div>
        <div class="panel cell medium-4">
          <p>Click below to delete this tax rate.</p>
          <BaseButton text="Delete" @click="remove" class="button button--danger" />
        </div>

      </div>
      <div class="grid-x grid-margin-x" >
        <div class="panel cell medium-12">
          <h3 class="no-margin">Countries</h3>
          <p>All countries are listed here. By default all countries will use the rate assigned above, to override it for a specfic country enter a rate.</p>

          <div class="grid-x grid-margin-x " >
            <template v-if="countriesAvailable.length">

              <div v-for="(item) in countriesAvailable" :key="item.id" :item="item" class="cell panel panel--padding panel--dark medium-3 xlarge-2" >
                <p><strong>{{ item.code }} : {{ item.name }}</strong></p>

                <form @submit.prevent="update" class="form-min form-min--small">
                  <BaseInput
                    v-model="country[item.id]"
                    label="Rate"
                    type="text"
                    name="rate"
                   />
                  <BaseButton type="submit" text="Set" class="button button--small" @click.prevent="setRate(item.id)" />
                  <BaseButton v-if="country[item.id]" class="button button--outline button--small" text="Reset" @click.prevent="removeCountry(item.id)" />
                </form>
              </div>
            </template>
          </div>
        </div>
      </div>
    </template>
  </div>
</div>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        props: ["id"],
        data() {
          return {
            isEditing: false
          }
        },
        components : {

        },
        created() {
          this.$store.dispatch('error/clear')
          this.$store.dispatch('tax/fetchTax', this.id)

        },
        computed: {
          isLoading() {
            return this.tax.isLoading
          },
          isDisabled() {
            return false
          },
          ...mapState({
            tax: state => state.tax.tax
          }),
          countriesAvailable() {
            return (this.tax.countries) ? this.tax.countries.all : []
          },
          country() {
            const country = []
            if ((this.tax.countries)) {
              this.tax.countries.assigned.forEach((fieldData) => {
                country[fieldData.pivot.country_id] = fieldData.pivot.rate
              })
            }
            return country
          }
        },
        methods: {
          remove() {
            this.$store.dispatch('tax/deleteTax', this.id)
          },
          update() {
            this.$store.dispatch('error/clear')
            this.$store.dispatch('tax/updateTax', this.tax)
            this.isEditing = false
          },
          edit() {
            this.isEditing = (this.isEditing) ? false : true

          },
          removeCountry(country) {
            this.tax.addCountry = null
            this.tax.removeCountry = country
            this.update()
          },
          setRate(id) {
            this.tax.addCountry = {id: id, rate: this.country[id]}
            this.tax.removeCountry = null
            this.update()
          }
        }

    }
</script>
