import TaxService from '@/services/TaxService.js'

export const namespaced = true

export const state = {
        taxes: [],
        taxesTotal: 0,
        tax: {},
        isLoading: true,
        meta: {},
    }
export const mutations = {
    ADD(state, tax) {
        state.taxes.push(tax)
    },
    UPDATE(state, tax) {
        state.tax = tax
    },
    DELETE(state, index) {
        state.taxes.splice(index)
    },
    SET_LIST(state, taxes) {
        state.taxes = taxes
    },
    SET_META(state, meta) {
        state.meta = meta
    },
    SET_LIST_TOTAL(state, total) {
        state.taxesTotal = total
    },
    SET_ITEM(state, tax) {
        state.tax = tax
    },
    SET_IS_LOADING(state, isLoading) {
        state.isLoading = isLoading
    }
  }

export const actions = {
    createTax({ commit, dispatch}, tax) {

        return TaxService.postTax(tax).then(response => {
            commit('ADD', tax)
            const notification = {
                type: 'success',
                message: 'New tax has been created!'
            }
            dispatch('notification/add', notification, {root: true})

            return response.data.data
        }).catch(error => {
            const notification = {
                type: 'error',
                message: error.response.data.message
            }
            dispatch('notification/add', notification, {root: true})

            const errors = error.response.data.errors
            Object.keys(errors).forEach(key => {
                dispatch('error/add', {key, message: errors[key]}, {root: true})
            });

            throw error
        })
    },
    updateTax({ commit, dispatch}, data) {

        return TaxService.updateTax(data).then(response => {
            commit('UPDATE', response.data.data)

            const notification = {
                type: 'success',
                message: 'Tax rate has been updated!'
            }
            dispatch('notification/add', notification, {root: true})


        }).catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem updating the tax rate: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})

            const errors = error.response.data.errors
            Object.keys(errors).forEach(key => {
                dispatch('error/add', {key, message: errors[key]}, {root: true})
            });

            throw error
        })
    },
    deleteTax({ commit, dispatch}, id) {

      const tax = getters.getTaxById(id)
      const taxIndex = state.taxes.indexOf(tax);

        return TaxService.deleteTax(id).then(() => {
            commit('DELETE', taxIndex)

            const notification = {
                type: 'success',
                message: 'Tax has been deleted!'
            }
            dispatch('notification/add', notification, {root: true})
        }).catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem deleting the tax: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})
            throw error
        })
    },
    fetchTaxes({commit, dispatch}, filters) {
      TaxService.getTaxes(filters)
      .then(response => {
        commit('SET_LIST', response.data.data)
        commit('SET_META', response.data.meta)
        if(response.data.meta !== undefined) {
            commit('SET_LIST_TOTAL', response.data.meta.total)
        }
        commit('SET_IS_LOADING', false)
      })
      .catch(error => {
        const notification = {
            type: 'error',
            message: 'There was a problem getting the taxes: ' + error.message
        }
        dispatch('notification/add', notification, {root: true})
      })
    },
    fetchTax({commit, dispatch}, id) {

        TaxService.getTax(id)
        .then(response => {
          commit('SET_ITEM', response.data.data)
          commit('SET_IS_LOADING', false)
        })
        .catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem getting a tax: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})
        })

    }
  }

export const getters = {
    getTaxById: state => id => {
      return state.taxes.find(tax => tax.id == id)
    }
  }
