<template>
  <div class="notifications">
    <h1>Push Notifications</h1>
    <template v-if="!isFilterActive">
      <BaseButton text="Filter" @click="filterToggle" />
    </template>
    <div v-else class="card">
      <div class="card-divider" style="justify-content: space-between;">
        <h3 class="no-margin">Filter</h3>
        <BaseButton text="X" class="button button--close" @click="filterToggle" />
      </div>
      <div class="card-section gid-container">
        <form @submit.prevent="filterResults" class="grid-x grid-margin-x">
          <div class="cell medium-4 large-3">
            <BaseInput
              v-model="filter.q"
              label="Search"
              type="search"
              name="q"
             />
          </div>

          <div class="cell medium-4 large-3">
            <BaseButton type="submit" text="Filter" style="margin-top: 27px;" />
          </div>
        </form>
      </div>
    </div>
    <router-link :to="{ name: 'push-notifications-create'}">
      <BaseButton text="Add new notification" />
    </router-link>
    <p v-if="isLoading">Loading...</p>
    <template v-else >
      <div>
        <ListLoop v-for="(item) in pushNotification.pushNotifications" :key="item.id" :item="item" route="push-notifications-detail" itemName="title" >
          <template v-slot:details>
            <p>Title: <strong>{{ item.title }}</strong><br/>Send At: <strong>{{ item.send_at }}</strong></p>
            <p>
              <strong v-if="item.status == 'draft'" class="info badge badge--lozenge">Draft</strong>
              <strong v-if="item.status == 'sent'" class="success badge badge--lozenge">Sent</strong>
              <strong v-if="item.status == 'active'" class="warning badge badge--lozenge">Active</strong>
            </p>
            <p>Created: <strong>{{ item.created_at }}</strong></p>
          </template>
        </ListLoop>
      </div>
      <Pagination :page="page" :perPage="perPage" routeName="notifications" :total="totalNotifications" />

    </template>

  </div>
</template>

<script>
    import ListLoop from '@/components/ListLoop.vue'
    import Pagination from '@/components/Pagination.vue'
    import { mapState } from 'vuex'

    export default {
        data() {
          return {
            isFilterActive: false,
            filter: {
              q: '',
              perPage: 10,
              page: parseInt(this.$route.query.page) || 1
            }
          }
        },
        components: {
          ListLoop,
          Pagination
        },
        created() {
          this.$store.dispatch('pushNotification/fetchNotifications', this.filter)
        },
        computed: {
          perPage(){
            return this.filter.perPage;
          },
          page() {
            return this.filter.page;
          },
          totalNotifications() {
            return this.pushNotification.notificationsTotal
          },
          isLoading() {
            return this.pushNotification.isLoading
          },
          ...mapState(['pushNotification'])
        },
        methods : {
          filterToggle() {
            this.isFilterActive = (this.isFilterActive) ? false : true
          },
          filterResults() {
            this.$store.dispatch('pushNotification/fetchNotifications', this.filter)
          }
        }

    }
</script>
