import MessageService from "@/services/MessageService";

export const namespaced = true

export const state = {
        messages: [],
        messagesTotal: 0,
        isLoading: true,
        meta: {},
    }
export const mutations = {
    SET_MESSAGES(state, users) {
        state.messages = users
    },
    SET_META(state, meta) {
        state.meta = meta
    },
    SET_MESSAGES_TOTAL(state, total) {
        state.usersTotal = total
    },
    SET_IS_LOADING(state, isLoading) {
        state.isLoading = isLoading
    }
  }

export const actions = {
    fetchMessages({commit, dispatch}, filters) {
      MessageService.getMessages(filters)
      .then(response => {
        commit('SET_MESSAGES', response.data.data)
        commit('SET_META', response.data.meta)
        commit('SET_MESSAGES_TOTAL', response.data.meta.total)
        commit('SET_IS_LOADING', false)
      })
      .catch(error => {
        const notification = {
            type: 'error',
            message: 'There was a problem getting messages: ' + error.message
        }
        dispatch('notification/add', notification, {root: true})
      })
    },
  }

export const getters = {
    getUserById: state => id => {
      return state.users.find(user => user.id == id)
    }
  }
