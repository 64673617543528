<template>
  <div class="grid-container" style="margin-top: 30px">
    <h1>Media Library</h1>
    <div class="library-header">
      <BaseButton @click="uploading" :text="isUploadingButton"/>
      <form @submit.prevent="filter" class="library-header__filter">
        <div>
          <BaseInput
              v-model="setQuery"
              label="Search Media"
              type="search"
              name="q"
          />
        </div>
        <div>
          <BaseSelect
              :options="['images', 'files']"
              v-model="mediaType"
              label="Media Type"
          />
        </div>
        <div>
          <BaseSelect
              :options="{
              100000 : 'all',
              36 : '36',
              12 : '12',
              3: '3'
            }"
              v-model="numberPerPage"
              label="Items Per Page"
          />
        </div>

        <BaseButton type="submit" text="Filter" />
      </form>
      <MultiFileUpload v-if="isUploading"/>
    </div>

    <p>Media Items Found: <strong class="info badge badge--lozenge">{{ media.meta.total }}</strong></p>

    <div class="grid-x grid-margin-x small-up-2 medium-up-4 large-up-6 xxlarge-up-8">
      <MediaListLoop  v-for="(media) in media.media" :media="media" :media-object="media" :key="media.id" />
    </div>

    <Pagination :page="page" :perPage="perPage" :q="query" :type="type" routeName="media-library-index"
                :total="totalMedia" :lastPage="lastPage" />

  </div>
</template>

<script>
import MultiFileUpload from '@/components/MultiFileUpload.vue';
import BaseButton from '@/components/BaseButton.vue';
import Pagination from '@/components/Pagination.vue'
import MediaListLoop from '@/components/MediaListLoop.vue'
import {mapState} from "vuex";


export default {

  components: {
    MultiFileUpload,
    BaseButton,
    Pagination,
    MediaListLoop
  },
  data() {
    return {
      isUploading: false,
      isUploadingButton: 'Upload files',
      numberPerPage: null,
      setQuery: null,
      mediaType: null,
      isFiltering: false,
      isFilteringButton: 'Filter'
    }
  },
  created() {
    this.$store.dispatch('error/clear')
    this.$store.dispatch('media/fetchMedia', {
      perPage: this.perPage,
      page: this.page,
      lastPage: this.lastPage,
      q: this.query,
      type: this.type
    })
  },
  computed: {
    perPage() {
      return (this.numberPerPage) || parseInt(this.$route.query.perPage) || 12
    },
    query() {
      return (this.setQuery) || (this.$route.query.q) || ''
    },
    type() {
      return (this.mediaType) || (this.$route.query.type) || ''
    },
    page() {
      return parseInt(this.$route.query.page) || 1
    },
    lastPage() {
      return this.media.meta.last_page
    },
    totalMedia() {
      return this.media.meta.total
    },
    isLoading() {
      return this.media.isLoading
    },
    ...mapState(['media'])
  },
  methods: {
    uploading() {
      this.isUploading = (this.isUploading) ? false : true
      this.isUploadingButton = (this.isUploading) ? 'Cancel' : 'Upload files'
    },
    filtering() {
      this.isFiltering = (this.isFiltering) ? false : true
      this.isFilteringButton = (this.isFiltering) ? 'Cancel' : 'Filter'
    },
    filter() {
      this.$store.dispatch('error/clear')
      this.$store.dispatch('media/fetchMedia', {
        perPage: this.perPage,
        page: 1,
        q: this.query,
        type: this.type
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .library-header {
    display:flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    &__filter {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: flex-end;

      .button {
        margin-bottom: 1em;
      }

      > div {
        padding-right: 10px;
      }
    }
  }
</style>
