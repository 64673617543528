import OrderService from '@/services/OrderService.js'

export const namespaced = true

export const state = {
        orders: [],
        ordersTotal: 0,
        order: {},
        isLoading: true,
        meta: {},
        stats: {},
    }
export const mutations = {
    ADD(state, order) {
        state.orders.push(order)
    },
    UPDATE(state, order) {
        state.order = order
    },
    DELETE(state, index) {
        state.orders.splice(index)
    },
    SET_LIST(state, orders) {
        state.orders = orders
    },
    SET_META(state, meta) {
        state.meta = meta
    },
    SET_STATS(state, stats) {
        state.stats = stats
    },
    SET_LIST_TOTAL(state, total) {
        state.ordersTotal = total
    },
    SET_ITEM(state, order) {
        state.order = order
    },
    SET_IS_LOADING(state, isLoading) {
        state.isLoading = isLoading
    }
  }

export const actions = {
    createOrder({ commit, dispatch}, order) {

        return OrderService.postOrder(order).then(response => {
            commit('ADD', order)
            const notification = {
                type: 'success',
                message: 'New order has been created!'
            }
            dispatch('notification/add', notification, {root: true})

            return response.data.data
        }).catch(error => {
            const notification = {
                type: 'error',
                message: error.response.data.message
            }
            dispatch('notification/add', notification, {root: true})

            const errors = error.response.data.errors
            Object.keys(errors).forEach(key => {
                dispatch('error/add', {key, message: errors[key]}, {root: true})
            });

            throw error
        })
    },
    generateOrder({dispatch}, order) {

        return OrderService.generateOrder(order).then(response => {

            const notification = {
                type: 'success',
                message: 'New order has been created!'
            }
            dispatch('notification/add', notification, {root: true})

            return response.data.data
        }).catch(error => {
            const notification = {
                type: 'error',
                message: error.response.data.message
            }
            dispatch('notification/add', notification, {root: true})

            const errors = error.response.data.errors
            Object.keys(errors).forEach(key => {
                dispatch('error/add', {key, message: errors[key]}, {root: true})
            });

            throw error
        })
    },
    clearOrder({commit}) {
        commit('UPDATE', {})
    },
    updateOrder({ commit, dispatch}, order) {

        return OrderService.updateOrder(order).then(response => {
            commit('UPDATE', response.data.data)

            const notification = {
                type: 'success',
                message: 'Order  has been updated!'
            }
            dispatch('notification/add', notification, {root: true})


        }).catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem updating the order : ' + error.message
            }
            dispatch('notification/add', notification, {root: true})

            const errors = error.response.data.errors
            Object.keys(errors).forEach(key => {
                dispatch('error/add', {key, message: errors[key]}, {root: true})
            });

            throw error
        })
    },
    deleteOrder({ commit, dispatch}, id) {

      const order = getters.getOrderById(id)
      const orderIndex = state.orders.indexOf(order);

        return OrderService.deleteOrder(id).then(() => {
            commit('DELETE', orderIndex)

            const notification = {
                type: 'success',
                message: 'Order  has been deleted!'
            }
            dispatch('notification/add', notification, {root: true})
        }).catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem deleting the order : ' + error.message
            }
            dispatch('notification/add', notification, {root: true})
            throw error
        })
    },
    exportOrders({commit, dispatch}, filters) {

        return OrderService.exportOrders(filters)
            .then(response => {
                commit('SET_IS_LOADING', false)

                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                var fileLink = document.createElement('a');

                fileLink.href = fileURL;
                fileLink.setAttribute('download', 'TBUOrderExport.csv');
                document.body.appendChild(fileLink);

                fileLink.click();

        })
        .catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem exporting orders: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})
            throw error
        })
    },
    fetchOrders({commit, dispatch}, filters) {
      OrderService.getOrders(filters)
      .then(response => {
        commit('SET_LIST', response.data.data)
        commit('SET_META', response.data.meta)
        commit('SET_STATS', response.data.stats)
        if(response.data.meta !== undefined) {
            commit('SET_LIST_TOTAL', response.data.meta.total)
        }
        commit('SET_IS_LOADING', false)
      })
      .catch(error => {
        const notification = {
            type: 'error',
            message: 'There was a problem getting the orders: ' + error.message
        }
        dispatch('notification/add', notification, {root: true})
      })
    },
    fetchOrder({commit, dispatch}, id) {

        OrderService.getOrder(id)
        .then(response => {
          commit('SET_ITEM', response.data.data)
          commit('SET_IS_LOADING', false)
        })
        .catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem getting a order : ' + error.message
            }
            dispatch('notification/add', notification, {root: true})
        })

    }
  }

export const getters = {
    getOrderById: state => id => {
      return state.orders.find(order => order.id == id)
    }
  }
