import ProductCategoryService from '@/services/ProductCategoryService.js'

export const namespaced = true

export const state = {
        productCategories: [],
        productCategoriesTotal: 0,
        productCategory: {
        },
        template: {
            parents: []
        },
        isLoading: true,
        meta: {},
    }
export const mutations = {
    NEW(state, template) {
        state.template = template
    },
    ADD(state, productCategory) {
        state.productCategories.push(productCategory)
    },
    UPDATE(state, productCategory) {
        state.productCategory = productCategory
    },
    DELETE(state, index) {
        state.productCategories.splice(index)
    },
    SET_LIST(state, productCategories) {
        state.productCategories = productCategories
    },
    SET_META(state, meta) {
        state.meta = meta
    },
    SET_LIST_TOTAL(state, total) {
        state.productCategoriesTotal = total
    },
    SET_ITEM(state, productCategory) {
        state.productCategory = productCategory
    },
    SET_IS_LOADING(state, isLoading) {
        state.isLoading = isLoading
    }
  }

export const actions = {
    newProductCategory({ commit, dispatch}) {

        return ProductCategoryService.newProductCategory().then(response => {
            commit('NEW', response.data.data)
        }).catch(error => {
            const notification = {
                type: 'error',
                message: error.response.data.message
            }
            dispatch('notification/add', notification, {root: true})

            const errors = error.response.data.errors
            Object.keys(errors).forEach(key => {
                dispatch('error/add', {key, message: errors[key]}, {root: true})
            });

            throw error
        })
    },
    createProductCategory({ commit, dispatch}, productCategory) {

        return ProductCategoryService.postProductCategory(productCategory).then(response => {
            commit('ADD', productCategory)
            const notification = {
                type: 'success',
                message: 'New product has been created!'
            }
            dispatch('notification/add', notification, {root: true})

            return response.data.data
        }).catch(error => {
            const notification = {
                type: 'error',
                message: error.response.data.message
            }
            dispatch('notification/add', notification, {root: true})

            const errors = error.response.data.errors
            Object.keys(errors).forEach(key => {
                dispatch('error/add', {key, message: errors[key]}, {root: true})
            });

            throw error
        })
    },
    updateProductCategory({ commit, dispatch}, productCategory) {

        return ProductCategoryService.updateProductCategory(productCategory).then(response => {
            commit('UPDATE', response.data.data)

            const notification = {
                type: 'success',
                message: 'Product tag has been updated!'
            }
            dispatch('notification/add', notification, {root: true})


        }).catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem updating the product tag: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})

            const errors = error.response.data.errors
            Object.keys(errors).forEach(key => {
                dispatch('error/add', {key, message: errors[key]}, {root: true})
            });

            throw error
        })
    },
    deleteProductCategory({ commit, dispatch}, id) {

      const productCategory = getters.getProductCategoryById(id)
      const productCategoryIndex = state.productCategories.indexOf(productCategory);

        return ProductCategoryService.deleteProductCategory(id).then(() => {
            commit('DELETE', productCategoryIndex)

            const notification = {
                type: 'success',
                message: 'Product tag has been deleted!'
            }
            dispatch('notification/add', notification, {root: true})
        }).catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem deleting the product tag: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})
            throw error
        })
    },
    fetchProductCategories({commit, dispatch}, filters) {
      ProductCategoryService.getProductCategories(filters)
      .then(response => {
        commit('SET_LIST', response.data.data)
        commit('SET_META', response.data.meta)
        if(response.data.meta !== undefined) {
            commit('SET_LIST_TOTAL', response.data.meta.total)
        }
        commit('SET_IS_LOADING', false)
      })
      .catch(error => {
        const notification = {
            type: 'error',
            message: 'There was a problem getting the product tags: ' + error.message
        }
        dispatch('notification/add', notification, {root: true})
      })
    },
    fetchProductCategory({commit, dispatch}, id) {

        ProductCategoryService.getProductCategory(id)
        .then(response => {
          commit('SET_ITEM', response.data.data)
          commit('SET_IS_LOADING', false)
        })
        .catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem getting a product tag: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})
        })

    }
  }

export const getters = {
    getProductCategoryById: state => id => {
      return state.productCategories.find(productCategory => productCategory.id == id)
    }
  }
