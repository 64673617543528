import ContentService from '@/services/ContentService.js'

export const namespaced = true

const blankContent = {
            categories: {
                assigned: [],
                available: [],
                combined: []
            },
            tags: {
                assigned: [],
                available: []
            },
            blocks: [],
            types: []

        }

export const state = {
        fullList: [],
        contentList: [],
        contentListTotal: 0,
        content: blankContent,
        isLoading: true,
        templates: [],
        template: {
            mediaTypes: [],
            contentTypes: [],
        },
        views: [],
        view: [],
        meta: {},
    }

export const mutations = {
    NEW(state, template) {
        state.template = template
    },
    ADD(state, content) {
        state.contentList.push(content)
    },
    UPDATE(state, content) {
        state.content = content
    },
    DELETE(state, index) {
        state.contentList.splice(index,1)
    },
    SET_FULL_LIST(state, fullList) {
        state.fullList = fullList
    },
    SET_LIST(state, contentList) {
        state.contentList = contentList
    },
    SET_META(state, meta) {
        state.meta = meta
    },
    SET_LIST_TOTAL(state, total) {
        state.contentListTotal = total
    },
    RESET_ITEM(state) {
        state.content = blankContent
        state.templates = []
    },
    SET_ITEM(state, content) {
        state.content = content

        // JSON.parse(JSON.stringify( - Used for deep copy of object
        const types = JSON.parse(JSON.stringify(state.content.types))
        const blocks = JSON.parse(JSON.stringify(state.content.blocks))
        const typesKeys = Object.keys(types)
        const templates = []

        state.content.blocks.forEach((blockData) => {

            const templateType = typesKeys.filter(type => {
                return types[type].id === blockData.type
            })

            const duplicateType = JSON.parse(JSON.stringify(types[templateType]))

            duplicateType.structure.forEach((fieldData, fieldKey) => {
                if (fieldData.fields !== undefined) {

                    let numberOfValues = 0;

                    fieldData.fields[0].forEach((subFieldData) => {

                        if (blockData.fields[subFieldData.name] !== undefined && blockData.fields[subFieldData.name].length > numberOfValues) {
                            numberOfValues = (blockData.fields[subFieldData.name] !== undefined) ? blockData.fields[subFieldData.name].length : 0
                        }
                    })

                    //Target first key as this is orignal structure we want to duplicate start loop at one because we already have the first one set!
                    const duplicateFields = fieldData.fields[0]
                    for (let i = 1; i < numberOfValues; i++) {
                        duplicateType.structure[fieldKey].fields.push(duplicateFields)
                    }
                }
            })

            templates.push(duplicateType)

        })

        // Goes thorugh templates and blocks incase new fields have been added to the content type and need to have a default value added
        templates.forEach((templateData, templateKey) => {
            const fields = Object.keys(blocks[templateKey].fields)
            templateData.structure.forEach((structureData) => {

                if (structureData.fields) {

                    structureData.fields[0].forEach((subField) => {
                        if (!fields.includes(subField.name)) {
                            for (let i = 0; i < structureData.fields.length; i++) {
                                if (blocks[templateKey].fields[subField.name] === undefined) {
                                    blocks[templateKey].fields[subField.name] = [{value: ''}]
                                } else {
                                    blocks[templateKey].fields[subField.name].push({value: ''})
                                }
                            }
                        }
                    })

                } else if (!fields.includes(structureData.name)) {
                    blocks[templateKey].fields[structureData.name] = [{value: ''}];
                }
            })
        })

        state.content.blocks = blocks
        state.templates = templates

    },
    SET_VIEWS(state, views) {
      state.views = views
    },
    SET_VIEW(state, view) {
        state.view = view
    },
    SET_IS_LOADING(state, isLoading) {
        state.isLoading = isLoading
    },
    ADD_FIELD(state, data) {
        state.templates[data.key].structure[data.repeaterKey].fields.push(data.field)

        data.field.forEach(fieldData => {
            const value = (state.templates[data.key].structure[data.repeaterKey].default !== undefined) ? state.templates[data.key].structure[data.repeaterKey].default[state.content.blocks[data.key].fields[fieldData.name].length-1] : ''
            state.content.blocks[data.key].fields[fieldData.name].push({value: value})
        })
    },
    ADD_TYPE(state, id) {
        const types = JSON.parse(JSON.stringify(state.content.types))
        const typesKeys = Object.keys(types)

        const templateType = typesKeys.filter(type => {
            return types[type].id == id
        })

        state.templates.push(types[templateType])

        const data = { type: id, content: state.content.id, fields:{} }

        types[templateType].structure.forEach(field => {
            if (field.type === 'repeater') {
                //Target the first array entry as this is the one we want to use
                field.fields[0].forEach(subField => {
                    const values = []

                    if (subField.default !== undefined) {
                        for (let i = 0; i < subField.default.length; i++) {
                            const value = subField.default !== undefined ? subField.default[i] : ''
                            values.push({value: value})
                        }
                    } else {
                        values.push({value: ''})
                    }

                    data.fields[subField.name] = values
                })
            } else {
                if (field.default !== undefined) {
                    const value = field.default !== undefined ? field.default[0] : ''
                    data.fields[field.name] = [{value: value}]
                } else {
                    data.fields[field.name] = [{value: ''}]
                }
            }
        })
        state.content.blocks.push(data)
    },
    DUPLICATE_BLOCK(state, key) {

        const newBlock = JSON.parse(JSON.stringify(state.content.blocks[key]))
        const types = JSON.parse(JSON.stringify(state.content.types))
        const typesKeys = Object.keys(types)

        const templateType = typesKeys.filter(type => {
            return types[type].id == newBlock.type
        })

        state.templates.push(types[templateType])
        state.content.blocks.push(newBlock)

    },
    UPDATE_BLOCK(state, data) {
        state.content.blocks[data.key] = data.data
    },
    DELETE_BLOCK(state, templateKey) {
        state.content.blocks.splice(templateKey,1)
        state.templates.splice(templateKey,1)
    },
    DELETE_SUBBLOCK(state, data) {
        const template = state.templates[data.templateKey].structure[data.fieldKey].fields[data.subFieldKey]
        template.forEach(field => {
            state.content.blocks[data.templateKey].fields[field.name].splice(data.subFieldKey,1)
        })
        state.templates[data.templateKey].structure[data.fieldKey].fields.splice(data.subFieldKey,1)
    },
    ORDER_BLOCK(state, data) {
        state.templates.splice(data.newKey, 0, state.templates.splice(data.key, 1)[0])
        state.content.blocks.splice(data.newKey, 0, state.content.blocks.splice(data.key, 1)[0])
    },
    ORDER_SUBBLOCK(state, data) {
        const template = state.templates[data.templateKey].structure[data.fieldKey].fields[data.subFieldKey]
        template.forEach(field => {
            state.content.blocks[data.templateKey].fields[field.name].splice(data.newKey, 0, state.content.blocks[data.templateKey].fields[field.name].splice(data.key, 1)[0])
        })
    }
}

export const actions = {
    newContent({ commit, dispatch}) {

        return ContentService.newContent().then(response => {
            commit('NEW', response.data.data)
        }).catch(error => {
            const notification = {
                type: 'error',
                message: error.response.data.message
            }
            dispatch('notification/add', notification, {root: true})

            const errors = error.response.data.errors
            Object.keys(errors).forEach(key => {
                dispatch('error/add', {key, message: errors[key]}, {root: true})
            });

            throw error
        })
    },
    createContent({ commit, dispatch}, content) {

        return ContentService.postContent(content).then(response => {
            commit('ADD', content)
            const notification = {
                type: 'success',
                message: 'New content has been created!'
            }
            dispatch('notification/add', notification, {root: true})

            return response.data.data
        }).catch(error => {
            const notification = {
                type: 'error',
                message: error.response.data.message
            }
            dispatch('notification/add', notification, {root: true})

            const errors = error.response.data.errors
            Object.keys(errors).forEach(key => {
                dispatch('error/add', {key, message: errors[key]}, {root: true})
            });

            throw error
        })
    },
    updateContent({ commit, dispatch}, content) {

        return ContentService.updateContent(content).then(response => {
            commit('UPDATE', response.data.data)

            const notification = {
                type: 'success',
                message: 'Content has been updated!'
            }
            dispatch('notification/add', notification, {root: true})


        }).catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem updating the content: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})

            const errors = error.response.data.errors
            Object.keys(errors).forEach(key => {
                dispatch('error/add', {key, message: errors[key]}, {root: true})
            });

            throw error
        })
    },
    deleteContent({ commit, dispatch}, id) {

      const content = getters.getContentById(id)
      const contentIndex = state.contentList.indexOf(content);

        return ContentService.deleteContent(id).then(() => {
            commit('DELETE', contentIndex)

            const notification = {
                type: 'success',
                message: 'Content has been delete!'
            }
            dispatch('notification/add', notification, {root: true})
        }).catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem deleting the content: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})
            throw error
        })
    },
    duplicateContent({ commit, dispatch}, {filter, id}) {

        return ContentService.duplicateContent(filter, id).then(response => {
            commit('SET_LIST', response.data.data)
            if(response.data.meta !== undefined) {
                commit('SET_LIST_TOTAL', response.data.meta.total)
            }
            commit('SET_IS_LOADING', false)

            const notification = {
                type: 'success',
                message: 'Content has been copied!'
            }
            dispatch('notification/add', notification, {root: true})
        }).catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem duplicating the content: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})
            throw error
        })
    },

    fetchFullContentList({commit, dispatch}) {
        commit('RESET_ITEM')

        if (state.fullList.length == 0) {
            ContentService.getFullContentList()
            .then(response => {
                commit('SET_FULL_LIST', response.data.data)
                commit('SET_IS_LOADING', false)
            })
            .catch(error => {
                const notification = {
                    type: 'error',
                    message: 'There was a problem getting the full content list: ' + error.message
                }
                dispatch('notification/add', notification, {root: true})
            })
        } else {
            console.log('Dont need to recall');
        }
    },
    fetchContentList({commit, dispatch}, filters) {
        commit('RESET_ITEM')

        ContentService.getContentList(filters)
        .then(response => {
            commit('SET_LIST', response.data.data)
            commit('SET_META', response.data.meta)
            if(response.data.meta !== undefined) {
                commit('SET_LIST_TOTAL', response.data.meta.total)
            }
            commit('SET_IS_LOADING', false)
        })
        .catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem getting the content list: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})
        })
    },
    fetchContent({commit, dispatch}, id) {
        commit('SET_IS_LOADING', true)

        ContentService.getContent(id)
        .then(response => {
          commit('SET_ITEM', response.data.data)
          commit('SET_IS_LOADING', false)
        })
        .catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem getting this content: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})
        })
    },
    exportContent({commit, dispatch}, filters) {

        return ContentService.exportContent(filters)
            .then(response => {
                commit('SET_IS_LOADING', false)

                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                var fileLink = document.createElement('a');

                fileLink.href = fileURL;
                fileLink.setAttribute('download', 'TBContentExport.csv');
                document.body.appendChild(fileLink);

                fileLink.click();

        })
        .catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem exporting content: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})
            throw error
        })
    },
    exportContentViews({commit, dispatch}, filters) {

        return ContentService.exportContentViews(filters)
            .then(response => {
                commit('SET_IS_LOADING', false)

                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                var fileLink = document.createElement('a');

                fileLink.href = fileURL;
                fileLink.setAttribute('download', 'TBContentViewsExport.csv');
                document.body.appendChild(fileLink);

                fileLink.click();

        })
        .catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem exporting content: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})
            throw error
        })
    },
    duplicateField({commit}, data) {
        commit('ADD_FIELD', data)
    },
    duplicateType({commit, dispatch}, id) {
        commit('ADD_TYPE', id)

        const newKey = (state.content.blocks.length-1)
        const data = {key: newKey, data: state.content.blocks[newKey]}
        dispatch('saveBlock', data)
    },
    duplicateBlock({commit, dispatch}, data) {

        const newKey = state.content.blocks.length

        commit('DUPLICATE_BLOCK', data.templateKey)

        const newBlock = JSON.parse(JSON.stringify(state.content.blocks[data.templateKey]))
        const newData = {key: newKey, data: newBlock}
        dispatch('saveBlock', newData)
    },
    saveBlock({ commit, dispatch}, data) {

        const blockData = {type: data.data.type, content: state.content.id, fields: data.data.fields, model: 'Content'}

        return ContentService.postBlock(blockData).then(response => {

            commit('UPDATE_BLOCK', {key: data.key, data: { id: response.data.data.id, content: state.content.id, type: response.data.data.type, fields: response.data.data.fields}})

            const notification = {
                type: 'success',
                message: 'Saved'
            }
            dispatch('notification/add', notification, {root: true})


        }).catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem saving the block: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})

            const errors = error.response.data.errors
            Object.keys(errors).forEach(key => {
                dispatch('error/add', {key, message: errors[key]}, {root: true})
            });

            throw error
        })
    },
    updateBlock({ commit, dispatch}, data) {

        const blockData = {type: data.data.type, content: state.content.id, fields: data.data.fields}

        return ContentService.updateBlock(data.data.id, blockData).then(response => {
            commit('UPDATE_BLOCK', {key: data.key, data: { id: data.data.id, content: state.content.id, type: data.data.type, fields: response.data.data.fields}})

            const notification = {
                type: 'success',
                message: 'Saved'
            }
            dispatch('notification/add', notification, {root: true})


        }).catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem saving the block: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})

            const errors = error.response.data.errors
            Object.keys(errors).forEach(key => {
                dispatch('error/add', {key, message: errors[key]}, {root: true})
            });

            throw error
        })
    },
    deleteBlock({ commit, dispatch }, data) {

        const block = state.content.blocks[data.templateKey]

        if (block.id > 0) {
            return ContentService.deleteBlock({ id: block.id, contentID: state.content.id}).then(response => {

                commit('DELETE_BLOCK', data.templateKey)
                const notification = {
                    type: 'success',
                    message: response.data.message
                }
                dispatch('notification/add', notification, {root: true})


            }).catch(error => {
                const notification = {
                    type: 'error',
                    message: 'There was a problem saving the block: ' + error.message
                }
                dispatch('notification/add', notification, {root: true})

                const errors = error.response.data.errors
                Object.keys(errors).forEach(key => {
                    dispatch('error/add', {key, message: errors[key]}, {root: true})
                });

                throw error
            })
        } else {
            commit('DELETE_BLOCK', data.templateKey)
        }
    },
    orderBlock({commit, dispatch}, data) {
        let newKey = ''
        if (data.direction !== undefined) {
            if (data.direction === 'up') {
                newKey = (data.key-1)
            }
            if (data.direction === 'down') {
                newKey = (data.key+1)
            }
        }
        if (data.newKey !== undefined) {
            newKey = data.newKey
        }

        const templates = JSON.parse(JSON.stringify(state.templates))
        const blockKeyOrder = Object.keys(templates)
        const newBlockOrder = []

        blockKeyOrder.forEach(templateKey => {
            newBlockOrder.push(state.content.blocks[templateKey].id)
        })

        newBlockOrder.splice(newKey, 0, newBlockOrder.splice(data.key, 1)[0])

        return ContentService.orderBlock({ content: state.content.id, order: newBlockOrder }).then(response => {
            commit('ORDER_BLOCK', {key: data.key, newKey: newKey})

            const notification = {
                type: 'success',
                message: response.message
            }
            dispatch('notification/add', notification, {root: true})


        }).catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem changing the order of the block: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})

            const errors = error.response.data.errors
            Object.keys(errors).forEach(key => {
                dispatch('error/add', {key, message: errors[key]}, {root: true})
            });

            throw error
        })

    },
    deleteSubBlock({commit, dispatch}, data) {

        const template = state.templates[data.templateKey].structure[data.fieldKey].fields[data.subFieldKey]
        const block = JSON.parse(JSON.stringify(state.content.blocks[data.templateKey]))

        template.forEach(field => {
            block.fields[field.name].splice(data.subFieldKey,1)
        })

        dispatch('updateBlock', {key: data.templateKey, data: block})

        commit('DELETE_SUBBLOCK', data)

    },
    orderSubBlock({commit, dispatch}, data) {

        let newKey = ''
        if (data.direction === 'up') {
            newKey = (data.subFieldKey-1)
        }
        if (data.direction === 'down') {
            newKey = (data.subFieldKey+1)
        }

        const template = state.templates[data.templateKey].structure[data.fieldKey].fields[data.subFieldKey]
        const block = JSON.parse(JSON.stringify(state.content.blocks[data.templateKey]))
        template.forEach(field => {
            block.fields[field.name].splice(newKey, 0, block.fields[field.name].splice(data.subFieldKey, 1)[0])
        })

        dispatch('updateBlock', {key: data.templateKey, data: block})

        commit('ORDER_SUBBLOCK', {key: data.subFieldKey, newKey: newKey, data: data})

    },
    fetchContentViews({commit, dispatch}, filters) {
        ContentService.getViews(filters)
            .then(response => {
                commit('SET_VIEWS', response.data)
                commit('SET_META', response.data.meta)
                commit('SET_IS_LOADING', false)
            })
            .catch(error => {
                const notification = {
                    type: 'error',
                    message: 'There was a problem getting content views: ' + error.message
                }
                dispatch('notification/add', notification, {root: true})
            })
    },
    fetchContentView({commit, dispatch}, {id}) {
        ContentService.getView(id)
            .then(response => {
                commit('SET_VIEW', response.data)
                commit('SET_IS_LOADING', false)
            })
            .catch(error => {
                const notification = {
                    type: 'error',
                    message: 'There was a problem getting the content users: ' + error.message
                }
                dispatch('notification/add', notification, {root: true})
            })
    },

    updateContentOrder({ dispatch }, order) {

        return ContentService.updateContentOrder(order).then(() => {

                const notification = {
                    type: 'success',
                    message: 'Saved!'
                }
                dispatch('notification/add', notification, {root: true})


            }).catch(error => {
                const notification = {
                    type: 'error',
                    message: 'There was a problem updating the content order: ' + error.message
                }
                dispatch('notification/add', notification, {root: true})

                const errors = error.response.data.errors
                Object.keys(errors).forEach(key => {
                    dispatch('error/add', {key, message: errors[key]}, {root: true})
                });

                throw error
            })
    },
  }

export const getters = {
    getContentById: state => id => {
      return state.contentList.find(content => content.id == id)
    },
    getTypeByName: state => name => {
      return state.types.find(type => type.name == name)
    },
    getContentAvaCategoryById : state => id => {
      return state.content.categories.available.find(content => content.id == id)
    }
  }
