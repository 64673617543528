<template>
  <div class="content">
    <h1>Content</h1>
    <template v-if="!isFilterActive">
      <BaseButton text="Filter" @click="filterToggle" />
    </template>
    <div v-else class="card">
      <div class="card-divider" style="justify-content: space-between;">
        <h3 class="no-margin">Filter</h3>
        <BaseButton text="X" class="button button--close" @click="filterToggle" />
      </div>
      <div class="card-section gid-container">
        <form @submit.prevent="filterResults" class="grid-x grid-margin-x">
          <div class="cell medium-4 large-3">
            <BaseInput
              v-model="filter.q"
              label="Search"
              type="search"
              name="q"
             />
          </div>
          <div class="cell medium-4 large-3">
            <BaseSelect
              :options="content.meta.filters.status || []"
              v-model="filter.status"
              label="Select a status"
              optionValue="id"
              optionName="name"
             />
          </div>
          <div class="cell medium-4 large-3">
            <BaseSelect
              :options="content.meta.filters.category || []"
              v-model="filter.category"
              label="Filter by module"
              optionValue="id"
              optionName="title"
             />
           </div>
          <div class="cell medium-4 large-3">
            <BaseSelect
              :options="content.meta.filters.type || []"
              v-model="filter.content_type"
              label="Filter by type"
              optionValue="id"
              optionName="name"
             />
           </div>
          <div class="cell medium-4 large-3">
            <BaseButton type="submit" text="Filter" style="margin-top: 27px;" />
          </div>
        </form>
      </div>
    </div>
    <router-link :to="{ name: 'content-create'}">
      <BaseButton text="Add new content" />
    </router-link>


    <p v-if="isLoading">Loading...</p>
    <template v-else >
      <div>
        <draggable class="dragArea list-group w-full" :list="content.contentList" @change="log">
          <ListLoop v-for="(item) in content.contentList" :key="item.id" :item="item" route="content-detail" itemName="title" duplicateShow :duplicate="duplicateContent" draggable type updated >
            <template v-slot:details>
              <div>
                <p><strong>{{ item.title }}</strong></p>
                <p>Slug: <strong>{{ item.slug }}</strong> <BaseButton @click.prevent="viewOnSite(item.slug)" text="view on site" class="button--small" /></p>

                <p style="margin-top: 5px;">
                  <strong class="info badge badge--lozenge">{{ item.media_type.title }}</strong>
                  <strong class="info badge badge--lozenge">{{ item.type.title }}</strong>
                  <strong v-if="item.is_premium" class="warning badge badge--lozenge">Protected</strong>
                  <strong v-if="item.status" class="success badge badge--lozenge">Live</strong>
                  <strong v-if="!item.status" class="error badge badge--lozenge">Inactive</strong>
                </p>
                <p v-if="item.publish_at"  style="margin-top: 5px;"><strong>Publish at: </strong><strong class="badge badge--lozenge success">{{ item.publish_at }}</strong></p>
                <p>Last Updated: <strong>{{ item.updated_at }}</strong></p>
              </div>
            </template>
          </ListLoop>
        </draggable>
      </div>

      <Pagination :page="page" :perPage="perPage" :filter="filter" routeName="content" :total="totalContentList" />
    </template>

  </div>
</template>

<script>
    import ListLoop from '@/components/ListLoop.vue'
    import Pagination from '@/components/Pagination.vue'
    import { VueDraggableNext } from 'vue-draggable-next'
    import { mapState } from 'vuex'

    export default {
        data() {
          return {
            isFilterActive: false,
            exporting: false,
            filter: {
              q: this.$route.query.q || '',
              perPage: 10,
              page: parseInt(this.$route.query.page) || 1,
              category: parseInt(this.$route.query.category) || 'all',
              tags: parseInt(this.$route.query.tags) || 'all',
              status: this.$route.query.status || 'all',
              allow_feature: this.$route.query.allow_feature || 'all',
              is_premium: this.$route.query.is_premium || 'all',
              is_demo: this.$route.query.is_demo || 'all',
              media: this.$route.query.media || 'all',
              type: this.$route.query.type || 'all',
            },
            enabled: true,
            dragging: false
          }
        },
        components: {
          ListLoop,
          Pagination,
          draggable: VueDraggableNext,
        },
        created() {
          this.$store.dispatch('content/fetchContentList', this.filter)
        },
        computed: {
          perPage(){
            return this.filter.perPage;
          },
          page() {
            return this.filter.page;
          },
          totalContentList() {
            return this.content.contentListTotal
          },
          isLoading() {
            return this.content.isLoading
          },
          ...mapState(['content'])
        },
        methods : {
          filterToggle() {
            this.isFilterActive = (this.isFilterActive) ? false : true
          },
          filterResults() {
            console.log(this.q);
            this.$store.dispatch('content/fetchContentList', this.filter)
          },
          duplicateContent(id) {
            this.$store.dispatch('content/duplicateContent', {
              filter: this.filter,
              id: id
            })
          },
          viewOnSite(slug) {
            var win = window.open(process.env.VUE_APP_SITE_URL + '' + slug, '_blank');
            win.focus();
          },
          exportCSV() {
            this.exporting = true;
            this.$store.dispatch('content/exportContent', this.filter).then(() => {
              this.exporting = false;
            })
          },
          log(data) {
            let order = [];

            data = (data.moved) ? this.content.contentList : data

            Object.keys(data).forEach(key => {
              order.push(data[key].id);
            })

            this.$store.dispatch('error/clear')
            this.$store.dispatch('content/updateContentOrder', { category: this.filter.category, order: order})
          },
        }

    }
</script>
