<template>
  <div class="products">
    <div>
      <p v-if="isLoading">Loading...</p>
      <template v-else >
          <div class="grid-container" >
            <h1>{{ product.title }}</h1>
              <div class="cell grid-x grid-margin-x">
                <div class="panel cell medium-4">
                  <h3>Product Details</h3>
                  <button class="button" @click="edit">Edit</button>
                  <template v-if="!isEditing">
                    <p><strong>Title: </strong> {{ product.title }}</p>
                    <p><strong>Slug: </strong>{{ product.slug || 'N/A' }}</p>
                    <p><strong>Introduction: </strong>{{ product.sku }}</p>
                    <p><strong>Type: </strong>{{ product.typeLabel }}</p>
                    <p>
                      <strong>Status: </strong>
                      <strong v-if="product.status" class="success badge badge--lozenge">Live</strong>
                      <strong v-if="!product.status" class="error badge badge--lozenge">Inactive</strong>
                    </p>
                    <p><strong>Created at: </strong>{{ product.created_at }}</p>

                  </template>
                  <template v-else>
                    <form @submit.prevent="update">

                      <BaseInput
                        v-model="product.title"
                        label="Title"
                        type="text"
                        name="title"
                       />
                      <BaseInput
                        v-model="product.slug"
                        label="Slug"
                        type="text"
                        name="slug"
                       />
                      <BaseTextarea
                        v-model="product.sku"
                        label="Introduction"
                        type="text"
                        name="intro"
                       />
                      <BaseRadioGroup
                          v-model="product.type"
                          :options="product.types"
                          optionValue="id"
                          optionName="title"
                          name="type"
                          label="Type"
                          lozenge
                       />
                      <BaseSwitch
                        v-model="product.status"
                        :options="product.statuses"
                        optionName="status"
                        label="Status"
                       />


                      <BaseButton type="submit" text="Update" />
                    </form>
                  </template>

                </div>
                <div class="panel cell medium-4">
                  <h3>Image</h3>
                    <p>
                      <strong>Featured Image: </strong>
                      <MediaReveal
                          v-model="product.media"
                          :uniqueKey="'product_' + product.id"
                          :save="update"
                      />
                    </p>
                </div>
                <div class="panel cell medium-4">
                  <h3>Colours</h3>
                    <BaseInput
                        v-model="product.colour_primary"
                        label="Background Colour"
                        name="colour_primary"
                        type="color"
                        @change="update"
                    />
                    <BaseInput
                        v-model="product.colour_secondary"
                        label="Arrow Colour"
                        name="colour_secondary"
                        type="color"
                        @change="update"
                    />

                </div>
              </div>

            <div class="cell grid-x grid-margin-x">
              <div class="panel cell medium-6">
                <h3>Product Categories</h3>
                <template v-if="categoriesAssigned.length">
                  <span v-for="(item) in categoriesAssigned" :key="item.id" :item="item" class="tag" >
                    {{ item.listTitle }}
                    <BaseButton class="button button--remove" text="Remove" @click.prevent="removeCategory(item.id)" />
                  </span>
                </template>
                <p v-else>No categories have been assigned yet</p>

                  <form v-if="categoriesAvailable.length" class="form-min">
                    <BaseAutoComplete
                      v-model="product.addCategory"
                      :options="categoriesAvailable"
                      subTitle="listTitle"
                      placeholder="Search to assign existing category..."
                     />
                    <BaseButton v-if="!product.addCategory" text="Save" disabled />
                    <BaseButton v-else text="Save" @click.prevent="update"  />
                  </form>

                  <BaseButton v-if="!addNewCategory" @click.prevent="toggleAddNewCategory" text="Add new category" class="button--outline button--bottom-right"  />
                  <form  v-else @submit.prevent="update" class="form-min">
                    <h3>Add New Category</h3>
                    <BaseInput
                      v-model="product.newCategory"
                      label="New Category Title"
                     />
                    <BaseSelect
                      v-model="product.addCategoryParent"
                      :options="categoriesParents"
                      optionValue="id"
                      optionName="listTitle"
                      label="Parent Category?"
                     />
                    <BaseButton text="Add" />
                  </form>

              </div>
              <div class="panel cell medium-6">
                <h3>Product Tags</h3>
                <template v-if="tagsAssigned.length">
                  <span v-for="(item) in tagsAssigned" :key="item.id" :item="item" class="tag" >
                    {{ item.title }}
                    <BaseButton class="button button--remove" text="Remove" @click.prevent="removeTag(item.id)" />
                  </span>
                </template>
                <p v-else>No tags have been assigned yet</p>

                  <form v-if="tagsAvailable.length" @submit.prevent="update" class="form-min">
                    <BaseAutoComplete
                      v-model="product.addTag"
                      :options="tagsAvailable"
                      placeholder="Search to assign existing tag..."
                     />
                    <BaseButton v-if="!product.addTag" text="Save" disabled />
                    <BaseButton v-else text="Save" @click.prevent="update"  />
                  </form>

                  <BaseButton v-if="!addNewTag" @click.prevent="toggleAddNewTag" text="Add new tag" class="button--outline button--bottom-right" />
                  <form v-else @submit.prevent="update" class="form-min">
                    <h3>Add New Tag</h3>
                    <BaseInput
                      v-model="product.newTag"
                      label="New Tag Title"
                     />
                    <BaseButton text="Add" />
                  </form>

              </div>
              <div class="cell medium-12">
                Click to delete this product
                <Remove :onRemove="remove" />
              </div>

            </div>
          </div>
          <div class="grid-container border-top">
            <div class="grid-x grid-margin-x">
              <div class="cell medium-12">
                <div class="cell">
                  <div class="cell__header">
                    <div>
                      <h2>Options & Pricing</h2>
                      <p>All products should have at least one option. Options include permissions for content and downloads that users get access to after purchasing.</p>
                    </div>
                  </div>
                </div>
                <ProductOption class="cell" v-for="(option, optionKey) in options" :key="option.id" :optionKey="optionKey" :option="option" />

                <div class="panel">
                    <BaseButton v-if="!addNewOption" @click.prevent="toggleAddNewOption" text="Add new option" class="button--outline" />
                    <form v-else @submit.prevent="addOption" >

                      <h3>Add New Option</h3>
                      <BaseInput
                        v-model="newOption.title"
                        label="Title"
                       />
                      <BaseRadioGroup
                        v-model="newOption.type"
                        :options="optionTypes"
                        optionValue="id"
                        optionName="name"
                        name="type"
                        label="Type"
                        lozenge
                       />
                      <BaseSwitch
                        v-model="newOption.status"
                        :options="[{name: 'Inactive'},{name: 'Live'}]"
                        optionName="name"
                        label="Status"
                       />
                      <BaseInput
                        v-model="newOption.price"
                        label="Price"
                        type="number"
                        step="0.01"
                       />
                      <BaseInput
                        v-model="newOption.price_rrp"
                        label="RRP"
                        type="number"
                        step="0.01"
                       />

                      <BaseSwitch
                        v-if="newOption.type === 3"
                        v-model="newOption.shipping"
                        :options="[{name: 'No'},{name: 'Yes'}]"
                        optionName="name"
                        label="Charge Shipping?"
                       />

                      <BaseSelect
                        :options="product.taxRates"
                        v-model="newOption.tax"
                        label="Select tax rate"
                        optionValue="id"
                        optionName="title"
                       />

                      <BaseSwitch
                        v-model="newOption.monthly"
                        :options="[{name: 'No'},{name: 'Yes'}]"
                        optionName="name"
                        label="Allow monthly payments?"
                       />
                      <template v-if="newOption.monthly">
                        <BaseInput
                          v-model="newOption.monthly_price"
                          label="Monthly Price"
                          type="number"
                          step="0.01"
                         />
                        <BaseInput
                          v-model="newOption.monthly_renewal"
                          label="Months till renewal"
                          type="number"
                         />
                      </template>
                      <BaseSwitch
                        v-model="renewal"
                        :options="[{name: 'No'},{name: 'Yes'}]"
                        optionName="name"
                        label="Requires Renewal?"
                       />
                      <template v-if="renewal">
                        <BaseInput
                          v-model="newOption.renewal"
                          label="Months valid for"
                          type="number"
                         />
                      </template>
                      <BaseButton text="Add" />
                    </form>
                </div>

              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import Remove from '@/components/Remove'
    import MediaReveal from '@/components/MediaReveal.vue'
    import ProductOption from '@/components/ProductOption.vue'

    export default {

        props: ["id"],
        data() {
          return {
            isEditing: false,
            showPreview: false,
            showEditor: true,
            selectBlock: 0,
            addNewCategory: false,
            addNewTag: false,
            addNewOption: false,
            newOption: this.newOptionAttr(),
            renewal: false,
          }
        },
        components : {
          Remove,
          MediaReveal,
          ProductOption
        },
        created() {
          this.$store.dispatch('error/clear')
          this.$store.dispatch('product/fetchProduct', this.id)
        },
        computed: {
          ...mapState({
            product: state => state.product.product,
            isLoading: state => state.product.isLoading
          }),
          isDisabled() {
            return false
          },
          options() {
            return (this.product.options) ? this.product.options : []
          },
          tagsAll() {
            return (this.product.tags) ? this.product.tags.all : []
          },
          tagsAssigned() {
            return (this.product.tags) ? this.product.tags.assigned : []
          },
          tagsAvailable() {
            return (this.product.tags) ? this.product.tags.available : []
          },
          categoriesParents() {
            return (this.product.categories) ? this.product.categories.parents : []
          },
          categoriesAssigned() {
            return (this.product.categories) ? this.product.categories.assigned : []
          },
          categoriesAvailable() {
            return (this.product.categories) ? this.product.categories.available : []
          },
          optionTypes() {
            return (this.product.optionTypes) ? this.product.optionTypes : []
          }
        },
        methods: {
          remove() {
            this.$store.dispatch('product/deleteProduct', this.id)
          },
          update() {
            this.$store.dispatch('error/clear')
            this.$store.dispatch('product/updateProduct', this.product)
            this.isEditing = false
          },
          addBlock() {
            this.$store.dispatch('product/duplicateType', this.selectBlock)
          },
          edit() {
            this.isEditing = (this.isEditing) ? false : true
          },
          removeTag(tag) {
            this.product.addTag = null
            this.product.removeTag = tag
            this.update()
          },
          removeCategory(category) {
            this.product.addCategory = null
            this.product.removeCategory = category
            this.update()
          },
          toggleAddNewCategory() {
            this.addNewCategory = this.addNewCategory ? false : true
          },
          toggleAddNewTag() {
            this.addNewTag = this.addNewTag ? false : true
          },
          toggleAddNewOption() {
            this.addNewOption = this.addNewOption ? false : true
          },
          convertToSlug(slug) {
            this.product.slug = slug
                .toLowerCase()
                .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
                .replace(/\s+/g, '-') // collapse whitespace and replace by -
                .replace(/-+/g, '-'); // collapse dashes
          },
          newOptionAttr() {
            return { type: 0 }
          },
          addOption() {
            this.newOption.product_id = this.product.id
            this.$store.dispatch('error/clear')
            this.$store.dispatch('product/createOption', this.newOption).then(() => {
              this.newOption = this.newOptionAttr()
            })
          }
        }

    }
</script>
