import apiClient from './apiClient.js'


export default {
    getMedia(perPage, page, q, type) {
        return new apiClient().get('/media?page=' + page + '&perPage=' + perPage + '&q=' + q + '&type=' + type);
    },
    getSingleMedia(mediaID) {
        return new apiClient().get('/media/' + mediaID);
    },
    postSingleMedia(media, mediaID) {
        return new apiClient().post('/media/' + mediaID, media);
    },
    deleteMedia(mediaID) {
        return new apiClient().delete('/media/'+ mediaID);
    },
    authCheck(authToken) {

        if (authToken === null) {
            console.log('not logged in');
        }

    }
}
