<template>
    <input
        type="radio"
        :checked="modelValue === value"
        :value="value"
        @change="$emit('update:modelValue', $props.value)"
        v-bind="$attrs"
        :id="random"
    />
    <label v-if="label" :for="random"><span v-if="icon" v-html="icon" class="icon"></span>{{ label }}</label>
</template>

<script>
export default {
    data() {
        return {
            random: 'radio-' + Math.random().toString(36).substring(7)
        }
    },
    props: {
        label: {
            type: String,
            default: ''
        },
        modelValue: {
            type: [String, Number],
            default: ''
        },
        icon: {
            type: [String, Boolean],
            required: false
        },
        value: {
            type: [String, Number],
            required: true
        }
    }
}
</script>

<style scoped>

</style>
