import apiClient from './apiClient.js'

export default {
    getSubscriptionPlans(filters) {
        let filtersEncoded = new URLSearchParams(filters).toString();
        return new apiClient().get('/subscription-plan?' + filtersEncoded);
    },
    getSubscriptionPlan(id) {
        return new apiClient().get('/subscription-plan/'+id);
    },
    postSubscriptionPlan(plan) {
        return new apiClient().post('/subscription-plan', plan);
    },
    updateSubscriptionPlan(plan) {
        return new apiClient().patch('/subscription-plan/'+plan.id, plan);
    }
}
