<template>
  <div class="grid-container" style="margin-top: 30px">
    <BaseButton @click="goBack"  text="Back to previous page" />

    <h1>{{ media.singleMedia.file_name }}</h1>

    <div class="grid-x grid-margin-x">
      <div class="cell medium-8">

        <img v-if="['jpg', 'jpeg', 'png', 'svg'].indexOf(media.singleMedia.file_type) >= 0"
             :src=media.singleMedia.temporaryURL>
        <img v-else src="@/assets/document.png" alt="">
      </div>
      <div class="cell medium-4" style="border: 2px solid white; border-radius: 5px; padding: 10px">
        <div v-if="!isEditing">
          <p><strong>Filename:</strong> {{ media.singleMedia.file_name }}</p>
          <p v-if="media.singleMedia.caption"><strong>Caption/Button Text:</strong> {{ media.singleMedia.caption }} </p>
          <p v-if="media.singleMedia.alt_tag"><strong>Alt tag:</strong> {{ media.singleMedia.alt_tag }} </p>
          <p v-if="media.singleMedia.width"><strong>Dimensions:</strong> {{ media.singleMedia.width }} x {{ media.singleMedia.height }}</p>
          <p>
            <strong>Protected:</strong> <span v-if="media.singleMedia.protected">true</span> <span v-else>false</span>
          </p>
        </div>
        <div v-else>
          <form @submit.prevent="update">
            <BaseInput
                v-model="media.singleMedia.file_name"
                label="File Name"
                type="text"
                name="file_name"
            />

            <BaseInput
                v-model="media.singleMedia.caption"
                label="Caption/Button Text"
                type="text"
                name="caption"
            />

            <BaseInput
                v-if="['jpg', 'jpeg', 'png', 'svg'].indexOf(media.singleMedia.file_type) >= 0"
                v-model="media.singleMedia.alt_tag"
                label="Alt tag"
                type="text"
                name="alt_tag"
            />

            <BaseCheckbox
                v-model="media.singleMedia.protected"
                label="Set file to be protected"
                name="protected"
                id="protected"
            />

            <div>
              <BaseButton type="submit" text="Update" style="margin-right: 20px" />
              <BaseButton @click="cancel()" text="Cancel edit" />
            </div>

          </form>
        </div>

        <div v-if="!isEditing">
          <button class="button" @click="edit()" style="margin: 30px 20px 0 0;">Edit</button>
          <button class="button" @click="remove(media.singleMedia.id)" style="margin: 30px 0 0 0;">Delete</button>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  props: {
    mediaID : {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      isEditing: this.$route.query.openEdit || false
    }
  },
  created() {
    this.$store.dispatch('error/clear')
    this.$store.dispatch('media/fetchSingleMedia', {
      mediaID: this.mediaID
    })
  },
  computed: {
    isLoading() {
      return this.media.isLoading
    },
    ...mapState(['media'])
  },
  methods: {
    update() {
      this.$store.dispatch('media/updateMedia', {
        media: this.media.singleMedia,
        mediaID: this.mediaID
      }).then(() => {
        this.$router.go()
      })
    },
    edit() {
      this.isEditing = (this.isEditing) ? false : true
    },
    cancel() {
      this.isEditing = false
    },
    remove(mediaID) {
      this.$store.dispatch('media/deleteMedia', {
        mediaID
      }).then(() => {
        this.$router.push({
          name: 'media-library-index'
        })
      }).catch(err => {
        this.error = err
      })
    },
    goBack() {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>

</style>
