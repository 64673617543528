<template>
  <div class="orders">
    <h1>Orders</h1>
    <template v-if="!isFilterActive">
      <BaseButton text="Filter" @click="filterToggle" />
    </template>
    <div v-else class="card">
      <div class="card-divider" style="justify-content: space-between;">
        <h3 class="no-margin">Filter</h3>
        <BaseButton text="X" class="button button--close" @click="filterToggle" />
      </div>
      <div class="card-section gid-container">
        <form @submit.prevent="filterResults" class="grid-x grid-margin-x">
          <div class="cell medium-4 large-3">
            <BaseInput
              v-model="filter.q"
              label="Search"
              type="search"
              name="q"
             />
          </div>
          <div class="cell medium-4 large-3">
            <BaseSelect
              :options="order.meta.filters.status || []"
              v-model="filter.status"
              label="Select a status"
              optionValue="id"
              optionName="name"
             />
          </div>
          <div class="cell medium-4 large-3">
            <BaseSelect
              :options="order.meta.filters.shipping || []"
              v-model="filter.shipping"
              label="Shipping required"
              optionValue="id"
              optionName="name"
             />
          </div>
          <div class="cell medium-4 large-3">
            <BaseSelect
              :options="order.meta.filters.payments || []"
              v-model="filter.payments"
              label="Payments"
              optionValue="id"
              optionName="name"
             />
          </div>
          <div class="cell medium-4 large-3">
            <BaseSelect
              :options="order.meta.filters.renewal || []"
              v-model="filter.renewal"
              label="Renewal"
              optionValue="id"
              optionName="name"
             />
          </div>
          <div class="cell medium-4 large-3">
            <BaseDate
              v-model="filter.created_from"
              label="Created from"
             />
          </div>
          <div class="cell medium-4 large-3">
            <BaseDate
              v-model="filter.created_until"
              label="Created until"
             />
          </div>

          <div class="cell medium-4 large-3">
            <BaseButton type="submit" text="Filter" style="margin-top: 27px;" />
          </div>
        </form>
      </div>
    </div>

    <BaseButton text="Export CSV" class="button--outline" @click="exportCSV" :disabled="exporting" :class="{'active' : exporting}" />
    <BaseButton text="New Order" class="button--outline" @click="newOrderToggle = true" />

    <div v-if="newOrderToggle" class="card">
      <div class="card-divider" style="justify-content: space-between;">
        <h3 class="no-margin">New Order</h3>
        <BaseButton text="X" class="button button--close" @click="newOrderToggle = false" />
      </div>
      <div class="card-section gid-container">
        <form @submit.prevent="generateOrder" class="grid-x grid-margin-x">

          <div class="cell medium-4 large-3">
            <BaseSelect
              :options="productOptions || []"
              v-model="newOrder.productOption"
              label="Select a product to purchase"
              optionValue="id"
              optionName="title"
             />
          </div>
          <div class="cell medium-4 large-3">
            <BaseSelect
              :options="users || []"
              v-model="newOrder.user"
              label="Select a user"
              optionValue="id"
              optionName="display_nameemail"
             />
          </div>
          <div class="cell medium-4 large-3">
            <BaseButton type="submit" text="Generate Order" style="margin-top: 27px;" />
          </div>
        </form>
      </div>
    </div>


    <p v-if="isLoading">Loading...</p>
    <template v-else >
      <div class="grid-x grid-margin-x panel panel--row " >

        <div class="panel panel--dark cell medium-3">
          <h4 class="no-margin centre">Weekly Due Payments</h4>
          <p>Payments that are due in the next 7 days</p>
          <p class="centre"><strong class="warning badge badge--lozenge">{{ stats.paymentsDueNext7Days }}</strong><BaseButton @click="filterDuePayments" text="View Matching Orders" class="button--small" /></p>
        </div>
        <div class="panel panel--dark cell medium-3">
          <h4 class="no-margin centre">Over Due Payments</h4>
          <p>Payments due dates past and not marked as paid</p>
          <p class="centre"><strong class="error badge badge--lozenge">{{ stats.paymentsDueOver }}</strong><BaseButton @click="filterOverDuePayments" text="View Matching Orders" class="button--small" /></p>
        </div>
        <div class="panel panel--dark cell medium-3">
          <h4 class="centre">Paid Payments <strong class="success badge badge--lozenge">{{ stats.paymentsPaid }}</strong></h4>
        </div>
      </div>
      <div>
        <ListLoop v-for="(item) in order.orders" :key="item.id" :item="item" route="orders-detail" itemName="title">
          <template v-slot:details>
            <p>ID: <strong>{{ item.id }}</strong> - Token: <strong>{{ item.token }}</strong> - Amount: <strong v-if="item.totals">{{ item.totals.totalDisplay }}</strong></p>
            <p>
              <strong v-if="item.renewal" class="error badge badge--lozenge">Renewal</strong>
              <strong v-if="item.status == 'new'" class="info badge badge--lozenge">New</strong>
              <strong v-if="item.status == 'completed'" class="success badge badge--lozenge">Completed</strong>
              <strong v-if="item.status == 'shipped'" class="success badge badge--lozenge">Shipped</strong>
              <strong v-if="item.status == 'active'" class="warning badge badge--lozenge">Active</strong>
              <strong v-if="item.status == 'pending'" class="warning badge badge--lozenge">Pending</strong>
              <strong v-if="item.status == 'paid'" class="success badge badge--lozenge">Paid</strong>
              <strong v-if="item.status == 'cancelled'" class="info badge badge--lozenge">Cancelled</strong>
              <strong v-if="item.shipping" class="error badge badge--lozenge">Requires Shipping</strong>
              <strong v-if="item.shipping && (item.status === 'shipped' || item.status === 'completed')" class="success badge badge--lozenge">Dispatched</strong>
            </p>
            <p>Created: <strong>{{ item.created_at }}</strong></p>
            <p>User: <strong>{{ item.user['display_name'] }}</strong></p>
          </template>
        </ListLoop>
      </div>

      <Pagination :page="page" :perPage="perPage" :filter="filter" routeName="orders" :total="totalOrders" />
    </template>

  </div>
</template>

<script>
    import ListLoop from '@/components/ListLoop.vue'
    import Pagination from '@/components/Pagination.vue'
    import { mapState } from 'vuex'

    export default {
        data() {
          return {
            newOrderToggle: false,
            newOrder: {
              productOption: 0,
              user: 0
            },
            isFilterActive: false,
            exporting: false,
            filter: {
              q: this.$route.query.q || '',
              perPage: 10,
              page: parseInt(this.$route.query.page) || 1,
              status: this.$route.query.status || 'all',
              shipping: this.$route.query.shipping || 'all',
              payments: this.$route.query.payments || 'all',
              renewal: this.$route.query.renewal || 'all',
              created_from: this.$route.query.created_from || '',
              created_until: this.$route.query.created_until || '',
            }
          }
        },
        components: {
          ListLoop,
          Pagination
        },
        created() {
          this.$store.dispatch('order/fetchOrders', this.filter)
          this.$store.dispatch('user/fetchFullList', this.filter)
          this.$store.dispatch('product/fetchOptionsFullList', this.filter)
        },
        computed: {
          perPage(){
            return this.filter.perPage;
          },
          page() {
            return this.filter.page;
          },
          totalOrders() {
            return this.order.ordersTotal
          },
          isLoading() {
            return this.order.isLoading
          },
          stats() {
            return this.order.stats
          },
          ...mapState({
            order: state => state.order,
            users: state => state.user.usersFullList,
            productOptions: state => state.product.productOptionsFullList,
          }),
        },
        methods : {
          filterToggle() {
            this.isFilterActive = (this.isFilterActive) ? false : true
          },
          filterResults() {
            this.$store.dispatch('order/fetchOrders', this.filter)
          },
          filterDuePayments() {
            this.filter.payments = 'due-7-days'
            this.filterResults()
          },
          filterOverDuePayments() {
            this.filter.payments = 'overdue'
            this.filterResults()
          },
          exportCSV() {
            this.exporting = true;
            this.$store.dispatch('order/exportOrders', this.filter).then(() => {
              this.exporting = false;
            })
          },
          generateOrder() {
            this.$store.dispatch('order/generateOrder', this.newOrder)
          }
        }

    }
</script>
