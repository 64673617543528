import MediaService from '@/services/MediaService.js'

export const namespaced = true

export const state = {
    media: [],
    singleMedia: {},
    meta: {},
    isLoading: true,
    isOpen: false,
    selectedMedia: 0,
    key: ''
}
export const mutations = {
    SET_MEDIA(state, media) {
        state.media = media
    },
    SET_MEDIA_APPEND(state, media) {
        state.media = [...state.media, ...media];
    },
    SET_SELECTED_MEDIA(state, mediaID) {
        state.selectedMedia = mediaID
    },
    SET_SINGLE_MEDIA(state, singleMedia) {
        state.singleMedia = singleMedia
    },
    UPDATE_MEDIA(state, singleMedia) {
        state.singleMedia = singleMedia
    },
    DELETE_MEDIA(state, index) {
        state.media.splice(index)
    },
    SET_META(state, meta) {
        state.meta = meta
    },
    SET_IS_LOADING(state, isLoading) {
        state.isLoading = isLoading
    },
    OPEN_REVEAL(state) {
        state.isOpen = true
    },
    CLOSE_REVEAL(state) {
        state.isOpen = false
    },
    SET_KEY(state, key) {
        state.key = key
    }
}

export const actions = {
    openReveal({commit}) {
        commit('OPEN_REVEAL')
    },
    closeReveal({commit}) {
        commit('CLOSE_REVEAL')
    },
    setKey({commit}, key) {
        commit('SET_KEY', key)
        console.log(key)
    },
    fetchMedia({commit, dispatch}, {perPage, page, q, type}) {
        MediaService.getMedia(perPage, page, q, type)
            .then(response => {
                commit('SET_MEDIA', response.data.data)
                commit('SET_META', response.data.meta)
                commit('SET_IS_LOADING', false)
            })
            .catch(error => {
                const notification = {
                    type: 'error',
                    message: 'There was a problem getting media items: ' + error.message
                }
                dispatch('notification/add', notification, {root: true})
            })
    },
    fetchMediaAppend({commit, dispatch}, {perPage, page, q, type}) {
        MediaService.getMedia(perPage, page, q, type)
            .then(response => {
                commit('SET_MEDIA_APPEND', response.data.data)
                commit('SET_META', response.data.meta)
                commit('SET_IS_LOADING', false)
            })
            .catch(error => {
                const notification = {
                    type: 'error',
                    message: 'There was a problem getting media items: ' + error.message
                }
                dispatch('notification/add', notification, {root: true})
            })
    },
    fetchSingleMedia({commit, dispatch}, {mediaID}) {
        MediaService.getSingleMedia(mediaID)
            .then(response => {
                commit('SET_SINGLE_MEDIA', response.data.data)
                commit('SET_IS_LOADING', false)
            })
            .catch(error => {
                const notification = {
                    type: 'error',
                    message: 'There was a problem getting a media item: ' + error.message
                }
                dispatch('notification/add', notification, {root: true})
            })
    },
    setSelectedMedia({commit}, {mediaID}) {
      commit('SET_SELECTED_MEDIA', mediaID)
    },
    updateMedia ({ commit, dispatch}, {media, mediaID}) {
        return MediaService.postSingleMedia(media, mediaID).then(response => {
            commit('UPDATE_MEDIA', response.data.data)

            const notification = {
                type: 'success',
                message: 'Media item has been updated!'
            }
            dispatch('notification/add', notification, {root: true})

            return response.data.data
        }).catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem updating the media item: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})

            const errors = error.response.data.errors
            Object.keys(errors).forEach(key => {
                dispatch('error/add', {key, message: errors[key]}, {root: true})
            });

            throw error
        })
    },
    deleteMedia({commit, dispatch}, {mediaID}) {
        const media = state.media.find(media => media.id == mediaID)
        const mediaIndex = state.media.indexOf(media);

        return MediaService.deleteMedia(mediaID).then(() => {
            commit('DELETE_MEDIA', mediaIndex)

            const notification = {
                type: 'success',
                message: 'Media item has been delete!'
            }
            dispatch('notification/add', notification, {root: true})
        }).catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem creating the media item: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})
            throw error
        })
    },
}
