import DashboardService from '@/services/DashboardService.js'

export const namespaced = true

export const state = {
        stats: {},
        isLoading: true,
    }
export const mutations = {
    SET_STATS(state, stats) {
        state.stats = stats
    },
    SET_IS_LOADING(state, isLoading) {
        state.isLoading = isLoading
    }
  }

export const actions = {
    fetchDashboard({commit, dispatch}, filters) {
      DashboardService.getDashboard(filters)
      .then(response => {
        commit('SET_STATS', response.data.data)
        commit('SET_IS_LOADING', false)
      })
      .catch(error => {
        const notification = {
            type: 'error',
            message: 'There was a problem getting the dashboard: ' + error.message
        }
        dispatch('notification/add', notification, {root: true})
      })
    }
  }

export const getters = {
    getTaxById: state => id => {
      return state.taxes.find(tax => tax.id == id)
    }
  }
