import apiClient from './apiClient.js'


export default {
    exportUsers(filters) {
        let filtersEncoded = new URLSearchParams(filters).toString();
        return new apiClient({baseURL: process.env.VUE_APP_API_URL, responseType: 'blob'}).get('/users/export?' + filtersEncoded);
    },
    getUsers(filters) {
        let filtersEncoded = new URLSearchParams(filters).toString();
        return new apiClient().get('/users?' + filtersEncoded);
    },
    getFullList() {
        return new apiClient().get('/users-full');
    },
    getUser(id) {
        return new apiClient().get('/users/'+id);
    },
    postUser(user) {
        return new apiClient().post('/users', user);
    },
    updateUser(user) {
        return new apiClient().post('/users/'+user.id, user);
    },
    newsletterForce(user) {
        return new apiClient().get('/users/newsletter-force/'+user.id, user);
    },
    deleteUser(id) {
        return new apiClient().delete('/users/'+id);
    },
    getFavourites() {
        return new apiClient().get('/users/favourites');
    },
    getFavourite(id) {
        return new apiClient().get('/users/favourites/'+id);
    },
    authCheck(authToken) {

        if (authToken === null) {
            console.log('not logged in');
        }

    }
}
