import apiClient from './apiClient.js'

export default {
    getTaxes(filters) {
        let filtersEncoded = new URLSearchParams(filters).toString();
        return new apiClient().get('/tax?' + filtersEncoded);
    },
    getTax(id) {
        return new apiClient().get('/tax/'+id);
    },
    postTax(tax) {
        return new apiClient().post('/tax', tax);
    },
    updateTax(tax) {
        return new apiClient().patch('/tax/'+tax.id, tax);
    },
    deleteTax(id) {
        return new apiClient().delete('/tax/'+id);
    }
}
