<template>
  <div class="form">
    <h1>Forms : {{ form.title }}</h1>
    <div>
    <p v-if="isLoading">Loading...</p>
    <template v-else >

      <div class="grid-x grid-margin-x" >
        <div class="panel cell medium-6">
          <h3>Form Details</h3>
          <button class="button" @click="edit">Edit</button>
          <template v-if="!isEditing">
            <p>{{ form.title }}</p>
            <p>Created: {{ form.created_at }}</p>
          </template>
          <template v-else>
            <form @submit.prevent="update">

              <BaseInput
                v-model="form.title"
                label="Name"
                type="text"
                name="title"
               />

              <BaseButton type="submit" text="Update" />
          </form>
          </template>

        </div>

        <div class="panel cell medium-6">
          <p>Click below to delete this form.</p>
          <template v-if="!form.protected">
            <BaseButton text="Delete" @click="remove" class="button button--danger" />
          </template>
          <template v-else>
            <p>This form can not be removed</p>
          </template>
        </div>


        <div class="panel cell medium-12" >
          <FormBuilder :parent="form" @save="update" />

        </div>

      </div>
    </template>
    </div>
  </div>
</template>

<script>
    import { mapState } from 'vuex'
    import FormBuilder from '@/components/FormBuilder'

    export default {
        props: ["id"],
        data() {
          return {
            isEditing: false
          }
        },
        components : {
          FormBuilder
        },
        created() {
          this.$store.dispatch('error/clear')
          this.$store.dispatch('form/fetchForm', this.id)

        },
        computed: {
          isLoading() {
            return this.form.isLoading
          },
          isDisabled() {
            return false
          },
          ...mapState({
            form: state => state.form.form
          })
        },
        methods: {
          remove() {
            this.$store.dispatch('form/deleteForm', this.id)
          },
          update() {
            this.$store.dispatch('error/clear')
            this.$store.dispatch('form/updateForm', this.form)
            this.isEditing = false
          },
          edit() {
            this.isEditing = (this.isEditing) ? false : true

          }
        }

    }
</script>
