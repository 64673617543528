<template>
  <div class="users">
    <h1>Order : {{ order.id || '' }}</h1>
    <div>
    <p v-if="isLoading && order">Loading...</p>
    <template v-else >

      <div class="grid-x grid-margin-x" >
        <div class="panel cell medium-4">
          <h3>Overview</h3>
            <p v-if="order.renewal" class="margin">
              <strong class="info badge badge--lozenge">Renewal</strong>
              <template v-if="order.renewal">
                <strong class="warning badge badge--lozenge">Renewal Start: {{ new Date(order.start_at).toLocaleDateString('en-GB') }}</strong>
              </template>
            </p>
            <p v-if="order.shipping" class="margin"><strong v-if="order.shipped_at" class="success badge badge--lozenge" >Shipped: {{ order.shipped_at }}</strong><strong v-else class="error badge badge--lozenge">Requires Shipping <BaseButton @click="orderShipped()" text="Mark as shipped" class="button--small button--outline" /></strong> </p>
            <p v-if="order.paid_at" class="margin"><strong  class="success badge badge--lozenge" >Paid: {{ order.paid_at }}</strong></p>
            <p v-if="order.status === 'new'" class="margin"><strong class="info badge badge--lozenge">New Order</strong></p>

            <p>ID: <strong>{{ order.id }}</strong> - Amount: <strong v-if="order.totals">{{ order.totals.totalDisplay }}</strong></p>
            <p>Token: <strong>{{ order.token }}</strong></p>
            <p>Created: <strong>{{ order.created_at }}</strong></p>
            <p v-if="order.user">User: <strong>{{ order.user.display_name }}</strong></p>
            <form @submit.prevent="update">
              <BaseRadioGroup
                v-model="order.status"
                :options="order.statuses"
                optionValue="id"
                optionName="name"
                name="status"
                label="Order Status"
                bar
               />
               <BaseButton text="Save" />
            </form>
        </div>
        <div class="panel cell medium-8">
          <div class="grid-x grid-margin-x">
            <div class="cell medium-4" v-if="order.address_billing_json">
              <h3>Billing Details</h3>
              <p>{{ order.address_billing_json.first_name }} {{ order.address_billing_json.last_name }}</p>
              <p>{{ order.address_billing_json.company }}</p>
              <p>{{ order.address_billing_json.street_address_1 }}</p>
              <p>{{ order.address_billing_json.street_address_2 }}</p>
              <p>{{ order.address_billing_json.city }}</p>
              <p>{{ order.address_billing_json.county }}</p>
              <p>{{ order.address_billing_json.postcode }}</p>
              <p>{{ order.countries[order.address_billing_json.country] }}</p>
              <p>Telephone: {{ order.address_billing_json.phone }}</p>
            </div>
            <div class="cell medium-4" v-if="order.shipping" >
              <h3>Shipping Details</h3>
              <p>{{ order.address_delivery_json.first_name }} {{ order.address_delivery_json.last_name }}</p>
              <p>{{ order.address_delivery_json.company }}</p>
              <p>{{ order.address_delivery_json.street_address_1 }}</p>
              <p>{{ order.address_delivery_json.street_address_2 }}</p>
              <p>{{ order.address_delivery_json.city }}</p>
              <p>{{ order.address_delivery_json.county }}</p>
              <p>{{ order.address_delivery_json.postcode }}</p>
              <p>{{ order.countries[order.address_delivery_json.country] }}</p>
              <p>Telephone: {{ order.address_delivery_json.phone }}</p>
            </div>
            <div class="cell medium-4" v-if="order.address_account_json" >
              <h3>Account Details</h3>
              <p>{{ order.address_account_json.first_name }} {{ order.address_account_json.last_name }}</p>
              <p>{{ order.address_account_json.company }}</p>
              <p>{{ order.address_account_json.street_address_1 }}</p>
              <p>{{ order.address_account_json.street_address_2 }}</p>
              <p>{{ order.address_account_json.city }}</p>
              <p>{{ order.address_account_json.county }}</p>
              <p>{{ order.address_account_json.postcode }}</p>
              <p>{{ order.countries[order.address_account_json.country] }}</p>
              <p>Telephone: {{ order.address_account_json.phone }}</p>
            </div>
          </div>
        </div>

      </div>
      <div class="grid-x grid-margin-x" >
        <div class="cell medium-6">
          <div class="panel" v-if="order && order.basket && order.basket.data">
            <h3>Summary</h3>

            <template v-for="(basketCont, basketType) in order.basket.data" :basketCont="basketCont" :key="basketType">
              <table class="stack">
                <thead>
                  <tr>
                    <th class="caps">{{ basketType }}</th>
                    <th>Type</th>
                    <th v-if="basketType !== 'subscription'">Qty</th>
                    <th>Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item) in basketCont" :item="item" :key="item.id">
                    <td>
                      <div class="basket-product">

                        <div>
                          {{ item.product.title }}
                          <span v-for="(category) in item.product.categories.assigned" :category="category" :key="category.slug">{{ category.listTitle }}</span>
                        </div>
                      </div>
                    </td>
                    <td>{{ item.option.title }}</td>
                    <td v-if="basketType !== 'subscription'">
                      {{item.qty}}
                    </td>
                    <td>
                      {{ item.total_display }}
                      <span v-if="item.monthly" class="small">{{ item.option.monthly_renewal }} monthly payments of {{ item.option.monthly_price_display }}</span>
                      <span class="small">Ex. VAT</span>
                    </td>

                  </tr>
                </tbody>
              </table>
            </template>
          </div>
          <div class="panel" v-if="order.subscriptions">
            <h3>Subscriptions</h3>
            <template v-for="(subscription, subscriptionKey) in order.subscriptions" :subscription="subscription" :key="'sub_' + subscriptionKey">
              <div class="panel panel--flex">
                <div>
                  <p>Subscription: <strong>{{ subscription.title }}</strong></p>
                  <p>Status: <strong :class="{'warning badge badge--lozenge': !subscription.status, 'success badge badge--lozenge': subscription.status}">{{ subscription.status ? 'Active' : 'Inactive'}}</strong> Auto Renew: <strong :class="{'warning badge badge--lozenge': !subscription.auto_renew, 'success badge badge--lozenge': subscription.auto_renew}">{{ subscription.auto_renew ? 'On' : 'Off'}}</strong></p>
                  <p>Expiry: <strong>{{ subscription.expiry }}</strong></p>
                </div>
                <div>
                  <p>
                    <BaseButton text="Activate" @click="subscriptionActivate(subscriptionKey, subscription.id)" v-if="!subscription.status" />
                    <BaseButton text="Deactivate" @click="subscriptionDeactivate(subscriptionKey, subscription.id)" class="button button--outline" v-if="subscription.status" />
                  </p>
                </div>
              </div>
            </template>
          </div>
        </div>
        <div class="panel cell medium-6">
          <h3>Payments & Totals</h3>
            <p v-if="order.monthly" class="left"><strong class="info badge badge--lozenge">When all payments are marked as paid the renewal for the next subscription cycle will be automatically sent to user</strong></p>
            <p v-if="order.totals && order.totals.subTotal">Sub Total: <strong>{{ order.totals.subTotal }}</strong></p>
            <p v-if="order.totals && order.totals.tax">VAT: <strong>{{ order.totals.tax }}</strong></p>
            <p v-if="order.totals && order.totals.shipping">Shipping: <strong>{{ order.totals.shipping }}</strong></p>
            <p v-if="order.totals && order.totals.discount">Discount: <strong>-{{ order.totals.discount }}</strong></p>
            <p v-if="order.totals && order.total">Overall Total: <strong>{{ order.total }}</strong></p>
            <p v-if="order.total_due">Due: <strong>{{ order.total_due }}</strong> - (Current billing cycle)</p>
            <p v-if="order.total_paid">Paid: <strong>{{ order.total_paid }}</strong></p>
            <p v-if="order.monthly">Monthly: <strong>{{ order.total_monthly }}</strong></p>

             <template v-if="order.payments">
              <table class="payments">
                <thead>
                  <tr>
                    <th class="caps">Amount</th>
                    <th>Due</th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(payment, paymentkey) in order.payments" :payment="payment" :key="'payment_' + paymentkey">
                    <td><strong>{{ payment.amount }}</strong></td>
                    <td><strong>{{ payment.due }}</strong></td>
                    <td><strong>{{ payment.type }}</strong></td>
                    <td>
                      <strong :class="{'warning badge badge--lozenge': !payment.paid, 'success badge badge--lozenge': payment.paid}">{{ payment.paid ? 'Paid' : 'Unpaid'}}</strong>
                      <strong class="info badge badge--lozenge">{{ payment.title }}</strong>
                    </td>
                    <td>
                      <BaseButton v-if="!payment.paid" class="button button--small" text="Mark Paid" @click="paymentPaid(paymentkey, payment.id)" />
                      <BaseButton v-else class="button button--small button--outline" text="Mark Unpaid" @click="paymentUnPaid(paymentkey, payment.id)" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </template>
            <p v-else>No payments have been generated or made for this order yet</p>
        </div>
        <div class="panel cell medium-6">
          <h3>Discounts</h3>
          <div v-if="order && order.basket && order.basket.discounts">
            <div v-for="(discount, discountKey) in order.basket.discounts" :discount="discount" :key="'discount_' + discountKey">
              <p>Discount Applied: <strong>{{ discount.title }}</strong></p>
              <p>Code: <strong>{{ discount.code }}</strong></p>
              <p>Type: <strong>{{ discount.type }}</strong></p>
              <p>Amount: <strong>{{ discount.amount }}</strong></p>
            </div>
          </div>
          <p v-else >No discounts applied to this order</p>
        </div>

        <div class="panel cell medium-12">
          <div>
            <h3>Contract Agreement</h3>
            <div class="contract" v-html="order.contract" v-if="order.contract"></div>
            <p v-else>No contract yet set/required for this order</p>
          </div>
        </div>
      </div>

      <div class="grid-x grid-margin-x" >
        <div class="panel cell medium-12">
          <p>Click below to delete this order, please note all corresponding data will also be removed.</p>
          <Remove :onRemove="remove" message="Are you sure you want to delete this order?" />
        </div>
      </div>
    </template>
    </div>
  </div>
</template>

<script>
    import Remove from '@/components/Remove.vue'
    import { mapState } from 'vuex'

    export default {
        props: ["id"],
        data() {
          return {
            isEditing: false
          }
        },
        components : {
          Remove
        },
        created() {
          this.$store.dispatch('error/clear')
          this.$store.dispatch('order/fetchOrder', this.id)

        },
        computed: {
          isDisabled() {
            return false
          },
          ...mapState({
            order: state => state.order.order || 0,
            isLoading: state => state.order.isLoading
          })
        },
        methods: {
          remove() {
            this.$store.dispatch('order/deleteOrder', this.id).then(() => {
              this.$router.push({
                name: 'orders'
              })
            })
          },
          update() {
            this.$store.dispatch('error/clear')
            this.$store.dispatch('order/updateOrder', this.order)
            this.isEditing = false
          },
          edit() {
            this.isEditing = (this.isEditing) ? false : true

          },
          subscriptionActivate(key, id) {
            const data = {key: key, data: {id: id, status: 1}}
            this.$store.dispatch('error/clear')
            this.$store.dispatch('subscription/updateSubscription', data)

            this.order.subscriptions[key].status = 1
            this.$store.dispatch('order/updateOrder', this.order)
          },
          subscriptionDeactivate(key, id) {
            const data = {key: key, data: {id: id, status: 0}}
            this.$store.dispatch('error/clear')
            this.$store.dispatch('subscription/updateSubscription', data)

            this.order.subscriptions[key].status = 1
            this.$store.dispatch('order/updateOrder', this.order)
          },
          paymentPaid(key, id) {
            const data = {key: key, data: {id: id, paid: 1}}
            this.$store.dispatch('error/clear')
            this.$store.dispatch('payment/updatePayment', data).then(() => {
              this.$store.dispatch('order/fetchOrder', this.id)
            })
          },
          paymentUnPaid(key, id) {
            const data = {key: key, data: {id: id, paid: 0}}
            this.$store.dispatch('error/clear')
            this.$store.dispatch('payment/updatePayment', data).then(() => {
              this.$store.dispatch('order/fetchOrder', this.id)
            })

          },
          orderShipped() {
            this.order.shipped_at = 1
            this.$store.dispatch('order/updateOrder', this.order)
          }
        }

    }
</script>

<style lang="scss">
  .caps {
    text-transform: capitalize;
  }
  .basket-product {
    img, svg {
      max-width: 200px;
      max-height: 200px;
    }

  }
  .left .badge {
    text-align:left;
    margin-bottom: 10px;
  }
  .small {
    display: block;
    font-size: 0.8em;
  }

  table.payments {
    margin-top: 30px;
  }
</style>
