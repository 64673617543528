<template>
  <div class="contents">
    <div>
      <p v-if="isLoading">Loading...</p>
      <template v-else >
          <div class="grid-container" >
            <h1>{{ content.title }}</h1>
            <div class="grid-x grid-margin-x" >
              <div class="panel cell medium-4">
                <h3>Content Details</h3>
                <BaseButton @click="edit" text="Edit" />
                <BaseButton @click="viewOnSite" class="button--outline" text="View on site" />
                <template v-if="!isEditing">
                  <p><strong>Author: </strong> <span >{{ content.user_manual || content.author || 'N/A' }}</span></p>
                  <p><strong>Title: </strong> {{ content.title }}</p>
                  <p><strong>Slug: </strong> {{ content.slug }}</p>
                  <p style="margin: 5px 0;">
                    <strong class="info badge badge--lozenge capitalize">{{ content.media_type }}</strong>
                    <strong class="info badge badge--lozenge capitalize">{{ content.type }}</strong>
                    <strong v-if="content.status" class="success badge badge--lozenge">Live</strong>
                    <strong v-if="!content.status" class="error badge badge--lozenge">Inactive</strong>
                    <strong v-if="content.set_year" class="info badge badge--lozenge">{{ content.set_year }}</strong>
                  </p>
                  <p v-if="content.publish_at"><strong>Publish at: </strong><strong class="badge badge--lozenge info">{{ content.publish_at }}</strong></p>
                  <p><strong>Created at: </strong>{{ content.created_at }}</p>
                  <p><strong>Protected? </strong><strong v-if="content.is_premium" class="badge badge--lozenge warning">Yes</strong><strong v-else class="badge badge--lozenge warning">No</strong></p>


                  <p>
                    <strong>Featured Image: </strong>
                    <MediaReveal
                        v-model="content.media_id"
                        :uniqueKey="'content_' + content.id"
                        :save="update"
                    />
                  </p>

                </template>
                <template v-else>
                  <form @submit.prevent="update">

                    <p v-if="content.author">Author: <strong>{{ content.user_manual || content.author }}</strong></p>
                    <div style="background-color: #F0EFEB; padding: 5px; border-radius: 2px;">
                      <p v-if="content.author" class="warning small badge badge--lozenge"><strong>Note: If this is changed the original author will not be remembered</strong></p>
                      <BaseSelect
                          :options="content.authors"
                          v-model="content.user_id"
                          optionValue="id"
                          optionName="display_name"
                          label="Select author"
                      />
                      <p class="info badge small badge--lozenge"><strong>Entering an author name below will override the selected one above. Once overridden to revert to the author above remove the text from this field.</strong></p>
                      <BaseInput
                        v-model="content.user_manual"
                        label="Manual Author Text"
                        type="text"
                        name="user_manual"
                       />
                    </div>

                    <BaseInput
                      v-model="content.title"
                      label="Title"
                      type="text"
                      name="title"
                     />
                    <BaseInput
                        v-model="content.slug"
                        label="Slug"
                        type="text"
                        name="slug"
                    />
                    <BaseInput
                        v-model="content.video"
                        label="Video (YT/Vimdeo Embed ID)"
                        type="text"
                        name="video"
                    />
                    <BaseTextarea
                      v-model="content.intro"
                      label="Introduction"
                      type="text"
                      name="intro"
                     />
                    <BaseRadioGroup
                      v-model="content.media_type"
                      :options="content.mediaTypes"
                      optionValue="id"
                      optionName="name"
                      name="media_type"
                      label="Media Type"
                      bar
                     />

                    <BaseSwitch
                      v-model="content.status"
                      :options="[{name: 'Draft'},{name: 'Live'}]"
                      optionName="name"
                      label="Status"
                     />
                    <BaseDate
                      v-model="content.publish_at"
                      label="Publish At (Status above must be set to 'Live')"
                     />
                    <BaseCheckbox
                        v-model="content.is_premium"
                        label="Protected content"
                        type="checkbox"
                        name="is_premium"
                        id="is_premium"
                    />

                    <BaseButton type="submit" text="Update" />
                  </form>
                </template>
              </div>

              <div class="cell medium-8" >
                <div class="panel cell medium-12" >
                    <h3>Course & Modules</h3>
                    <p>Select the courses and moudles this content appears in.</p>
                    <template v-if="categoriesAssigned">
                      <span v-for="(item) in categoriesAssigned" :key="item.id" :item="item" class="tag" >
                        {{ item.title }}
                        <BaseButton class="button button--remove" text="Remove" @click.prevent="removeCategory(item.id)" />
                      </span>
                    </template>
                    <p v-else>None have been assigned yet</p>

                    <template v-if="categoriesAvailable">
                      <form @submit.prevent="update" class="form-min">
                        <BaseAutoComplete
                          v-model="content.addCategory"
                          :options="categoriesAvailable"
                          placeholder="Search to assign..."
                         />
                        <BaseButton v-if="!content.addCategory" text="Save" disabled />
                        <BaseButton v-else text="Save" @click.prevent="update"  />
                      </form>


                    </template>
                    <p v-else>No more available to assign</p>

                </div>

                <div class="panel cell medium-12" v-if="content.is_premium">
                    <h3>Granted Roles</h3>
                    <p>Assign the roles which can access this content. If this is left blank by default ALL user roles will have access.</p>
                    <template v-if="rolesAssigned">
                      <span v-for="(item) in rolesAssigned" :key="item.id" :item="item" class="tag" >
                        {{ item.display_name }}
                        <BaseButton class="button button--remove" text="Remove" @click.prevent="removeRole(item.id)" />
                      </span>
                    </template>
                    <p v-else>No roles have been assigned yet</p>

                    <template v-if="rolesAvailable">
                      <form @submit.prevent="update" class="form-min">
                        <BaseAutoComplete
                          v-model="content.addRole"
                          :options="rolesAvailable"
                          placeholder="Search to assign existing role..."
                         />
                        <BaseButton v-if="!content.addRole" text="Save" disabled />
                        <BaseButton v-else text="Save" @click.prevent="update"  />
                      </form>


                    </template>
                    <p v-else>No more roles available to assign</p>

                </div>
              </div>




              <div class="cell medium-12">
                Click to delete this content
                <Remove :onRemove="remove" />
              </div>

            </div>
          </div>
          <div class="grid-container border-top">
            <div class="grid-x grid-margin-x">
              <div v-if="showEditor" class="cell" :class="editorClass">
                <div class="cell">
                  <div class="cell__header">
                    <h2>Content</h2>
                    <div class="button-group">
                      <BaseButton v-if="!showPreview" @click="togglePreview" text="Preview" />
                    </div>
                  </div>
                </div>
                <ContentBlock class="cell" v-for="(item, templateKey) in templates" :key="item.title" :templateKey="templateKey" :item="item" :blocksTotal="blocksTotal" allowOrder allowDuplicate allowRemove model="content" />
                <p class="panel cell">
                  <BaseButton :text="newBlockButtonText" @click="toggleShowNewBlocks" />
                  <BaseRadioGroup
                    v-if="showNewBlocks"
                    v-model="selectBlock"
                    :options="types"
                    optionValue="id"
                    optionName="title"
                    optionIcon="svg"
                    name="type"
                    label="Select a block to add"
                    ui
                   />
                </p>
              </div>
              <div class="cell" v-if="showPreview"  :class="previewClass" >
                <div class="cell">
                  <div class="cell__header">
                    <h2>Preview</h2>
                    <div class="button-group">
                      <BaseButton v-if="showPreview && showSideNav" @click="togglePreview" text="Close Preview" />
                      <BaseButton v-if="showPreview && showSideNav" @click="togglePreviewLarge" text="Large Preview" class="button button--outline" />
                      <BaseButton v-if="showPreview && !showSideNav" @click="togglePreviewLarge" text="Close Large Preview" class="button button--outline" />
                    </div>
                  </div>
                </div>
                <div class="platform-block" style="background-color:#F0EFEB;">
                  <PreviewBlock v-for="(block, blockKey) in content.blocks" :key="block.id" :blockKey="blockKey" :block="block" />
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import ContentBlock from '@/components/ContentBlock'
    import PreviewBlock from '@/components/PreviewBlock'
    import MediaReveal from '@/components/MediaReveal.vue'
    import Remove from '@/components/Remove'

    export default {

        props: ["id"],
        data() {
          return {
            isEditing: false,
            showPreview: false,
            showEditor: true,
            selectBlock: 0,
            editorClass: 'medium-12',
            previewClass: 'medium-8',
            addNewCategory: false,
            addNewRole: false,
            showNewBlocks: false,
            newBlockButtonText: 'Add new block',
            addRole: false,
          }
        },
        components : {
          ContentBlock,
          PreviewBlock,
          Remove,
          MediaReveal,
        },
        created() {
          this.$store.dispatch('error/clear')
          this.$store.dispatch('content/fetchContent', this.id)
          this.setSrcdoc
        },
        computed: {
          ...mapState({
            content: state => state.content.content,
            blocksTotal: state => state.content.content.blocks.length || 0,
            templates: state => state.content.templates,
            types: state => state.content.content.types,
            isLoading: state=> state.content.isLoading,
            showSideNav: state=> state.layout.showSideNav
          }),
          isDisabled() {
            return false
          },
          rolesAll() {
            return (this.content.roles) ? this.content.roles.all : []
          },
          rolesAssigned() {
            return (this.content.roles) ? this.content.roles.assigned : []
          },
          rolesAvailable() {
            return (this.content.roles) ? this.content.roles.available : []
          },
          categoriesParents() {
            return (this.content.categories) ? this.content.categories.parents : []
          },
          categoriesAssigned() {
            return (this.content.categories) ? this.content.categories.assigned : []
          },
          categoriesAvailable() {
            return (this.content.categories) ? this.content.categories.available : []
          },
          plansAssigned() {
            return (this.content.plans) ? this.content.plans.assigned : []
          },
          plansAvailable() {
            return (this.content.plans) ? this.content.plans.available : []
          },
        },
        methods: {
          remove() {
            this.$store.dispatch('content/deleteContent', this.id)
          },
          update() {
            this.convertToSlug(this.content.slug)
            this.$store.dispatch('error/clear')
            this.$store.dispatch('content/updateContent', this.content).then(() => {
              this.content.removeMedia = null
              this.content.addMedia = null
              this.isEditing = false
            })
          },
          addBlock() {
            this.$store.dispatch('content/duplicateType', this.selectBlock)
          },
          edit() {
            this.isEditing = (this.isEditing) ? false : true
          },
          viewOnSite() {
            var win = window.open(process.env.VUE_APP_SITE_URL + '' + this.content.slug, '_blank');
            win.focus();
          },
          removeMedia(media) {
            this.content.addMedia = null
            this.content.removeMedia = media
            this.$store.dispatch('media/setSelectedMedia', 0)
            this.update()
          },
          editMedia(media) {
             this.$router.push({
                name: 'single-media-detail',
                params: { mediaID: media },
                query: { openEdit: true }
              })
          },
          togglePreview() {
            this.showPreview = this.showPreview ? false : true
            this.editorClass = this.showPreview ? 'medium-4' : 'medium-12'
            this.previewClass = this.showPreview ? 'medium-8' : 'medium-8'
            this.showEditor = this.showPreview ? true : true
          },
          togglePreviewLarge() {
            this.showLargePreview = this.showLargePreview ? false : true
            this.showEditor = this.showLargePreview ? false : true
            this.previewClass = this.showLargePreview ? 'medium-12' : 'medium-8'

            if (this.showLargePreview) {
              this.$store.dispatch('layout/closeSideNav')
            } else {
              this.$store.dispatch('layout/showSideNav')
            }

          },
          toggleShowNewBlocks() {
            this.showNewBlocks = this.showNewBlocks ? false : true
            this.newBlockButtonText = this.showNewBlocks ? 'Hide' : 'Add new block'
          },
          convertToSlug(slug) {
            this.content.slug = slug
                .toLowerCase()
                .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
                .replace(/\s+/g, '-') // collapse whitespace and replace by -
                .replace(/-+/g, '-'); // collapse dashes
          },
          removeRole(role) {
            this.content.addRole = null
            this.content.removeRole = role
            this.update()
          },
          removeCategory(category) {
            this.content.addCategory = null
            this.content.removeCategory = category
            this.update()
          },
        },
        watch: {
          selectBlock() {
            this.addBlock()
          },
        },

    }
</script>
<style land="scss">
  .capitalize {
    text-transform: capitalize;
  }
</style>
