import router from '@/router'
import axios from 'axios'
import store from '@/store'

class apiClient {
  constructor(customOptions) {

    const options = {
                        baseURL: process.env.VUE_APP_API_URL + 'api',
                        withCredentials: false,
                        headers : {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${ store.getters['authentication/authToken'] }`,
                        },
                        ...customOptions
                    };
    const instance = axios.create(options);

    instance.interceptors.response.use(
      response => response,
      error => {
        if (error.response.status === 401) {
          store.dispatch('authentication/logout')
          router.push({ name: 'login' })
        }
        if (error.response.status === 500) {
          console.log('500 error');
        }
        return Promise.reject(error)
      });

    return instance;
  }


}

export default apiClient;
