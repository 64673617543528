<template>
  <div v-if="(typeof modelValue === 'object') && (modelValue !== null)" v-bind="$attrs" class="image-holder">
    <img :src="modelValue.temporary_url" :alt="modelValue.alt_tag"
         style="width: 300px" />
      <BaseButton class="button button--remove" text="Remove" @click.prevent="setMedia(0)"/>
      <BaseButton class="button button--edit" text="Edit" @click.prevent="editMedia(modelValue.id)"/>
  </div>
  <template v-else v-bind="$attrs">
    <div class="upload-area">
      <BaseButton text="Select Media" @click="openReveal" class="small" />
      <strong class="badge badge--lozenge">or</strong>
      <MultiFileUpload small :uniqueKey="uniqueKey" />
    </div>
  </template>
</template>
<script>
import MultiFileUpload from '@/components/MultiFileUpload.vue';
import {mapState} from "vuex";

export default {
  components: {
    MultiFileUpload
  },
  props: {
    modelValue: {
        type: [Object, String],
        default: () => {}
    },
    save: {
      type: Function,
      default: () => {}
    },
    uniqueKey: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState({
      selectedMedia: state => state.media.selectedMedia,
      setKey: state => state.media.key,
      media: state => state.media,
    }),
  },
  methods: {
    openReveal() {
      this.$store.dispatch('media/setKey', this.uniqueKey)
      this.$store.dispatch('media/openReveal')
    },
    setMedia(mediaID) {
      this.$emit('update:modelValue', 'media:'+ mediaID)
      this.$store.dispatch('media/setSelectedMedia', { mediaID })
      this.save()
    },
    editMedia(mediaID) {
     this.$router.push({
        name: 'single-media-detail',
        params: { mediaID: mediaID },
        query: { openEdit: true }
      })
    }
  },
  watch: {
    selectedMedia(newValue) {
      if (newValue > 0 && this.uniqueKey == this.setKey) {
        this.$emit('update:modelValue', 'media:'+ this.selectedMedia)
        this.save()
      }
    },
  }

}
</script>

<style scoped lang="scss">
  .upload-area {
    padding: 0px;
    display: flex;
    border: 1px dashed #1c1c1c;
    align-items: center;
    margin-bottom: 15px;

    .badge {
      border-radius: 100%;
      background-color: transparent;
      color: #1c1c1c;
      font-size: 12px;
      line-height: 30px;
      height: 30px;
      width: 30px;
      padding: 0px;
    }

    .small {
      min-width: 130px;
    }
  }
  .image-holder {
    position: relative;
  }
  .button--remove {
    vertical-align: top;
    position: absolute;
    top: 5px;
    right: 5px;
  }
  .button--edit {
    vertical-align: top;
    position: absolute;
    top: 5px;
    right: 40px;
  }
</style>
