<template>
  <p v-if="isLoading">Loading...</p>
  <div v-else class="users">
    <h1>Setting : {{ setting.title }}</h1>
    <div>
      <div class="grid-x grid-margin-x" >
        <div class="panel cell medium-12">
          <h3>Setting Details</h3>
          <button class="button" @click="edit">Edit</button>
          <template v-if="!isEditing">
            <p><strong>{{ setting.title }}</strong></p>
          </template>
          <template v-else>
            <form @submit.prevent="update">

              <BaseInput
                v-model="setting.title"
                label="Title"
                type="text"
                name="title"
               />

              <BaseButton type="submit" text="Update" />
          </form>
          </template>

        </div>
        <div class="panel cell medium-12" v-if="setting.type == 'form'">
          <FormBuilder />

        </div>

        <div class="panel cell medium-12" v-else-if="setting.type == 'nav'">
          <NavBuilder />

        </div>

        <div class="panel cell medium-12" v-else-if="setting.type == 'content'">
          <h2>Content</h2>
          <ContentBlock class="cell" v-for="(item, templateKey) in templates" :key="item.title" :templateKey="templateKey" :item="item" :blocksTotal="blocksTotal" model="setting" allowOrder allowRemove />
          <p class="panel cell">
            <BaseButton :text="newBlockButtonText" @click="toggleShowNewBlocks" />
            <BaseRadioGroup
              v-if="showNewBlocks"
              v-model="selectBlock"
              :options="types"
              optionValue="id"
              optionName="title"
              optionIcon="svg"
              name="type"
              label="Select a block to add"
              ui
             />
          </p>

        </div>

        <div class="panel cell medium-12" v-else-if="setting.type == 'list'" >
          <ListBuilder :modelName="setting.type" />
        </div>

        <div class="panel cell medium-12" v-else >
          <p>This setting content is automatic and can't be manaully updated</p>
        </div>


      </div>
  </div>
</div>
</template>

<script>
    import { mapState } from 'vuex'
    import FormBuilder from '@/components/FormBuilder'
    import NavBuilder from '@/components/NavBuilder'
    import ContentBlock from '@/components/ContentBlock'
    import ListBuilder from '@/components/ListBuilder'

    export default {
        props: ["id"],
        data() {
          return {
            isEditing: false,
            data: [{
                    'name': 'source',
                    'type': 'select',
                    'options': [{}]
                  },{
                    'name': ''
                  }],
            newBlockButtonText: 'Add block',
            showNewBlocks: false,
            selectBlock: 0,
          }
        },
        components : {
          FormBuilder,
          NavBuilder,
          ListBuilder,
          ContentBlock
        },
        created() {
          this.$store.dispatch('error/clear')
          this.$store.dispatch('setting/fetchSetting', this.id)
          this.$store.dispatch('content/fetchFullContentList')

        },
        computed: {
          isDisabled() {
            return false
          },
          ...mapState({
            setting: state => state.setting.setting,
            isLoading: state => state.setting.isLoading,
            blocksTotal: state => state.setting.setting.blocks.length || 0,
            templates: state => state.setting.templates,
            types: state => state.setting.setting.types,
          }),
        },
        methods: {
          update() {
            this.$store.dispatch('error/clear')
            this.$store.dispatch('setting/updateSetting', this.setting)
            this.isEditing = false
          },
          addBlock() {
            this.$store.dispatch('setting/duplicateType', this.selectBlock)
          },
          edit() {
            this.isEditing = (this.isEditing) ? false : true
          },
          toggleShowNewBlocks() {
            this.showNewBlocks = this.showNewBlocks ? false : true
            this.newBlockButtonText = this.showNewBlocks ? 'Hide' : 'Add new block'
          },
        },
        watch: {
          selectBlock() {
            this.addBlock()
          },
        },

    }
</script>
