<template>
  <div class="content">
    <h1>New Content</h1>
    <div class="panel">
      <form @submit.prevent="createContent">

        <BaseInput
          v-model="content.title"
          label="Title"
          type="text"
          name="title"
         />
        <BaseInput
          v-model="content.intro"
          label="Introduction"
          type="text"
          name="intro"
         />
        <BaseRadioGroup
          v-model="content.media_type"
          :options="template.mediaTypes"
          optionValue="id"
          optionName="name"
          name="media_type"
          label="Media Type"
          bar
         />
        <BaseSwitch
          v-model="content.status"
          :options="[{name: 'Draft'},{name: 'Live'}]"
          optionName="name"
          label="Status"
         />
        <BaseDate
          v-model="content.publish_at"
          label="Publish At (Status above must be set to 'Live')"
         />

        <BaseCheckbox
            v-model="content.is_premium"
            label="Set protected content"
            type="checkbox"
            name="is_premium"
            id="is_premium"
        />

        <BaseButton type="submit" text="Add New Content" />

      </form>
    </div>
  </div>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        data() {
          return {
            content: this.createFreshContentObject(),
            error: {},

          }
        },
        created() {
          this.$store.dispatch('error/clear')
          this.$store.dispatch('content/newContent')
        },
        computed: {
          ...mapState({
            template: state => state.content.template
          })
        },
        methods: {
          createContent() {
            this.$store.dispatch('error/clear')
            this.$store.dispatch('content/createContent', this.content).then(response => {
              this.$router.push({
                name: 'content-detail',
                params: { id: response.id }
              })
              this.content = this.createFreshContentObject()
            }).catch(err => {
                this.error = err
            })
          },
          createFreshContentObject() {
            const content = this.$store.state.content
            const id = Math.floor(Math.random*10000)

            return {
              id: id,
              content: content
            }
          }
        }

    }
</script>
