<template >
    <div class="card">
      <div class="card-divider" ><h3>List Items</h3></div>
      <div class="card-section" >
        <ListBuilderField  v-for="(field, fieldKey) in setting.data" :key="field.title" :modelName="modelName" :field="field" :fieldKey="fieldKey" :fieldTotal="setting.data.length || 0" />
        <BaseButton text="Add item" @click="additem" />
      </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import ListBuilderField from '@/components/ListBuilderField'

    export default {
        data() {
          return {
            isActive: false,
            blankField: {
                    name: '',
                    item: ''
                  }
          }
        },
        props: {
          modelName: {
            type: String,
            default: 'content'
          }
        },
        components : {
          ListBuilderField
        },
        computed: {
          ...mapState({
            setting: state => state.setting.setting,
            isLoading: state => state.setting.isLoading,
          })
        },
        created() {
          this.$store.dispatch('error/clear')
          if (this.modelName != 'list') {
            this.$store.dispatch(this.modelName + '/fetchFullList', this.id)
          }
        },
        methods: {
          additem() {
            this.setting.data.push(this.blankField)
            console.log(this.setting.data)
            this.$store.dispatch('error/clear')
            this.$store.dispatch('setting/updateSetting', this.setting)
          }
        }
    }
</script>

<style scoped>

</style>
