<template>
  <div class="shipping">
    <h1>Shipping Rate - Create</h1>
    <div>
      <form @submit.prevent="createShippingRate">

        <BaseInput
          v-model="shipping.title"
          label="Title"
          type="text"
          name="title"
         />

        <BaseButton type="submit" text="Add New Rate" />

      </form>
    </div>
  </div>
</template>

<script>
    // import Datepicker from 'vue3-datepicker'
    import { mapState } from 'vuex'

    export default {
        data() {
          return {
            shipping: this.createFreshObject(),
            error: {}
          }
        },
        created() {
          this.$store.dispatch('error/clear')
        },
        computed: {
          ...mapState({
            template: state => state.shipping.template
          })
        },
        methods: {
          createShippingRate() {
            this.$store.dispatch('error/clear')
            this.$store.dispatch('shipping/createShipping', this.shipping).then(response => {
              this.$router.push({
                name: 'shipping-detail',
                params: { id: response.id }
              })
              this.shipping = this.createFreshObject()
            }).catch(err => {
                this.error = err
            })
          },
          createFreshObject() {
            const shipping = this.$store.state.shipping
            const id = Math.floor(Math.random*10000)

            return {
              id: id,
              shipping: shipping
            }
          }
        }

    }
</script>
