<template>
    <label :for="modelValue" :class="fieldErrorClass" >
        <span v-if="help" data-tooltip class="top" tabindex="2" :title="help">
            {{ label}}
        </span>
        <template  v-if="!help" >
            {{ label}}:
        </template>
    </label>
    <input
        v-bind="$attrs"
        :placeholder="label"
        :class="fieldErrorClass"
        :value="modelValue"
        :type="type"
        @input="$emit('update:modelValue', $event.target.value)"
    />
    <template v-if="errors.length > 0">
      <span class="message message--error" v-for="(message, keyMessage) in fieldErrors" :key="keyMessage">{{ message }}</span>
    </template>
</template>

<script>

import { mapState } from 'vuex'
export default {
    props: {
        label: {
            type: String,
            default: ''
        },
        modelValue: {
            type: [String, Number, Date],
            default: ''
        },
        type: {
            type: String,
            default: 'text'
        },
        name: {
            type: String,
            defualt: ''
        },
        help: {
            type: [String,Boolean],
            default: false
        }
    },
    computed: {
        ...mapState('error', ['errors']),
        fieldErrorClass() {
            if (this.errors.length > 0) {
                if (this.errors.find(error => error.key == this.name)) {
                    return 'error';
                }
            }
            return ''
        },
        fieldErrors() {
            if (this.errors.length > 0) {
                if (this.errors.find(error => error.key == this.name)) {
                    if (this.errors.find(error => error.key == this.name).message.length > 0) {
                        return this.errors.find(error => error.key == this.name).message
                    }
                }
            }
            return []
        },

    }
}
</script>

<style scoped>

</style>
