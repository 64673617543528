<template>
  <div class="subscriptions">
    <h1>Subscriptions</h1>
    <template v-if="!isFilterActive">
      <BaseButton text="Filter" @click="filterToggle" />
    </template>
    <div v-else class="card">
      <div class="card-divider" style="justify-content: space-between;">
        <h3 class="no-margin">Filter</h3>
        <BaseButton text="X" class="button button--close" @click="filterToggle" />
      </div>
      <div class="card-section gid-container">
        <form @submit.prevent="filterResults" class="grid-x grid-margin-x">
          <div class="cell medium-4 large-3">
            <BaseInput
              v-model="filter.q"
              label="Search"
              type="search"
              name="q"
             />
          </div>

          <div class="cell medium-4 large-3">
            <BaseButton type="submit" text="Filter" style="margin-top: 27px;" />
          </div>
        </form>
      </div>
    </div>

    <p v-if="isLoading">Loading...</p>
    <template v-else >
      <div>
        <ListLoop v-for="(item) in subscription.subscriptions" :key="item.id" :item="item" route="subscriptions-detail" itemName="title">
          <template v-slot:details>
            <p>User: <strong>{{ item.subscribed_user['display_name'] }}</strong></p>
            <p>Plan: <strong>{{ item.title }}</strong></p>
            <p>
              <strong :class="{'warning badge badge--lozenge': !item.status, 'success badge badge--lozenge': item.status}">{{ item.status ? 'Active' : 'Inactive'}}</strong>
              <strong :class="{'warning badge badge--lozenge': !item.auto_renew, 'success badge badge--lozenge': item.auto_renew}">{{ item.auto_renew ? 'Auto Renew: ON' : 'Auto Renew: OFF'}}</strong>
            </p>
            <p>ID: <strong>{{ item.id }}</strong> - Expiry: <strong>{{ item.expiry }}</strong></p>
            <p>Created: <strong>{{ item.created_at }}</strong></p>
          </template>
        </ListLoop>
      </div>

      <Pagination :page="page" :perPage="perPage" :filter="filter" routeName="subscriptions" :total="totalSubscriptions" />
    </template>

  </div>
</template>

<script>
    import ListLoop from '@/components/ListLoop.vue'
    import Pagination from '@/components/Pagination.vue'
    import { mapState } from 'vuex'

    export default {
        data() {
          return {
            isFilterActive: false,
            filter: {
              q: this.$route.query.q || '',
              perPage: 10,
              page: parseInt(this.$route.query.page) || 1
            }
          }
        },
        components: {
          ListLoop,
          Pagination
        },
        created() {
          this.$store.dispatch('subscription/fetchSubscriptions', this.filter)
        },
        computed: {
          perPage(){
            return this.filter.perPage;
          },
          page() {
            return this.filter.page;
          },
          totalSubscriptions() {
            return this.subscription.subscriptionsTotal
          },
          isLoading() {
            return this.subscription.isLoading
          },
          ...mapState(['subscription'])
        },
        methods : {
          filterToggle() {
            this.isFilterActive = (this.isFilterActive) ? false : true
          },
          filterResults() {
            this.$store.dispatch('subscription/fetchSubscriptions', this.filter)
          }
        }

    }
</script>
