<template>
  <div class="subscription-plan">
    <h1>Subscription Plan - Create</h1>
    <div>
      <form @submit.prevent="createSubscriptionPlan">

        <BaseInput
          v-model="subscriptionPlan.title"
          label="Title"
          type="text"
          name="title"
         />

        <BaseButton type="submit" text="Add New Plan" />

      </form>
    </div>
  </div>
</template>

<script>
    // import Datepicker from 'vue3-datepicker'

    export default {
        data() {
          return {
            subscriptionPlan: this.createFreshPlanObject(),
            error: {},

          }
        },
        methods: {
          createSubscriptionPlan() {
            this.$store.dispatch('error/clear')
            this.$store.dispatch('subscriptionPlan/createSubscriptionPlan', this.subscriptionPlan).then(response => {
              this.$router.push({
                name: 'subscription-plan-detail',
                params: { id: response.id }
              })
              this.subscriptionPlan = this.createFreshPlanObject()
            }).catch(err => {
                this.error = err
            })
          },
          createFreshPlanObject() {
            const subscriptionPlan = this.$store.state.subscriptionPlan
            const id = Math.floor(Math.random*10000)

            return {
              id: id,
              subscriptionPlan: subscriptionPlan
            }
          }
        }

    }
</script>
