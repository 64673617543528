
export const namespaced = true

export const state = {
    showSideNav: true
}
export const mutations = {
    SET_SIDE_NAV(state, bool) {
        state.showSideNav = bool
    },
}

export const actions = {
    showSideNav({commit}) {
        commit('SET_SIDE_NAV', true)
    },
    closeSideNav({commit}) {
        commit('SET_SIDE_NAV' ,false)
    },
}
