<template>
    <div>
      <div class="card">
        <div class="card-divider" style="justify-content: space-between">
          <div>
            <BaseButton v-show="isActive" class="button button--minus" text="Minimise" @click="toggleOption()"/>
            <BaseButton v-show="!isActive" class="button button--plus" text="Expand" @click="toggleOption()"/>
            <BaseButton v-if="optionKey" class="button button--up" text="Move Up" @click="orderOption('up')"/>
            <BaseButton v-if="!isLast" class="button button--down" text="Move Down" @click="orderOption('down')"/>
            <h4 @click="toggleOption()" >{{ option.title }}</h4>
          </div>
          <BaseButton class="button button--remove" text="Remove" @click="removeOption()"/>
        </div>
        <div class="card-section" v-show="isActive">
          <div class="grid-x grid-margin-x">
            <div class="cell panel medium-4">
                <h3>Details</h3>
                <p v-if="product.type && editOption.monthly" class="margin-bottom"><strong>Changing prices on subscriptions:</strong> For users already subscribed. When a user is out of contract they will be moved onto the new price, if a user is still in contract they will be kept on the current price untill the end of their contract.</p>
                <template v-if="!editOptionToggle" >
                  <p>Title: <strong>{{ option.title }}</strong></p>
                  <p>Slug: <strong>{{ option.slug }}</strong></p>
                  <p>Type: <strong  class="info badge badge--lozenge">{{ option.typeTitle }}</strong> - SKU: <strong>{{ option.sku }}</strong></p>
                  <p>Price: <strong>{{ option.price }}</strong> - RRP: <strong>{{ option.price_rrp }}</strong></p>
                  <p v-if="option.type === 3">
                    Shipping: <strong v-if="option.shipping" class="success badge badge--lozenge">Yes</strong><strong v-if="!option.shipping" class="error badge badge--lozenge capitalize">No</strong>
                    <span v-if="option.tax">Tax Rate: <strong v-if="option.tax" class="success badge badge--lozenge">{{ option.taxTitle }}</strong></span>
                  </p>
                  <BaseButton @click.prevent="toggleEditOption" text="Edit Option" class="button--outline" />
                </template>
                <form v-else @submit.prevent="save" >
                  <BaseInput
                    v-model="editOption.title"
                    label="Title"
                   />
                  <BaseInput
                    v-model="editOption.slug"
                    label="Slug"
                   />
                  <BaseRadioGroup
                    v-model="editOption.type"
                    :options="editOption.types"
                    optionValue="id"
                    optionName="name"
                    name="type"
                    label="Type"
                    lozenge
                   />
                  <BaseSwitch
                    v-model="editOption.status"
                    :options="[{name: 'Inactive'},{name: 'Live'}]"
                    optionName="name"
                    label="Status"
                   />
                  <BaseInput
                    v-model="editOption.price"
                    label="Price"
                    type="number"
                    step="0.01"
                   />
                  <BaseInput
                    v-model="editOption.price_rrp"
                    label="RRP"
                    type="number"
                    step="0.01"
                   />


                  <BaseSwitch
                    v-if="editOption.type === 3"
                    v-model="editOption.shipping"
                    :options="[{name: 'No'},{name: 'Yes'}]"
                    optionName="name"
                    label="Charge Shipping?"
                   />

                  <BaseSelect
                    :options="product.taxRates"
                    v-model="editOption.tax"
                    label="Select tax rate"
                    optionValue="id"
                    optionName="title"
                   />

                  <BaseSwitch
                    v-model="editOption.monthly"
                    :options="[{name: 'No'},{name: 'Yes'}]"
                    optionName="name"
                    label="Allow monthly payments?"
                   />
                  <template v-if="editOption.monthly">
                    <BaseInput
                      v-model="editOption.monthly_price"
                      label="Monthly Price"
                      type="number"
                      step="0.01"
                     />
                    <BaseInput
                      v-model="editOption.monthly_renewal"
                      label="Months till expiry - Subscriptions only"
                      type="number"
                     />
                  </template>
                  <BaseSwitch
                    v-model="editOption.renewal"
                    :options="[{name: 'No'},{name: 'Yes'}]"
                    optionName="name"
                    label="Requires Renewal?"
                   />
                  <template v-if="renewal">
                    <BaseInput
                      v-model="editOption.renewal"
                      label="Months till renewal sent - Subscriptions only"
                      type="number"
                     />
                  </template>
                  <BaseButton text="Save" />
                  <BaseButton text="Close" class="button button--outline" @click.prevent="toggleEditOption" />
                </form>
                <h3 class="no-margin" style="margin-top: 60px;">Discounts</h3>
                <p  v-if="editOption.discounts.length">Codes applied to this option</p>
                <div v-if="editOption.discounts.length">
                  <a :href="'/discount/' + item.id"  v-for="(item) in editOption.discounts" :key="item.id" :item="item" class="tag" >
                    {{ item.title }}
                  </a>
                </div>
                <p v-else>No discounts directly applied to this option.</p>
            </div>
            <div class="cell medium-8">
              <div class="panel">
                <h3>Subscription Plans</h3>
                <p>These are subscription plans the user will have access to when this option is purchased.</p>
                <div v-if="plansAssigned.length" class="cell cell--padding-top">
                  <span v-for="(item) in plansAssigned" :key="item.id" :item="item" class="tag" >
                    {{ item.title }}
                    <BaseButton class="button button--remove" text="Remove" @click.prevent="removePlan(item.id)" />
                  </span>
                </div>
                <p v-else>No plans have been assigned yet</p>

                <h3 class="no-margin">Plans available to assign</h3>
                <p>Assign this content to all the subscription plans that it should be available in.</p>
                <div v-if="plansAvailable.length" class="cell cell--padding-top">
                  <span v-for="(item) in plansAvailable" :key="item.id" :item="item" class="tag" >
                    {{ item.title }}
                    <BaseButton class="button button--plus" text="Add" @click.prevent="addPlan(item.id)" />
                  </span>
                </div>
                <p v-else>No more plans to assign</p>


              </div>
              <div class="panel">
                <h3>Additional Content Permissions</h3>
                <p>These are <strong>content</strong> categories, tags or individual items a user will have acess to when this option is purchased, use this to assign things like Trendbooks, PDFs or downloads. This is usefull when specific pieces of content are not available in one of the subscription plans assigned.</p>

                <template v-if="permissionsAssigned.length">
                  <span v-for="(item) in permissionsAssigned" :key="item.id" :item="item" class="tag" >
                    {{ item.title }}
                    <BaseButton class="button button--remove" text="Remove" @click.prevent="removePermission(item.id)" />
                  </span>
                </template>
                <p v-else>No content permissions have been assigned yet</p>

                <form v-if="permissionsAvailable.length" @submit.prevent="save" class="form-min">
                  <BaseAutoComplete
                    v-model="editOption.addPermission"
                    :options="permissionsAvailable"
                    placeholder="Search to assign content permission..."
                    subTitle="model_title"
                   />
                  <BaseButton v-if="!editOption.addPermission" text="Save" disabled />
                  <BaseButton v-else text="Save" />
                </form>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>

    import { mapState } from 'vuex'
    export default {
        data() {
          return {
            isActive: false,
            editOptionToggle: false,
            addPermission: false,

          }
        },
        props: {
            option: {
                type: Object,
                required: true
            },
            optionKey: {
                type: Number,
                required: true
            }
        },
        components : {
        },
        computed: {
          ...mapState({
            product: state => state.product.product,
            options: state => state.product.product.options,
            editOption(state) {
              return state.product.product.options[this.optionKey]
            }
          }),
          isLast() {
            return (this.options.length-1) === this.optionKey
          },
          permissionsAssigned() {
            return (this.option.permissions) ? this.option.permissions.assigned : []
          },
          permissionsAvailable() {
            return (this.product.permissions) ? this.product.permissions.available : []
          },
          plansAssigned() {
            return (this.option.plans) ? this.option.plans.assigned : []
          },
          plansAvailable() {
            return (this.option.plans) ? this.option.plans.available : []
          },
          renewal() {
            return (this.option.renewal)
          },
        },
        methods: {
          removeOption() {
            this.$store.dispatch('product/deleteOption', { optionKey: this.optionKey })
          },
          toggleOption() {
            this.isActive = this.isActive ? false : true
          },
          orderOption(direction) {
            this.$store.dispatch('product/orderOption', { optionKey: this.optionKey, direction: direction })
          },
          toggleEditOption() {
            this.editOptionToggle = this.editOptionToggle ? false : true
          },
          save() {
            this.$store.dispatch('product/updateOption', { optionKey: this.optionKey, option: this.editOption })
          },
          removePermission(permission) {
            this.editOption.addPermission = null
            this.editOption.removePermission = permission
            this.save()
          },
          removePlan(plan) {
            this.editOption.addPlan = null
            this.editOption.removePlan = plan
            this.save()
          },
          addPlan(plan) {
            console.log('add plan')
            this.editOption.addPlan = plan
            this.editOption.removePlan = null
            this.save()
          },
        }
    }
</script>

<style scoped>
  .margin-bottom {
    margin-bottom: 10px;
  }
</style>
