import AddressService from '@/services/AddressService.js'

export const namespaced = true

export const state = {
    addresses: [],
    address: {},
    isLoading: true,
    template: {
        countries: []
    },
}
export const mutations = {
    NEW(state, template) {
        state.template = template
    },
    SET_ADDRESSES(state, addresses) {
        state.addresses = addresses
    },
    SET_ADDRESS(state, addresses) {
        state.address = addresses
    },
    UPDATE_ADDRESS(state, addresses) {
        state.address = addresses
    },
    DELETE_ADDRESS(state, index) {
        state.addresses.splice(index)
    },
    SET_IS_LOADING(state, isLoading) {
        state.isLoading = isLoading
    }
}

export const actions = {
    newAddress({ commit, dispatch}) {

        return AddressService.newAddress().then(response => {
            commit('NEW', response.data.data)
        }).catch(error => {
            const notification = {
                type: 'error',
                message: error.response.data.message
            }
            dispatch('notification/add', notification, {root: true})

            const errors = error.response.data.errors
            Object.keys(errors).forEach(key => {
                dispatch('error/add', {key, message: errors[key]}, {root: true})
            });

            throw error
        })
    },
    fetchAddresses({commit, dispatch}, id) {

        AddressService.getAddresses(id)
            .then(response => {
                commit('SET_ADDRESSES', response.data.data)
                commit('SET_IS_LOADING', false)
            })
            .catch(error => {
                const notification = {
                    type: 'error',
                    message: 'There was a problem getting a users addresses: ' + error.message
                }
                dispatch('notification/add', notification, {root: true})
            })
    },
    fetchAddress({commit, dispatch}, {id, addressID}) {
        AddressService.getAddress(id, addressID)
            .then(response => {
                commit('SET_ADDRESS', response.data.data)
                commit('SET_IS_LOADING', false)
            })
            .catch(error => {
                const notification = {
                    type: 'error',
                    message: 'There was a problem getting a users addresses: ' + error.message
                }
                dispatch('notification/add', notification, {root: true})
            })
    },
    updateAddress({ commit, dispatch}, {id, address}) {
        return AddressService.postAddress(id, address).then(response => {
            commit('UPDATE_ADDRESS', response.data.data)

            const notification = {
                type: 'success',
                message: 'User address has been updated!'
            }
            dispatch('notification/add', notification, {root: true})

            return response.data.data
        }).catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem updating the user address: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})

            const errors = error.response.data.errors
            Object.keys(errors).forEach(key => {
                dispatch('error/add', {key, message: errors[key]}, {root: true})
            });

            throw error
        })
    },
    deleteAddress({commit, dispatch}, {id, addressID}) {
        const address = state.addresses.find(address => address.id == addressID)
        const addressIndex = state.addresses.indexOf(address);

        return AddressService.deleteAddress(id, addressID).then(() => {
            commit('DELETE_USER', addressIndex)

            const notification = {
                type: 'success',
                message: 'User address has been delete!'
            }
            dispatch('notification/add', notification, {root: true})
        }).catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem creating the user address: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})
            throw error
        })
    },
    createAddress({commit, dispatch}, {id, address}) {
        return AddressService.createAddress(id, address).then(() => {
            commit('SET_IS_LOADING', false)

        }).catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem creating the user address: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})
            throw error
        })
    },
}
