<template>
  <p v-if="isLoading">Loading...</p>

  <template v-else>
    <h1>User: Update Addresses</h1>

    <form @submit.prevent="update">

      <BaseInput
          v-model="address.address.street_address_1"
          label="Street Address 1"
          type="text"
          name="street_address_1"
      />

      <BaseInput
          v-model="address.address.street_address_2"
          label="Street Address 2"
          type="text"
          name="street_address_2"
      />

      <BaseInput
          v-model="address.address.city"
          label="City"
          type="text"
          name="city"
      />

      <BaseInput
          v-model="address.address.county"
          label="County"
          type="text"
          name="county"
      />

      <BaseInput
          v-model="address.address.postcode"
          label="Postcode"
          type="text"
          name="postcode"
      />

      <BaseSelect
        :options="address.address.countries"
        v-model="address.address.country"
        label="Select a country"
       />

      <BaseButton type="submit" text="Update" />

      <BaseButton class="button" text="Cancel" @click="cancel" />
    </form>
  </template>

</template>
<script>
import {mapState} from 'vuex'

export default {
  props: ["id", "addressID"],
  data() {
    return {
      isEditing: false
    }
  },
  created() {
    this.$store.dispatch('error/clear')
    this.$store.dispatch('address/fetchAddress', {
      id: this.id,
      addressID: this.addressID
    })
  },
  computed: {
    isLoading() {
      return this.address.isLoading
    },
    ...mapState(['address'])
  },
  methods: {
    update() {
      this.$store.dispatch('address/updateAddress', {
        id : this.id,
        address: this.address.address
      })
    },
    cancel() {
      this.$router.push({
        name: 'users-addresses',
        params: { id: this.id }
      })
    }
  }
}
</script>

<style scoped>

</style>
