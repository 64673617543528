import apiClient from './apiClient.js'

export default {
    exportBaskets(filters) {
        let filtersEncoded = new URLSearchParams(filters).toString();
        return new apiClient({baseURL: process.env.VUE_APP_API_URL, responseType: 'blob'}).get('/baskets/export?' + filtersEncoded);
    },
    getBaskets(filters) {
        let filtersEncoded = new URLSearchParams(filters).toString();
        return new apiClient().get('/basket-admin?' + filtersEncoded);
    }
}
