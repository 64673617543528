import apiClient from './apiClient.js'

export default {
    newContentCategory() {
        return new apiClient().get('/content-categories/create');
    },
    getContentCategories(filters) {
        let filtersEncoded = new URLSearchParams(filters).toString();
        return new apiClient().get('/content-categories?' + filtersEncoded);
    },
    getContentCategory(id) {
        return new apiClient().get('/content-categories/'+id);
    },
    postContentCategory(tag) {
        return new apiClient().post('/content-categories', tag);
    },
    updateContentCategory(tag) {
        return new apiClient().patch('/content-categories/'+tag.id, tag);
    },
    updateContentCategoryOrder(order) {
        return new apiClient().post('/content-categories/order', order);
    },
    deleteContentCategory(id) {
        return new apiClient().delete('/content-categories/'+id);
    }
}
