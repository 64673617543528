<template>
    <div id="nav" class="cell large-2">
      <nav>
          <ul class="no-bullet">
            <li :class="isActive(['dashboard'])" ><router-link :to="{ name: 'dashboard'}">Dashboard</router-link></li>
            <li :class="isActive(['users','roles','teams','invites','users-favourites'])" >
              <router-link :to="{ name: 'users'}">Users</router-link>
              <BaseButton v-show="users" @click="subToggle('users')" class="button button--minus" text="collapse" />
              <BaseButton v-show="!users" @click="subToggle('users')" class="button button--plus" text="expand" />
              <ul v-if="users">
                <li :class="isActive(['users'])" ><router-link :to="{ name: 'users', query: { approved: '1' }}">Approved</router-link></li>
                <li :class="isActive(['users'])" ><router-link :to="{ name: 'users', query: { approved: '0' }}">Not Approved</router-link></li>
                <li :class="isActive(['roles'])" ><router-link :to="{ name: 'roles'}">Roles</router-link></li>
              </ul>
            </li>
            <li :class="isActive(['message', 'push-notifications'])" >
              <router-link :to="{ name: 'message'}">Messages</router-link>
              <BaseButton v-show="messages" @click="subToggle('messages')" class="button button--minus" text="collapse" />
              <BaseButton v-show="!messages" @click="subToggle('messages')" class="button button--plus" text="expand" />
              <ul v-if="messages">
                <li :class="isActive(['push-notifications'])" ><router-link :to="{ name: 'push-notifications'}">Push Notifications</router-link></li>
              </ul>
            </li>
            <li :class="isActive(['content', 'content-categories', 'content-tags', 'media-library-index', 'search'])" >
              <router-link :to="{ name: 'content'}">Content</router-link>
              <BaseButton v-show="content" @click="subToggle('content')" class="button button--minus" text="collapse" />
              <BaseButton v-show="!content" @click="subToggle('content')" class="button button--plus" text="expand" />
              <ul v-if="content">
                <li :class="isActive(['course'])" ><router-link :to="{ name: 'content-categories'}">Courses</router-link></li>
                <li :class="isActive(['media-library-index'])" ><router-link :to="{ name: 'media-library-index'}">Media</router-link></li>
                <li :class="isActive(['forms'])" ><router-link :to="{ name: 'forms'}">Forms</router-link></li>
                <li :class="isActive(['setting'])" ><router-link :to="{ name: 'setting'}">Settings</router-link></li>
              </ul>
            </li>

            <li :class="isActive(['product', 'product-categories', 'product-tags', 'shipping', 'tax', 'discount'])" >
              <router-link :to="{ name: 'product'}">Store</router-link>
              <BaseButton v-show="product" @click="subToggle('product')" class="button button--minus" text="collapse" />
              <BaseButton v-show="!product" @click="subToggle('product')" class="button button--plus" text="expand" />
              <ul v-if="product">
                <li :class="isActive(['product-categories'])" ><router-link :to="{ name: 'product-categories'}">Categories</router-link></li>
                <li :class="isActive(['product-tags'])" ><router-link :to="{ name: 'product-tags'}">Tags</router-link></li>
                <li :class="isActive(['shipping'])" ><router-link :to="{ name: 'shipping'}">Shipping</router-link></li>
                <li :class="isActive(['tax'])" ><router-link :to="{ name: 'tax'}">Taxes</router-link></li>
                <li :class="isActive(['discount'])" ><router-link :to="{ name: 'discount'}">Discounts</router-link></li>
              </ul>
            </li>
            <li :class="isActive(['orders', 'baskets'])" >
              <router-link :to="{ name: 'orders'}">Orders</router-link>
              <BaseButton v-show="orders" @click="subToggle('orders')" class="button button--minus" text="collapse" />
              <BaseButton v-show="!orders" @click="subToggle('orders')" class="button button--plus" text="expand" />
              <ul v-if="orders">
                <li :class="isActive(['baskets'])" ><router-link :to="{ name: 'baskets'}">Basket Data</router-link></li>
              </ul>
            </li>

            <li :class="isActive(['subscriptions', 'subscription-plans'])" >
              <router-link :to="{ name: 'subscriptions'}">Subscriptions</router-link>
              <BaseButton v-show="subscriptions" @click="subToggle('subscriptions')" class="button button--minus" text="collapse" />
              <BaseButton v-show="!subscriptions" @click="subToggle('subscriptions')" class="button button--plus" text="expand" />
              <ul v-if="subscriptions">
                <li :class="isActive(['subscription-plans'])" ><router-link :to="{ name: 'subscription-plans'}">Plans</router-link></li>
              </ul>
            </li>

          </ul>
      </nav>
    </div>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        data() {
            return {
                content: false,
                users: false,
                product: false,
                subscriptions: false,
                mailing: false,
                messages: false,
            }
        },
        computed: {
          ...mapState({
            showSideNav: state => state.layout.showSideNav
          }),
        },
        methods: {
          subToggle(toggle) {
            this[toggle] = this[toggle] ? false : true
          },
          isActive(route) {
            let className = ''
            if (this.$route.matched[0] !== undefined && route.includes(this.$route.matched[0].name)) {
              className = 'is-active'
              if (this[this.$route.matched[0].name] !== undefined) {
                this[this.$route.matched[0].name] = true
              }
            }

            return className

          },
          toggleSideBar() {
            this.showSideNav = this.showSideNav ? false : true


            if (this.showSideNav) {
              this.$store.dispatch('layout/closeSideNav')
            } else {
              this.$store.dispatch('layout/showSideNav')
            }

          }
        }

    }
</script>
