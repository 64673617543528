<template v-if="!isLoading">
  <div class="card">
    <div class="card-divider" style="justify-content: space-between">
      <div>
        <BaseButton v-show="isParentActive" class="button button--minus" text="Minimise" @click="toggleItem()"/>
        <BaseButton v-show="!isParentActive" class="button button--plus" text="Expand" @click="toggleItem()"/>
        <BaseButton v-if="fieldKey" class="button button--up" text="Move Up" @click="orderItem('up')"/>
        <BaseButton v-if="!isLast" class="button button--down" text="Move Down" @click="orderItem('down')"/>
        <h4 @click="toggleItem()" >{{ form.data[fieldKey].label }}</h4>
      </div>

      <div>
        <BaseButton class="button button--remove" text="Remove" @click="removeItem()"/>
      </div>
    </div>
    <div class="card-section" v-show="isParentActive">
      <BaseInput
        v-model="form.data[fieldKey].label"
        label="Field Label"
        name="label"
        @change="save"
      />
      <BaseInput
        v-model="form.data[fieldKey].name"
        label="Field Name"
        name="name"
        @change="save"
      />
      <BaseSelect
        :options="fieldTypes"
        v-model="form.data[fieldKey].type"
        name="type"
        label="Field Type"
        optionValue="value"
        optionName="name"
        @change="save"
       />
      <BaseSwitch
        v-model="form.data[fieldKey].required"
        :options="[{name: 'No'},{name: 'Yes'}]"
        optionName="name"
        label="Required Field?"
        @change="save"
       />
      <template v-if="form.data[fieldKey].type === 'select'">
        <h3>Select Options</h3>
        <div>
          <div v-for="(option, optionKey) in form.data[fieldKey].options" :key="option.value"  :option="option" class="card" >
            <div class="card-divider">
              <div><p><strong>{{ option.name }}</strong> - value: {{ option.value }}</p></div>
              <div>
                <BaseButton class="button--edit" @click="editOption(optionKey)" text="edit" />
                <BaseButton class="button--remove" @click="removeOption(optionKey)" text="remove" />
              </div>
            </div>
            <div class="card-section" v-if="isActive.includes(optionKey)" >
              <div>
                <BaseInput
                  v-model="form.data[fieldKey].options[optionKey].value"
                  label="Option Value"
                  name="value"
                />
                <BaseInput
                  v-model="form.data[fieldKey].options[optionKey].name"
                  label="Option Name"
                  name="name"
                />
                <BaseButton text="Save Option" @click="update" />
              </div>
            </div>
          </div>
        </div>
        <div>
          <BaseInput
            v-model="newOption.value"
            label="Option Value"
            name="value"
          />
          <BaseInput
            v-model="newOption.name"
            label="Option Name"
            name="name"
          />
          <BaseButton text="Add Option" @click="saveNewOption" />
        </div>
      </template>

      <BaseSwitch
        v-model="form.data[fieldKey].conditional"
        :options="[{name: 'No'},{name: 'Yes'}]"
        optionName="name"
        label="Conditional"
       />

       <template v-if="form.data[fieldKey].conditional">
          <BaseSelect
            :options="fields"
            v-model="form.data[fieldKey].conditionalField"
            name="type"
            label="Show if the field"
            optionValue="value"
            optionName="name"
            @change="save"
           />
          <BaseSelect
            :options="fieldOptions[form.data[fieldKey].conditionalField]"
            v-model="form.data[fieldKey].conditionalValue"
            name="type"
            label="Is equal To"
            optionValue="value"
            optionName="name"
            @change="save"
           />
       </template>
    </div>
  </div>
</template>

<script>
    import { mapState } from 'vuex'


    export default {
      props: {
          field: {
            type: Object,
            required: true
          },
          fieldKey: {
            type: Number,
            required: true
          },
          fieldTotal: {
              type: Number,
              required: true
          },
      },
      data() {
          return {
            newOption: {
              value: '',
              name: ''
            },
            isActive: [],
            isParentActive: false,
            fieldTypes : [{
                value: 'select',
                name: 'Select',
              },
              {
                value: 'text',
                name: 'Text Field',
              },
              {
                value: 'textarea',
                name: 'Text Area',
              },
              {
                value: 'email',
                name: 'Email Field',
              }
            ]
          }
      },
      computed: {
        ...mapState({
          form: state => state.form.form,
          isLoading: state => state.form.isLoading,
        }),
        fields() {
          var data = [];

          if (this.form.data.length) {

            this.form.data.forEach((field) => {
              console.log(field)
              data.push({ value: field.name, name: field.label })
            })

            console.log(data)
          }

          return data
        },
        fieldOptions() {
          var data = [];
          this.form.data.forEach((field) => {
            data[field.name] = field.options
          })

          return data
        },
        isLast() {
          return (this.fieldTotal-1) === this.fieldKey
        }
      },
      methods: {
        save() {
          this.$store.dispatch('error/clear')
          this.$store.dispatch('form/updateForm', this.form)
        },
        saveNewOption() {
          if (this.form.data[this.fieldKey].options === null) {
            this.form.data[this.fieldKey].options = []
          }
          this.form.data[this.fieldKey].options.push(this.newOption)
          this.save()


          this.newOption = {
              value: '',
              name: ''
            }
        },
        removeOption(key) {
          this.form.data[this.fieldKey].options.splice(key, 1)
          this.save()
        },
        editOption(key) {
          const index = this.isActive.indexOf(key);
          if (index > -1) {
            this.isActive.splice(index, 1);
          } else {
            this.isActive.push(key);
          }

        },

        removeItem() {
          this.form.data.splice(this.fieldKey, 1);
          this.save();
        },
        toggleItem() {
          this.isParentActive = this.isParentActive ? false : true
        },
        orderItem(direction) {
          var toIndex = (direction == 'up') ? (this.fieldKey-1) : (this.fieldKey+1);
          var element = this.form.data[this.fieldKey];
          this.form.data.splice(this.fieldKey, 1);
          this.form.data.splice(toIndex, 0, element);
          this.save();
        }
      }
    }
</script>

<style scoped lang="scss">
  .field-group {
    border-bottom: 1px #000 solid;
    padding: 10px 0;

    &:last-of-type {
      border-bottom: 0;
    }
  }

  .card-divider {
    display: flex;
    justify-content: space-between;
  }
</style>
