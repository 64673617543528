<template>
  <div class="product-category">
    <h1>Product Categories - Create</h1>
    <div>
      <form @submit.prevent="createProductCategory">

        <BaseInput
          v-model="productCategory.title"
          label="Title"
          type="text"
          name="title"
         />

        <BaseSelect
          :options="template.parents"
          v-model="productCategory.parent"
          label="Select parent"
          optionValue="id"
          optionName="title"
         />

        <BaseButton type="submit" text="Add New Category" />

      </form>
    </div>
  </div>
</template>

<script>
    // import Datepicker from 'vue3-datepicker'
    import { mapState } from 'vuex'

    export default {
        data() {
          return {
            productCategory: this.createFreshObject(),
            error: {}
          }
        },
        created() {
          this.$store.dispatch('error/clear')
          this.$store.dispatch('productCategory/newProductCategory')
        },
        computed: {
          ...mapState({
            template: state => state.productCategory.template
          })
        },
        methods: {
          createProductCategory() {
            this.$store.dispatch('error/clear')
            this.$store.dispatch('productCategory/createProductCategory', this.productCategory).then(response => {
              this.$router.push({
                name: 'product-category-detail',
                params: { id: response.id }
              })
              this.productCategory = this.createFreshObject()
            }).catch(err => {
                this.error = err
            })
          },
          createFreshObject() {
            const productCategory = this.$store.state.productCategory
            const id = Math.floor(Math.random*10000)

            return {
              id: id,
              parent: 0,
              productCategory: productCategory
            }
          }
        }

    }
</script>
