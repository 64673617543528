import apiClient from './apiClient.js'

export default {
    getSubscriptions(filters) {
        let filtersEncoded = new URLSearchParams(filters).toString();
        return new apiClient().get('/subscription?' + filtersEncoded);
    },
    getSubscription(id) {
        return new apiClient().get('/subscription/'+id);
    },
    postSubscription(subscription) {
        return new apiClient().post('/subscription', subscription);
    },
    updateSubscription(subscription) {
        return new apiClient().patch('/subscription/'+subscription.id, subscription);
    },
    deleteSubscription(id) {
        return new apiClient().delete('/subscription/'+id);
    }
}
