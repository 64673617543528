import apiClient from './apiClient.js'

export default {
    newProductCategory() {
        return new apiClient().get('/product-categories/create');
    },
    getProductCategories(filters) {
        let filtersEncoded = new URLSearchParams(filters).toString();
        return new apiClient().get('/product-categories?' + filtersEncoded);
    },
    getProductCategory(id) {
        return new apiClient().get('/product-categories/'+id);
    },
    postProductCategory(tag) {
        return new apiClient().post('/product-categories', tag);
    },
    updateProductCategory(tag) {
        return new apiClient().patch('/product-categories/'+tag.id, tag);
    },
    deleteProductCategory(id) {
        return new apiClient().delete('/product-categories/'+id);
    }
}
