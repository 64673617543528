<template>
  <p v-if="isLoading">Loading...</p>
  <div v-else class="users">
    <h1>Subscription Plan : {{ subscriptionPlan.title }}</h1>
    <div>
      <p v-if="isLoading">Loading...</p>
      <template v-else >

        <div class="grid-x grid-margin-x" >
          <div class="panel cell medium-4">
            <h3>Plan Details</h3>
            <button class="button" @click="edit">Edit</button>
            <template v-if="!isEditing">
              <p><strong>{{ subscriptionPlan.title }}</strong></p>
              <p><strong v-if="subscriptionPlan.entry_level" class="info badge badge--lozenge">Entry Level</strong> <strong v-if="subscriptionPlan.typeTitle" class="info badge badge--lozenge">{{subscriptionPlan.typeTitle}}</strong></p>
              <p>{{ subscriptionPlan.intro }}</p>

              <BaseInput
                  v-model="subscriptionPlan.primary_colour"
                  label="Primary Colour"
                  name="primary_colour"
                  type="color"
                  @change="update"
              />

              <BaseInput
                  v-model="subscriptionPlan.secondary_colour"
                  label="Secondary Colour"
                  name="secondary_colour"
                  type="color"
                  @change="update"
              />

            </template>
            <template v-else>
              <form @submit.prevent="update">

                <BaseInput
                  v-model="subscriptionPlan.title"
                  label="Title"
                  type="text"
                  name="title"
                 />

                <BaseTextarea
                    v-model="subscriptionPlan.intro"
                    label="Intro"
                    name="intro"
                />

                <BaseRadioGroup
                  v-model="subscriptionPlan.type"
                  :options="subscriptionPlan.planTypes"
                  optionValue="id"
                  optionName="name"
                  name="type"
                  label="Plan Type"
                  bar
                 />

                <BaseRadioGroup
                  v-model="subscriptionPlan.level"
                  :options="subscriptionPlan.levels"
                  optionValue="id"
                  optionName="name"
                  name="level"
                  label="Plan Level"
                  bar
                 />

                <BaseSwitch
                  v-model="subscriptionPlan.entry_level"
                  :options="[{name: 'No'},{name: 'Yes'}]"
                  optionName="name"
                  label="Is plan entry level?"
                 />

                <BaseButton type="submit" text="Update" />
            </form>
            </template>

          </div>

          <div class="cell medium-8">
            <div class="panel">
                <h3>Next Level Subscription Plan</h3>
                <p>This is the next level of the subscription plan offered to users.</p>
                <div v-if="subscriptionPlan.parent_id" class="cell cell--padding-top">
                  <span class="tag" >
                        {{ subscriptionPlan.parent_id.title }}
                        <BaseButton class="button button--remove" text="Remove" @click.prevent="removeParent()" />
                      </span>
                </div>
                <div v-else>
                  <p><strong>Select a plan from below which is the direct next level up from this plan.</strong></p>
                  <div v-if="subscriptionPlans" class="cell cell--padding-top">
                        <span v-for="(item) in subscriptionPlans" :key="item.id" :item="item" class="tag" >
                          {{ item.title }}
                          <BaseButton class="button button--plus" text="Add" @click.prevent="setParent(item.id)" />
                        </span>
                  </div>
                  <p v-else>No plans to assign</p>
                </div>
            </div>
            <div class="panel">
              <h3>Prefered Subscription Option</h3>
              <p>When a user is able to upgrade to this plan they will be offered a CTA which adds the subscription into their basket.</p>

              <div v-if="subscriptionPlan.option_id" class="cell cell--padding-top">
                <span class="tag" >
                      {{ subscriptionPlan.option_id.full_title }}
                      <BaseButton class="button button--remove" text="Remove" @click.prevent="removeOption()" />
                    </span>
              </div>
              <div v-else>
                <p><strong>Select the option that should be used from below</strong></p>
                <div v-if="options" class="cell cell--padding-top">
                      <span v-for="(item) in options" :key="item.id" :item="item" class="tag" >
                        {{ item.full_title }}
                        <BaseButton class="button button--plus" text="Add" @click.prevent="setOption(item.id)" />
                      </span>
                </div>
                <p v-else>No options to assign</p>
              </div>
            </div>
          </div>

        </div>

        <div >
          <h2>Content</h2>
          <ContentBlock class="cell" v-for="(item, templateKey) in templates" :key="item.title" :templateKey="templateKey" :item="item" :blocksTotal="blocksTotal" model="subscriptionPlan" />
          <p class="panel cell" v-if="!templates.length">
            <BaseButton :text="newBlockButtonText" @click="toggleShowNewBlocks" />
            <BaseRadioGroup
              v-if="showNewBlocks"
              v-model="selectBlock"
              :options="types"
              optionValue="id"
              optionName="title"
              optionIcon="svg"
              name="type"
              label="Select a block to add"
              ui
             />
          </p>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
    import { mapState } from 'vuex'
    import ContentBlock from '@/components/ContentBlock'

    export default {
      props: ["id"],
      data() {
        return {
          isEditing: false,
          newBlockButtonText: 'Add block',
          showNewBlocks: false,
          selectBlock: 0,
        }
      },
      components : {
        ContentBlock
      },
      created() {
          this.$store.dispatch('error/clear')
          this.$store.dispatch('subscriptionPlan/fetchSubscriptionPlan', this.id)
          this.$store.dispatch('subscriptionPlan/fetchSubscriptionPlans')
      },
      computed: {
        isLoading() {
          return this.subscriptionPlan.isLoading
        },
        isDisabled() {
          return false
        },
        ...mapState({
          subscriptionPlan: state => state.subscriptionPlan.subscriptionPlan,
          subscriptionPlans: state => state.subscriptionPlan.subscriptionPlans || 0,
          content: state => state.subscriptionPlan.subscriptionPlan,
          blocksTotal: state => state.subscriptionPlan.subscriptionPlan.blocks.length || 0,
          templates: state => state.subscriptionPlan.templates,
          types: state => state.subscriptionPlan.subscriptionPlan.types,
          options: state => state.subscriptionPlan.subscriptionPlan.options || 0
        })
      },
      methods: {
        update() {
          this.$store.dispatch('error/clear')
          this.$store.dispatch('subscriptionPlan/updateSubscriptionPlan', this.subscriptionPlan)
          this.isEditing = false
        },
        addBlock() {
          this.$store.dispatch('subscriptionPlan/duplicateType', this.selectBlock)
        },
        setParent(id){
          this.subscriptionPlan.parent_id = id;
          this.$store.dispatch('subscriptionPlan/updateSubscriptionPlan', this.subscriptionPlan)
        },
        removeParent() {
          this.subscriptionPlan.parent_id = null;
          this.$store.dispatch('subscriptionPlan/updateSubscriptionPlan', this.subscriptionPlan)
        },
        setOption(id){
          this.subscriptionPlan.option_id = id;
          this.$store.dispatch('subscriptionPlan/updateSubscriptionPlan', this.subscriptionPlan)
        },
        removeOption() {
          this.subscriptionPlan.option_id = null;
          this.$store.dispatch('subscriptionPlan/updateSubscriptionPlan', this.subscriptionPlan)
        },
        edit() {
          this.isEditing = (this.isEditing) ? false : true

        },
        toggleShowNewBlocks() {
          this.showNewBlocks = this.showNewBlocks ? false : true
          this.newBlockButtonText = this.showNewBlocks ? 'Hide' : 'Add new block'
        },
      },
      watch: {
        selectBlock() {
          this.addBlock()
        },
      },

    }
</script>
