<template>
  <h1>Users saved content</h1>

  <p v-if="isLoading">Loading...</p>

  <div v-else>
        <div v-for="(favourite) in user.favourites" :key="favourite.id">
          <div class="loop-list">
            <div class="loop-list__details">
              <div>
                <p>{{ favourite.name }}</p>
                <p>Number of content saved: {{ favourite.count }}</p>
              </div>
              <div>
                <router-link :to="{ name: 'users-detail', params: { id: favourite.id }}"><BaseButton text="View User" /></router-link>
                <router-link :to="{ name: 'user-favourite-detail', params: { userID: favourite.id }}"><BaseButton text="View Saved Content" /></router-link>
              </div>
            </div>
          </div>
        </div>
  </div>
</template>

<script>
  import {mapState} from "vuex";

  export default {
    created() {
      this.$store.dispatch('user/fetchFavourites')
    },
    computed: {
      isLoading() {
        return this.user.isLoading
      },
      ...mapState(['user'])
    }
  }
</script>

<style scoped>

</style>