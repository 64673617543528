import ContentCategoryService from '@/services/ContentCategoryService.js'

export const namespaced = true

export const state = {
        contentCategories: [],
        contentCategoriesTotal: 0,
        contentCategory: {
        },
        template: {
            children: [],
            parents: [],
            master: [],
        },
        isLoading: true,
        meta: {},
    }
export const mutations = {
    NEW(state, template) {
        state.template = template
    },
    ADD(state, contentCategory) {
        state.contentCategories.push(contentCategory)
    },
    UPDATE(state, contentCategory) {
        state.contentCategory = contentCategory
    },
    DELETE(state, index) {
        state.contentCategories.splice(index)
    },
    SET_LIST(state, contentCategories) {
        state.contentCategories = contentCategories
    },
    SET_META(state, meta) {
        state.meta = meta
    },
    SET_LIST_TOTAL(state, total) {
        state.contentCategoriesTotal = total
    },
    SET_ITEM(state, contentCategory) {
        state.contentCategory = contentCategory
    },
    SET_IS_LOADING(state, isLoading) {
        state.isLoading = isLoading
    }
  }

export const actions = {
    newContentCategory({ commit, dispatch}) {

        return ContentCategoryService.newContentCategory().then(response => {
            commit('NEW', response.data.data)
        }).catch(error => {
            const notification = {
                type: 'error',
                message: error.response.data.message
            }
            dispatch('notification/add', notification, {root: true})

            const errors = error.response.data.errors
            Object.keys(errors).forEach(key => {
                dispatch('error/add', {key, message: errors[key]}, {root: true})
            });

            throw error
        })
    },
    createContentCategory({ commit, dispatch}, contentCategory) {

        return ContentCategoryService.postContentCategory(contentCategory).then(response => {
            commit('ADD', contentCategory)
            const notification = {
                type: 'success',
                message: 'New content has been created!'
            }
            dispatch('notification/add', notification, {root: true})

            return response.data.data
        }).catch(error => {
            const notification = {
                type: 'error',
                message: error.response.data.message
            }
            dispatch('notification/add', notification, {root: true})

            const errors = error.response.data.errors
            Object.keys(errors).forEach(key => {
                dispatch('error/add', {key, message: errors[key]}, {root: true})
            });

            throw error
        })
    },
    updateContentCategory({ commit, dispatch}, contentCategory) {

        return ContentCategoryService.updateContentCategory(contentCategory).then(response => {
            commit('UPDATE', response.data.data)

            const notification = {
                type: 'success',
                message: 'Saved!'
            }
            dispatch('notification/add', notification, {root: true})


        }).catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem updating the content category: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})

            const errors = error.response.data.errors
            Object.keys(errors).forEach(key => {
                dispatch('error/add', {key, message: errors[key]}, {root: true})
            });

            throw error
        })
    },
    updateContentCategoryOrder({ dispatch }, order) {

        return ContentCategoryService.updateContentCategoryOrder(order).then(() => {

                const notification = {
                    type: 'success',
                    message: 'Saved!'
                }
                dispatch('notification/add', notification, {root: true})


            }).catch(error => {
                const notification = {
                    type: 'error',
                    message: 'There was a problem updating the content category order: ' + error.message
                }
                dispatch('notification/add', notification, {root: true})

                const errors = error.response.data.errors
                Object.keys(errors).forEach(key => {
                    dispatch('error/add', {key, message: errors[key]}, {root: true})
                });

                throw error
            })
    },
    deleteContentCategory({ commit, dispatch}, id) {

      const contentCategory = getters.getContentCategoryById(id)
      const contentCategoryIndex = state.contentCategories.indexOf(contentCategory);

        return ContentCategoryService.deleteContentCategory(id).then(() => {
            commit('DELETE', contentCategoryIndex)

            const notification = {
                type: 'success',
                message: 'Content tag has been deleted!'
            }
            dispatch('notification/add', notification, {root: true})
        }).catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem deleting the content tag: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})
            throw error
        })
    },
    fetchContentCategories({commit, dispatch}, filters) {
      ContentCategoryService.getContentCategories(filters)
      .then(response => {
        commit('SET_LIST', response.data.data)
        commit('SET_META', response.data.meta)
        if(response.data.meta !== undefined) {
            commit('SET_LIST_TOTAL', response.data.meta.total)
        }
        commit('SET_IS_LOADING', false)
      })
      .catch(error => {
        const notification = {
            type: 'error',
            message: 'There was a problem getting the content tags: ' + error.message
        }
        dispatch('notification/add', notification, {root: true})
      })
    },
    fetchContentCategory({commit, dispatch}, id) {

        ContentCategoryService.getContentCategory(id)
        .then(response => {
          commit('SET_ITEM', response.data.data)
          commit('SET_IS_LOADING', false)
        })
        .catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem getting a content tag: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})
        })

    }
  }

export const getters = {
    getContentCategoryById: state => id => {
      return state.contentCategories.find(contentCategory => contentCategory.id == id)
    }
  }
