import apiClient from './apiClient.js'

export default {
    exportOrders(filters) {
        let filtersEncoded = new URLSearchParams(filters).toString();
        return new apiClient({baseURL: process.env.VUE_APP_API_URL, responseType: 'blob'}).get('/orders/export?' + filtersEncoded);
    },
    getOrders(filters) {
        let filtersEncoded = new URLSearchParams(filters).toString();
        return new apiClient().get('/order?' + filtersEncoded);
    },
    getOrder(id) {
        return new apiClient().get('/order/'+id);
    },
    postOrder(order) {
        return new apiClient().post('/order', order);
    },
    generateOrder(order) {
        return new apiClient().post('/order/generate', order);
    },
    updateOrder(order) {
        return new apiClient().patch('/order/'+order.id, order);
    },
    deleteOrder(id) {
        return new apiClient().delete('/order/'+id);
    }
}
