<template>
  <div class="content-categories">
    <h1>Courses & Modules</h1>
    <template v-if="!isFilterActive">
      <BaseButton text="Filter" @click="filterToggle" />
    </template>
    <div v-else class="card">
      <div class="card-divider" style="justify-content: space-between;">
        <h3 class="no-margin">Filter</h3>
        <BaseButton text="X" class="button button--close" @click="filterToggle" />
      </div>
      <div class="card-section gid-container">
        <form @submit.prevent="filterResults" class="grid-x grid-margin-x">
          <div class="cell medium-4 large-3">
            <BaseInput
              v-model="filter.q"
              label="Search"
              type="search"
              name="q"
             />
          </div>
          <div class="cell medium-4 large-3">
            <BaseSelect
              :options="contentCategory.meta.filters.parent || []"
              v-model="filter.parent"
              label="Parent"
              optionValue="id"
              optionName="title"
             />
          </div>

          <div class="cell medium-4 large-3">
            <BaseButton type="submit" text="Filter" style="margin-top: 27px;" />
          </div>
        </form>
      </div>
    </div>
    <router-link :to="{ name: 'content-categories-create'}">
      <BaseButton text="Add new" />
    </router-link>

    <p v-if="isLoading">Loading...</p>
    <template v-else >
      <div>
        <draggable class="dragArea list-group w-full" :list="contentCategory.contentCategories" @change="log">
          <ListLoop v-for="(item) in contentCategory.contentCategories" :key="item.id" :item="item" route="content-categories-detail" itemName="title" draggable :draggableLog="log" >
            <template v-slot:buttons>
              <router-link :to="{ name: 'content', query: { category: item.id }}"><BaseButton text="View Content" class="button--outline" /></router-link>
              <router-link :to="{ name: 'content-categories-detail', params: { id: item.id }}"><BaseButton text="View" /></router-link>
            </template>
          </ListLoop>
        </draggable>
      </div>

      <Pagination :page="page" :perPage="perPage" :filter="filter" routeName="content-categories" :total="totalContentCategories" />
    </template>

  </div>
</template>

<script>
    import ListLoop from '@/components/ListLoop.vue'
    import Pagination from '@/components/Pagination.vue'
    import { mapState } from 'vuex'
    import { VueDraggableNext } from 'vue-draggable-next'

    export default {
        data() {
          return {
            isFilterActive: false,
            filter: {
              q: this.$route.query.q || '',
              perPage: 10,
              page: parseInt(this.$route.query.page) || 1,
              parent: this.$route.query.parent || 'all',
            },
            enabled: true,
            dragging: false
          }
        },
        components: {
          ListLoop,
          Pagination,
          draggable: VueDraggableNext,
        },
        created() {
          this.$store.dispatch('contentCategory/fetchContentCategories', this.filter)
        },
        computed: {
          perPage(){
            return this.filter.perPage;
          },
          page() {
            return this.filter.page;
          },
          totalContentCategories() {
            return this.contentCategory.contentCategoriesTotal
          },
          isLoading() {
            return this.contentCategory.isLoading
          },
          ...mapState(['contentCategory'])
        },
        methods : {
          filterToggle() {
            this.isFilterActive = (this.isFilterActive) ? false : true
          },
          filterResults() {
            this.$store.dispatch('contentCategory/fetchContentCategories', this.filter)
          },
          log(data) {
            let order = [];

            data = (data.moved) ? this.contentCategory.contentCategories : data

            Object.keys(data).forEach(key => {
              order.push(data[key].id);
            })

            this.$store.dispatch('error/clear')
            this.$store.dispatch('contentCategory/updateContentCategoryOrder', { order: order})
          },
        }

    }
</script>
