<template>
  <div class="cell" style="margin-top: 30px; max-height: 282px;">
    <router-link :to="'/media-library/'+mediaObject.id" v-if="$route.name == 'media-library-index'">
        <img v-if="['jpg', 'jpeg', 'png', 'svg'].indexOf(mediaObject.file_type) >= 0"
             :src=mediaObject.temporaryURL alt="">
        <img v-else src="@/assets/document.png" alt="">
        <div class="overlay">
          <p><strong>Filename:</strong> {{ mediaObject.file_name }}</p>
          <p><strong>Uploaded:</strong> {{ new Date(mediaObject.created_at).toLocaleDateString('en-GB') }}</p>
          <BaseButton class="button button--remove" text="Remove" @click.prevent="remove(mediaObject.id)"/>
        </div>
    </router-link>
    <div v-else @click="setSelectedMedia(mediaObject.id); $emit('close-reveal');" class="media-select">
      <img v-if="['jpg', 'jpeg', 'png', 'svg'].indexOf(mediaObject.file_type) >= 0" :src=mediaObject.temporaryURL
           alt="">
      <img v-else src="@/assets/document.png" alt="">
      <div class="overlay">
        <p><strong>Filename:</strong> {{ mediaObject.file_name }}</p>
        <p><strong>Uploaded:</strong> {{ new Date(mediaObject.created_at).toLocaleDateString('en-GB') }}</p>
        <BaseButton class="button button--remove" text="Remove" @click.prevent="remove(mediaObject.id)"/>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  props: {
    mediaObject: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState(['media'])
  },
  methods: {
    setSelectedMedia(mediaID) {
      this.$store.dispatch('media/setSelectedMedia', { mediaID })
    },
    remove(mediaID) {
      this.$store.dispatch('media/deleteMedia', {
        mediaID
      }).then(() => {
        this.$router.go()
      }).catch(err => {
        this.error = err
      })
    },
  }
}
</script>

<style scoped>
  .media-select > img:hover, .media-select > div:hover {
    cursor: pointer;
  }

  .cell {
    position: relative;
    border: 1px solid #c1c1c1;
    border-radius: 2px;
  }

  .cell img {
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  .cell > div {
    height: 100%;
  }

  .cell > div:hover {
    cursor: default;
  }

  .overlay {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color: white;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 15px;
  }

  .cell:hover {
    cursor: pointer;
  }

  .cell:hover > a > .overlay, .cell:hover > div > .overlay {
    display: block;
  }

  .button--remove {
    position: absolute;
    top: 15px;
    right: 15px;
  }
</style>
