import AuthenticationService from '@/services/AuthenticationService.js'
import {localAuthData, localAuthDataRemove, localAuthDataSet} from '@/services/localStore'

export const namespaced = true

export const state = {
  authUser: false,
  formOptions: {
    data: {},
    countries: []
  },
}
export const mutations = {

    LOGIN(state, userData) {
        state.authUser = userData
        localAuthDataSet(userData)
    },
    LOGOUT() {
      localAuthDataRemove()
      location.reload()
    },
    LOGIN_RESET() {
      state.authUser = false
    },
    SET_FORM_OPTIONS(state, data) {
      state.formOptions = data
    },
}

export const actions = {
  login({commit, dispatch}, credentials) {
    return AuthenticationService.login(credentials).then(({data}) => {
              commit('LOGIN', data)
            }).catch(error => {

              const notification = {
                  type: 'error',
                  message: error.response.data.message
              }
              dispatch('notification/add', notification, {root: true})

              const errors = error.response.data.errors
              Object.keys(errors).forEach(key => {
                  dispatch('error/add', {key, message: errors[key]}, {root: true})
                })

              throw error
            });
  },
  logout({commit}) {
    commit('LOGOUT')
  },
  resetLogin({commit}) {
    commit('LOGIN_RESET')
  },
  autoLogin({commit}) {
    const authData = localAuthData
    commit('LOGIN', authData)
  },
  fetchFormOptions({commit, dispatch}) {
    return AuthenticationService.getFormOptions().then(response => {
              commit('SET_FORM_OPTIONS', response.data)

            }).catch(error => {

              const notification = {
                  type: 'error',
                  message: error.response.data.message
              }
              dispatch('notification/add', notification, {root: true})

              throw error
            });
  },
}

export const getters = {
  loggedIn (state) {
    return !!state.authUser
  },
  authToken (state) {
    if (state.authUser) {
      return state.authUser.access_token
    }
    return null
  },
}
