<template>
    <label v-if="label" >{{ label}}</label>
    <select
        :value="modelValue"
        v-bind="{
            ...$attrs,
            onChange: ($event) => { $emit('update:modelValue', $event.target.value)}
        }"
    >
        <option
            v-for="(option, key) in options"
            :value="optionValueCalculated(key)"
            :key="option"
            :selected="option === modelValue"
            >
            <template v-if="optionName === 'option'">{{ option }}</template>
            <template v-else>{{ option[optionName] }}</template>
        </option>


    </select>
    <template v-if="errors.length > 0">
      <span class="message message--error" v-for="(message, keyMessage) in fieldErrors" :key="keyMessage">{{ message }}</span>
    </template>


</template>

<script>
import { mapState } from 'vuex'
export default {
    props: {
        name: String,
        label: {
            type: String,
            default: ''
        },
        modelValue: {
            type: [String, Number],
            default: ''
        },
        options: {
            type: Array,
            required: true
        },
        optionValue: {
            type: String,
            default: 'option'
        },
        optionName: {
            type: String,
            default: 'option'
        },
        error: Object,
        type: String
    },
    methods: {
        optionValueCalculated(key) {
          if(!Array.isArray(this.options)) {
            return key
          } else {
            if (this.optionValue === 'option') {
              return this.options[key]
            } else {
              return this.options[key][this.optionValue]
            }
          }
        }
    },
    computed: {

        ...mapState('error', ['errors']),
        fieldErrorClass() {
            if (this.errors.length > 0) {
                if (this.errors.find(error => error.key == this.name)) {
                    return 'error';
                }
            }
            return ''
        },
        fieldErrors() {
            if (this.errors.length > 0) {
                if (this.errors.find(error => error.key == this.name)) {
                    if (this.errors.find(error => error.key == this.name).message.length > 0) {
                        return this.errors.find(error => error.key == this.name).message
                    }
                }
            }
            return []
        },
    }

}
</script>

<style scoped>

</style>
