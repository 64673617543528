<template v-if="options.length">
  <p :class="{'centre': ui}" >{{ label }}:</p>
  <div :class="{'radio-ui': ui || lozenge || bar, 'radio-ui--centre': ui}">
    <component
      v-for="option in options"
      :key="option[optionValue]"
      :option="option"
      :is="(ui || lozenge) ? 'div' : 'span'"
      :class="{
        'radio-ui__box': ui,
        'radio-ui__lozenge': lozenge,
        'radio-ui__bar': bar,
      }"
    >
      <BaseRadio
        :label="option[optionName]"
        :value="option[optionValue]"
        :name="name"
        :icon="option[optionIcon]"
        :modelValue="modelValue"
        @update:modelValue="$emit('update:modelValue', $event)" />
    </component>
  </div>

  <template v-if="errors.length > 0">
    <span class="message message--error" v-for="(message, keyMessage) in fieldErrors" :key="keyMessage">{{ message }}</span>
  </template>

</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    modelValue: {
      type: [String, Number],
      required: true,
    },
    name: {
      type: String,
      required: true
    },
    options: {
        type: Array,
        required: true
    },
    optionValue: {
        type: String,
        default: 'value'
    },
    optionName: {
        type: String,
        default: 'option'
    },
    optionIcon: {
        type: [String, Boolean],
        default: false
    },
    lozenge :{
      type: Boolean,
      defualt: false
    },
    bar :{
      type: Boolean,
      defualt: false
    },
    ui :{
      type: Boolean,
      defualt: false
    }
  },
  computed: {
    ...mapState('error', ['errors']),
    fieldErrorClass() {
      if (this.errors.length > 0) {
        if (this.errors.find(error => error.key == this.name)) {
          return 'error';
        }
      }
      return ''
    },
    fieldErrors() {
      if (this.errors.length > 0) {
        if (this.errors.find(error => error.key == this.name)) {
          if (this.errors.find(error => error.key == this.name).message.length > 0) {
            return this.errors.find(error => error.key == this.name).message
          }
        }
      }
      return []
    },

  }
}
</script>

<style lang="scss">
@import "@/assets/scss/_settings.scss";
p.centre {
  text-align: center;
}
.radio-ui {
  display: flex;
  flex-wrap: wrap;
  margin-top: rem-calc(10);
  margin-bottom: rem-calc(10);

  &--centre {
    justify-content: center;
  }

  &__lozenge {

    overflow: hidden;

    display: flex;
    border-left: 1px $light-grey solid;
    border-right-width: 0;


    input:checked + label, input:not(:checked) + label {
      text-align: center;
      padding: 5px 20px;
      margin: 0;
      background-color: $cotton;
      transition: .25s;
      border: 0;


      &:before, &:after {
        display: none;
      }
    }

    &:first-of-type {
      border-bottom-left-radius: 200px;
      border-top-left-radius: 200px;
      border-left-width: 0px;
    }

    &:last-of-type {
      border-bottom-right-radius: 200px;
      border-top-right-radius: 200px;
      border-right-width: 0px;
    }

    input:checked + label {
      background-color: #009eb5;
      color: $cotton;
    }

  }


  &__bar {

    overflow: hidden;

    display: flex;
    border: 0;
    margin: 1px;


    input:checked + label, input:not(:checked) + label {
      text-align: center;
      padding: 5px 20px;
      margin: 0;
      background-color: $cotton;
      transition: .25s;
      border: 0;


      &:before, &:after {
        display: none;
      }
    }

    input:checked + label {
      background-color: #009eb5;
      color: $cotton;
    }

  }


  &__box {

    overflow: hidden;
    display: flex;
    margin: rem-calc(5);



    input:checked + label, input:not(:checked) + label {
      text-align: center;
      padding: 20px;
      margin: 0;
      background-color: $cotton;
      transition: .25s;
      border: 0;
      border-radius: 2px;

      &:hover {
        background-color: $cotton-dark;
      }

      &:before, &:after {
        display: none;
      }
    }

    input:checked + label {
      background-color: #009eb5;
      color: $cotton;
    }

    label {
      display: flex;
      flex-direction: column;
    }

  }

}



</style>
