<template>
  <div class="users">
    <h1>Shipping Rate : {{ shipping.title }}</h1>
    <div>
    <p v-if="isLoading">Loading...</p>
    <template v-else >

      <div class="grid-x grid-margin-x" >
        <div class="panel cell medium-8">
          <div class="panel__header">
            <h3>Rate Details</h3>
            <button class="button" @click="edit">Edit</button>
          </div>
          <template v-if="!isEditing">
            <p><strong>{{ shipping.title }}</strong></p>
            <p><strong class="info badge badge--lozenge badge--margin">Cost: &pound;{{ shipping.price }}</strong>  <strong class="info badge badge--lozenge badge--margin">Tax: {{ shipping.tax }}%</strong></p>
          </template>
          <template v-else>
            <form @submit.prevent="update">

              <BaseInput
                v-model="shipping.title"
                label="Title"
                type="text"
                name="title"
               />
              <BaseInput
                v-model="shipping.price"
                label="Cost"
                type="text"
                name="cost"
               />
              <BaseInput
                v-model="shipping.tax"
                label="Tax"
                type="text"
                name="tax"
               />

              <BaseButton type="submit" text="Update" />
          </form>
          </template>

        </div>
        <div class="panel cell medium-4">
          <p>Click below to delete this shipping rate.</p>
          <BaseButton text="Delete" @click="remove" class="button button--danger" />
        </div>

      </div>
      <div class="grid-x grid-margin-x" >
        <div class="panel cell medium-12">
          <h3 class="no-margin">Countries</h3>
          <p>Countries listed here fall under this shipping charge</p>
          <div >
            <template v-if="countriesAssigned.length">
              <span v-for="(item) in countriesAssigned" :key="item.id" :item="item" class="tag" >
                {{ item.code }} : {{ item.name }}
                <BaseButton class="button button--remove" text="Remove" @click.prevent="removeCountry(item.id)" />
              </span>
            </template>
            <p v-else class="warning badge badge--lozenge badge--margin">No countries have been assigned to this rate yet</p>

            <template v-if="countriesAvailable.length">
              <form class="form-min">
                <BaseAutoComplete
                  v-model="shipping.addCountry"
                  :options="countriesAvailable"
                  subTitle="name"
                  placeholder="Search to assign a country..."
                 />
                <BaseButton v-if="!shipping.addCountry" text="Save" disabled />
                <BaseButton v-else text="Save" @click.prevent="update"  />
              </form>
            </template>
          </div>
        </div>
      </div>
    </template>
    </div>
  </div>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        props: ["id"],
        data() {
          return {
            isEditing: false
          }
        },
        components : {

        },
        created() {
          this.$store.dispatch('error/clear')
          this.$store.dispatch('shipping/fetchShipping', this.id)

        },
        computed: {
          isLoading() {
            return this.shipping.isLoading
          },
          isDisabled() {
            return false
          },
          ...mapState({
            shipping: state => state.shipping.shipping
          }),
          countriesAssigned() {
            return (this.shipping.countries) ? this.shipping.countries.assigned : []
          },
          countriesAvailable() {
            return (this.shipping.countries) ? this.shipping.countries.all : []
          },
        },
        methods: {
          remove() {
            this.$store.dispatch('shipping/deleteShipping', this.id)
          },
          update() {
            this.$store.dispatch('error/clear')
            this.$store.dispatch('shipping/updateShipping', this.shipping)
            this.isEditing = false
          },
          edit() {
            this.isEditing = (this.isEditing) ? false : true

          },
          removeCountry(country) {
            this.shipping.addCountry = null
            this.shipping.removeCountry = country
            this.update()
          },
        }

    }
</script>
