import apiClient from './apiClient.js'


export default {
    newAddress(id) {
        return new apiClient().get('/users/'+id+'/addresses/create');
    },
    getAddresses(id) {
        return new apiClient().get('/users/'+id+'/addresses');
    },
    getAddress(id, addressID) {
        return new apiClient().get('/users/'+id+'/addresses/'+addressID);
    },
    postAddress(id, address) {
        return new apiClient().post('/users/'+id+'/addresses/'+address.id, address);
    },
    deleteAddress(id, addressID) {
        return new apiClient().delete('/users/'+id+'/addresses/'+addressID);
    },
    createAddress(id, address) {
        return new apiClient().post('/users/'+id+'/addresses/', address);
    },
    authCheck(authToken) {

        if (authToken === null) {
            console.log('not logged in');
        }

    }
}
