<template>
  <div class="users">
    <h1>Product Tag : {{ productTag.title }}</h1>
    <div>
    <p v-if="isLoading">Loading...</p>
    <template v-else >

      <div class="grid-x grid-margin-x" >
        <div class="panel cell medium-4">
          <h3>Tag Details</h3>
          <button class="button" @click="edit">Edit</button>
          <template v-if="!isEditing">
            <p>{{ productTag.title }}</p>
            <p>{{ productTag.slug }}</p>
            <p>{{ productTag.type }}</p>
          </template>
          <template v-else>
            <form @submit.prevent="update">

              <BaseInput
                v-model="productTag.title"
                label="Title"
                type="text"
                name="title"
               />
              <BaseInput
                v-model="productTag.slug"
                label="Slug"
                type="text"
                name="slug"
               />

              <BaseButton type="submit" text="Update" />
          </form>
          </template>

        </div>

      </div>
      <div class="grid-x grid-margin-x" >
        <div class="panel cell medium-6">
          <p>Click below to delete this product tag.</p>
          <BaseButton text="Delete" @click="remove" class="button button--danger" />
        </div>
      </div>
    </template>
    </div>
  </div>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        props: ["id"],
        data() {
          return {
            isEditing: false
          }
        },
        components : {

        },
        created() {
          this.$store.dispatch('error/clear')
          this.$store.dispatch('productTag/fetchProductTag', this.id)

        },
        computed: {
          isLoading() {
            return this.productTag.isLoading
          },
          isDisabled() {
            return false
          },
          ...mapState({
            productTag: state => state.productTag.productTag
          })
        },
        methods: {
          remove() {
            this.$store.dispatch('productTag/deleteProductTag', this.id)
          },
          update() {
            this.$store.dispatch('error/clear')
            this.$store.dispatch('productTag/updateProductTag', this.productTag)
            this.isEditing = false
          },
          edit() {
            this.isEditing = (this.isEditing) ? false : true

          }
        }

    }
</script>
