<template>
  <h1>Saved content</h1>

  <p v-if="isLoading">Loading...</p>

  <div v-else>
    <div v-for="(favourite) in user.favourite" :key="favourite.id">
      <div v-if="!favourite.deleted_at" class="loop-list">
        <div class="loop-list__details">
          <div>
            <p>{{ favourite.title }}</p>
            <p>Saved content: {{ favourite.created_at }}</p>
            <p v-if="favourite.deleted_at">Removed content: {{ favourite.deleted_at }}</p>
          </div>
          <div>
            <router-link :to="{ name: 'content-detail', params: { id: favourite.id }}"><BaseButton text="View Content" /></router-link>
          </div>
        </div>
      </div>
      <div v-else class="loop-list loop-list--deleted">
        <div class="loop-list__details">
          <div>
            <p>{{ favourite.title }}</p>
            <p>Saved content: {{ favourite.created_at }}</p>
            <p v-if="favourite.deleted_at">Removed content: {{ favourite.deleted_at }}</p>
          </div>
          <div>
            <router-link :to="{ name: 'content-detail', params: { id: favourite.id }}"><BaseButton text="View Content" /></router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapState} from "vuex";

  export default {
    props: ["userID"],
    created() {
      this.$store.dispatch('user/fetchFavourite', { id: this.userID })
    },
    computed: {
      isLoading() {
        return this.user.isLoading
      },
      ...mapState(['user'])
    }
  }
</script>

<style scoped>
  .loop-list--deleted {
    background-color: #ff00001c;
  }
</style>