import { createRouter, createWebHistory } from 'vue-router'
import {localAuthData} from '@/services/localStore'


import ContentCategories from '../views/content/categories/Index.vue'
import ContentCategoriesCreate from '../views/content/categories/Create.vue'
import ContentCategoriesDetail from '../views/content/categories/Detail.vue'
import ContentCreate from '../views/content/Create.vue'
import ContentDetail from '../views/content/Detail.vue'
import ContentList from '../views/content/Index.vue'
import ContentViews from '../views/content/Views'
import ContentViewsDetail from '../views/content/ViewsDetail'
import Dashboard from '../views/Dashboard.vue'
import ErrorNotFound from '../views/ErrorNotFound404.vue'
import Login from '../views/authentication/Login.vue'
import Media from '../views/media/Index.vue'
import MediaLibraryIndex from '../views/media-library/Index.vue'
import Message from '../views/messages/Index.vue'
import NotificationsCreate from '../views/push-notifications/Create.vue'
import NotificationsDetail from '../views/push-notifications/Detail.vue'
import NotificationsList from '../views/push-notifications/Index.vue'
import RolesCreate from '../views/roles/Create.vue'
import RolesDetail from '../views/roles/Detail.vue'
import RolesList from '../views/roles/Index.vue'
import FormsCreate from '../views/forms/Create.vue'
import FormsDetail from '../views/forms/Detail.vue'
import FormsList from '../views/forms/Index.vue'
import Setting from '../views/setting/Index.vue'
import SettingDetail from '../views/setting/Detail.vue'
import SingleMediaDetail from '../views/media-library/Detail'
import UsersCreate from '../views/users/Create.vue'
import UsersDetail from '../views/users/Detail.vue'
import UsersList from '../views/users/Index.vue'

import Orders from '../views/orders/Index.vue'
import OrdersDetail from '../views/orders/Detail.vue'
import Baskets from '../views/orders/baskets/Index.vue'
import ProductCategories from '../views/product/categories/Index.vue'
import ProductCategoriesCreate from '../views/product/categories/Create.vue'
import ProductCategoriesDetail from '../views/product/categories/Detail.vue'
import ProductCreate from '../views/product/Create.vue'
import ProductDetail from '../views/product/Detail.vue'
import ProductList from '../views/product/Index.vue'
import ProductTags from '../views/product/tags/Index.vue'
import ProductTagsCreate from '../views/product/tags/Create.vue'
import ProductTagsDetail from '../views/product/tags/Detail.vue'
import Discount from '../views/discount/Index.vue'
import DiscountCreate from '../views/discount/Create.vue'
import DiscountDetail from '../views/discount/Detail.vue'
import Shipping from '../views/shipping/Index.vue'
import ShippingCreate from '../views/shipping/Create.vue'
import ShippingDetail from '../views/shipping/Detail.vue'
import SubscriptionPlans from '../views/subscriptions/plans/Index.vue'
import SubscriptionPlansCreate from '../views/subscriptions/plans/Create.vue'
import SubscriptionPlansDetail from '../views/subscriptions/plans/Detail.vue'
import Subscriptions from '../views/subscriptions/Index.vue'
import SubscriptionsDetail from '../views/subscriptions/Detail.vue'
import Tax from '../views/tax/Index.vue'
import TaxCreate from '../views/tax/Create.vue'
import TaxDetail from '../views/tax/Detail.vue'
import UsersFavourites from '../views/users/Favourites.vue'
import UserFavouriteDetail from '../views/users/FavouriteDetail'
import UsersAddress from '../views/addresses/Update.vue'
import UsersAddressCreate from '../views/addresses/Create.vue'
import UsersAddresses from '../views/addresses/Index.vue'

const routes = [

  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: { requiresNoAuth: true }
  },
  {
    path: '/',
    name: 'dashboard',
    component: Dashboard,
    meta: { requiresAuth: true }
  },
  {
    path: '/users',
    name: 'users',
    component: UsersList,
    meta: { requiresAuth: true }
  },
  {
    path: '/users/:id',
    name: 'users-detail',
    component: UsersDetail,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/users/create',
    name: 'users-create',
    component: UsersCreate,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/roles',
    name: 'roles',
    component: RolesList,
    meta: { requiresAuth: true }
  },
  {
    path: '/roles/:id',
    name: 'roles-detail',
    component: RolesDetail,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/roles/create',
    name: 'roles-create',
    component: RolesCreate,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/push-notifications',
    name: 'push-notifications',
    component: NotificationsList,
    meta: { requiresAuth: true }
  },
  {
    path: '/push-notifications/:id',
    name: 'push-notifications-detail',
    component: NotificationsDetail,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/push-notifications/create',
    name: 'push-notifications-create',
    component: NotificationsCreate,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/forms',
    name: 'forms',
    component: FormsList,
    meta: { requiresAuth: true }
  },
  {
    path: '/forms/:id',
    name: 'forms-detail',
    component: FormsDetail,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/forms/create',
    name: 'forms-create',
    component: FormsCreate,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/content',
    name: 'content',
    component: ContentList,
    meta: { requiresAuth: true }
  },
  {
    path: '/content/:id',
    name: 'content-detail',
    component: ContentDetail,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/content/create',
    name: 'content-create',
    component: ContentCreate,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/content/views',
    name: 'content-views',
    component: ContentViews,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/content/views/:id',
    name: 'content-views-detail',
    component: ContentViewsDetail,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/content-categories',
    name: 'content-categories',
    component: ContentCategories,
    meta: { requiresAuth: true }
  },
  {
    path: '/content-categories/:id',
    name: 'content-categories-detail',
    component: ContentCategoriesDetail,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/content-categories/create',
    name: 'content-categories-create',
    component: ContentCategoriesCreate,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/media',
    name: 'media',
    component: Media,
    meta: { requiresAuth: true }
  },
  {
    path: '/media-library',
    name: 'media-library-index',
    component: MediaLibraryIndex,
    meta: { requiresAuth: true }
  },
  {
    path: '/media-library/:mediaID',
    name: 'single-media-detail',
    component: SingleMediaDetail,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/setting',
    name: 'setting',
    component: Setting,
    meta: { requiresAuth: true }
  },
  {
    path: '/setting/:id',
    name: 'setting-detail',
    component: SettingDetail,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/message',
    name: 'message',
    component: Message,
    meta: { requiresAuth: true }
  },


  {
    path: '/product',
    name: 'product',
    component: ProductList,
    meta: { requiresAuth: true }
  },
  {
    path: '/product/:id',
    name: 'product-detail',
    component: ProductDetail,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/product/create',
    name: 'product-create',
    component: ProductCreate,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/product-categories',
    name: 'product-categories',
    component: ProductCategories,
    meta: { requiresAuth: true }
  },
  {
    path: '/product-categories/:id',
    name: 'product-categories-detail',
    component: ProductCategoriesDetail,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/product-categories/create',
    name: 'product-categories-create',
    component: ProductCategoriesCreate,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/product-tags',
    name: 'product-tags',
    component: ProductTags,
    meta: { requiresAuth: true }
  },
  {
    path: '/product-tags/:id',
    name: 'product-tags-detail',
    component: ProductTagsDetail,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/product-tags/create',
    name: 'product-tags-create',
    component: ProductTagsCreate,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/subscriptions',
    name: 'subscriptions',
    component: Subscriptions,
    meta: { requiresAuth: true }
  },
  {
    path: '/subscriptions/plans',
    name: 'subscription-plans',
    component: SubscriptionPlans,
    meta: { requiresAuth: true }
  },
  {
    path: '/subscriptions/plans/:id',
    name: 'subscription-plans-detail',
    component: SubscriptionPlansDetail,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/subscriptions/plans/create',
    name: 'subscription-plans-create',
    component: SubscriptionPlansCreate,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/subscriptions/:id',
    name: 'subscriptions-detail',
    component: SubscriptionsDetail,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/orders',
    name: 'orders',
    component: Orders,
    meta: { requiresAuth: true }
  },
  {
    path: '/orders/:id',
    name: 'orders-detail',
    component: OrdersDetail,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/orders/baskets',
    name: 'baskets',
    component: Baskets,
    meta: { requiresAuth: true }
  },
  {
    path: '/users/:id/addresses',
    name: 'users-addresses',
    component: UsersAddresses,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/users/:id/addresses/:addressID',
    name: 'users-address',
    component: UsersAddress,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/users/:id/addresses/create',
    name: 'users-address-create',
    component: UsersAddressCreate,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/users/favourites',
    name: 'users-favourites',
    component: UsersFavourites,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/users/favourites/:userID',
    name: 'user-favourite-detail',
    component: UserFavouriteDetail,
    props: true,
    meta: { requiresAuth: true }
  },

  {
    path: '/discount',
    name: 'discount',
    component: Discount,
    meta: { requiresAuth: true }
  },
  {
    path: '/discount/:id',
    name: 'discount-detail',
    component: DiscountDetail,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/discount/create',
    name: 'discount-create',
    component: DiscountCreate,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/shipping',
    name: 'shipping',
    component: Shipping,
    meta: { requiresAuth: true }
  },
  {
    path: '/shipping/:id',
    name: 'shipping-detail',
    component: ShippingDetail,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/shipping/create',
    name: 'shipping-create',
    component: ShippingCreate,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/tax',
    name: 'tax',
    component: Tax,
    meta: { requiresAuth: true }
  },
  {
    path: '/tax/:id',
    name: 'tax-detail',
    component: TaxDetail,
    props: true,
    meta: { requiresAuth: true }
  },

  {
    path: '/tax/create',
    name: 'tax-create',
    component: TaxCreate,
    props: true,
    meta: { requiresAuth: true }
  },

  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: ErrorNotFound
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if ((localAuthData === null) && to.matched.some(record => record.meta.requiresAuth)) {
      next('/login')
  } else if ((localAuthData !== null) && to.matched.some(record => record.meta.requiresNoAuth)) {
      next('/')
  } else {
    next()
  }
})

export default router
