import apiClient from './apiClient.js'

export default {
    newProduct() {
        return new apiClient().get('/product/create');
    },
    getProductList(filters) {
        let filtersEncoded = new URLSearchParams(filters).toString();
        return new apiClient().get('/product?' + filtersEncoded);
    },
    getOptionsFullList() {
        return new apiClient().get('/product/options');
    },
    getProduct(id) {
        return new apiClient().get('/product/'+id);
    },
    postProduct(product) {
        return new apiClient().post('/product', product);
    },
    updateProduct(product) {
        return new apiClient().patch('/product/'+product.id, product);
    },
    deleteProduct(id) {
        return new apiClient().delete('/product/'+id);
    },
    postOption(option) {
        return new apiClient().post('/product-options', option);
    },
    updateOption(optionID, option) {
        return new apiClient().patch('/product-options/'+optionID, option);
    },
    deleteOption(data) {
        return new apiClient().delete('/product-options/'+data.id);
    },
    orderOption(data) {
        return new apiClient().patch('/product-options/order/'+ data.product, data.order);
    },
}
