import apiClient from './apiClient.js'

export default {
    getShippingRates(filters) {
        let filtersEncoded = new URLSearchParams(filters).toString();
        return new apiClient().get('/shipping?' + filtersEncoded);
    },
    getShipping(id) {
        return new apiClient().get('/shipping/'+id);
    },
    postShipping(shipping) {
        return new apiClient().post('/shipping', shipping);
    },
    updateShipping(shipping) {
        return new apiClient().patch('/shipping/'+shipping.id, shipping);
    },
    deleteShipping(id) {
        return new apiClient().delete('/shipping/'+id);
    }
}
