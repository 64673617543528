import BasketService from '@/services/BasketService.js'

export const namespaced = true

export const state = {
        baskets: [],
        basketsTotal: 0,
        basket: {},
        isLoading: true,
        meta: {},
        stats: {},
    }
export const mutations = {
    SET_LIST(state, baskets) {
        state.baskets = baskets
    },
    SET_META(state, meta) {
        state.meta = meta
    },
    SET_STATS(state, stats) {
        state.stats = stats
    },
    SET_LIST_TOTAL(state, total) {
        state.basketsTotal = total
    },
    SET_IS_LOADING(state, isLoading) {
        state.isLoading = isLoading
    }
  }

export const actions = {

    clearBasket({commit}) {
        commit('UPDATE', {})
    },
    exportBaskets({commit, dispatch}, filters) {

        return BasketService.exportBaskets(filters)
            .then(response => {
                commit('SET_IS_LOADING', false)

                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                var fileLink = document.createElement('a');

                fileLink.href = fileURL;
                fileLink.setAttribute('download', 'TBUBasketExport.csv');
                document.body.appendChild(fileLink);

                fileLink.click();

        })
        .catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem exporting baskets: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})
            throw error
        })
    },
    fetchBaskets({commit, dispatch}, filters) {
      BasketService.getBaskets(filters)
      .then(response => {
        commit('SET_LIST', response.data.data)
        commit('SET_META', response.data.meta)
        commit('SET_STATS', response.data.stats)
        if(response.data.meta !== undefined) {
            commit('SET_LIST_TOTAL', response.data.meta.total)
        }
        commit('SET_IS_LOADING', false)
      })
      .catch(error => {
        const notification = {
            type: 'error',
            message: 'There was a problem getting the baskets: ' + error.message
        }
        dispatch('notification/add', notification, {root: true})
      })
    },
  }

export const getters = {
    getBasketById: state => id => {
      return state.baskets.find(basket => basket.id == id)
    }
  }
