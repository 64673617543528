import { createStore } from 'vuex'
import * as authentication from '@/store/modules/authentication.js'
import * as content from '@/store/modules/content.js'
import * as contentCategory from '@/store/modules/contentCategory.js'
import * as dashboard from '@/store/modules/dashboard.js'
import * as error from '@/store/modules/error.js'
import * as form from '@/store/modules/form.js'
import * as layout from '@/store/modules/layout.js'
import * as media from '@/store/modules/media.js'
import * as message from '@/store/modules/message.js'
import * as notification from '@/store/modules/notification.js'
import * as role from '@/store/modules/role.js'
import * as pushNotification from '@/store/modules/pushNotification.js'
import * as setting from '@/store/modules/setting.js'
import * as user from '@/store/modules/user.js'

import * as address from '@/store/modules/address.js'
import * as basket from '@/store/modules/basket.js'
import * as order from '@/store/modules/order.js'
import * as payment from '@/store/modules/payment.js'
import * as product from '@/store/modules/product.js'
import * as productCategory from '@/store/modules/productCategory.js'
import * as productTag from '@/store/modules/productTag.js'
import * as shipping from '@/store/modules/shipping.js'
import * as subscription from '@/store/modules/subscription.js'
import * as subscriptionPlan from '@/store/modules/subscriptionPlan.js'
import * as tax from '@/store/modules/tax.js'
import * as discount from '@/store/modules/discount.js'


// Create a new store instance.
const store = createStore({
  modules: {
    authentication,
    content,
    contentCategory,
    dashboard,
    discount,
    error,
    form,
    layout,
    media,
    message,
    notification,
    role,
    setting,
    user,
    address,
    basket,
    order,
    payment,
    product,
    productCategory,
    productTag,
    pushNotification,
    shipping,
    subscription,
    subscriptionPlan,
    tax,
  },
  state : {}
})

export default store
