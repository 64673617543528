<template>
    <div>
      <div class="card">
        <div class="card-divider" style="justify-content: space-between">
          <div>
            <BaseButton v-show="isActive" class="button button--minus" text="Minimise" @click="toggleBlock()"/>
            <BaseButton v-show="!isActive" class="button button--plus" text="Expand" @click="toggleBlock()"/>
            <BaseButton v-if="templateKey && allowOrder" class="button button--up" text="Move Up" @click="orderBlock('up')"/>
            <BaseButton v-if="!isLast && allowOrder" class="button button--down" text="Move Down" @click="orderBlock('down')"/>
            <h4 @click="toggleBlock()" >{{ item.title }}</h4>
          </div>

          <div>
            <BaseButton v-if="allowDuplicate" class="button button--duplicate" text="Duplicate" @click="duplicateBlock()"/>
            <BaseButton v-if="allowRemove" class="button button--remove" text="Remove" @click="removeBlock()"/>
          </div>
        </div>
        <div class="card-section" v-show="isActive">
          <ContentBlockField  v-for="(field, fieldKey) in item.structure" :key="field.name" :templateKey="templateKey" :field="field" :fieldKey="fieldKey" allowRemove allowOrder :model="model" />
          <BaseButton class="button button--outline" @click="toggleBlock()" :text="'Close '+item.title" />
        </div>
      </div>
    </div>
</template>

<script>
    import ContentBlockField from '@/components/ContentBlockField'

    export default {
        data() {
          return {
            isActive: false,
          }
        },
        props: {
            templateKey: {
                type: Number,
                required: true
            },
            item: {
                type: Object,
                required: true
            },
            blocksTotal: {
                type: Number,
                required: true
            },
            allowOrder: {
                type: Boolean,
                defualt: false
            },
            allowRemove: {
                type: Boolean,
                defualt: false
            },
            allowDuplicate: {
                type: Boolean,
                defualt: false
            },
            model: {
              type: String,
              default: 'content'
            }
        },
        components : {
          ContentBlockField
        },
        computed: {
          isLast() {
            return (this.blocksTotal-1) === this.templateKey
          }
        },
        methods: {
          removeBlock() {
            this.$store.dispatch(this.model + '/deleteBlock', { templateKey: this.templateKey })
          },
          duplicateBlock() {
            this.$store.dispatch(this.model + '/duplicateBlock', { templateKey: this.templateKey })
          },
          toggleBlock() {
            this.isActive = this.isActive ? false : true
          },
          orderBlock(direction) {
            this.$store.dispatch(this.model + '/orderBlock', { key: this.templateKey, direction: direction })
          }
        }
    }
</script>

<style scoped>

</style>
