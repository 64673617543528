<template>
  <div class="product-tag">
    <h1>Product Tags - Create</h1>
    <div>
      <form @submit.prevent="createProductTag">

        <BaseInput
          v-model="productTag.title"
          label="Title"
          type="text"
          name="title"
         />

        <BaseButton type="submit" text="Add New Tag" />

      </form>
    </div>
  </div>
</template>

<script>
    // import Datepicker from 'vue3-datepicker'

    export default {
        data() {
          return {
            productTag: this.createFreshRoleObject(),
            error: {},

          }
        },
        methods: {
          createProductTag() {
            this.$store.dispatch('error/clear')
            this.$store.dispatch('productTag/createProductTag', this.productTag).then(response => {
              this.$router.push({
                name: 'product-tag-detail',
                params: { id: response.id }
              })
              this.productTag = this.createFreshRoleObject()
            }).catch(err => {
                this.error = err
            })
          },
          createFreshRoleObject() {
            const productTag = this.$store.state.productTag
            const id = Math.floor(Math.random*10000)

            return {
              id: id,
              productTag: productTag
            }
          }
        }

    }
</script>
