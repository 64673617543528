<template >
    <div class="card">
      <div class="card-divider" ><h3>Navigation Items</h3></div>
      <div class="card-section" >
        <NavBuilderField  v-for="(field, fieldKey) in setting.data" :key="field.title"  :field="field" :fieldKey="fieldKey" :fieldTotal="setting.data.length || 0" />
        <BaseButton text="Add item" @click="additem" />
      </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import NavBuilderField from '@/components/NavBuilderField'

    export default {
        data() {
          return {
            isActive: false,
            blankField: {
                    name: '',
                    type: 'internal',
                    link: '/',
                    subItems: []
                  }
          }
        },
        props: {
          model: {
            type: String,
            default: 'content'
          }
        },
        components : {
          NavBuilderField
        },
        computed: {
          ...mapState({
            setting: state => state.setting.setting,
            isLoading: state => state.setting.isLoading,
          })
        },
        methods: {
          additem() {
            this.setting.data.push(this.blankField)
            console.log(this.setting.data)
            this.$store.dispatch('error/clear')
            this.$store.dispatch('setting/updateSetting', this.setting)
          }
        }
    }
</script>

<style scoped>

</style>
