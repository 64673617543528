<template>
    <BaseButton text="Delete" @click="openReveal" class="button button--danger button--outline" />
    <div class="reveal" id="removeModal" data-reveal>
        <div class="card">
            <div class="card-divider" style="justify-content: space-between">
                <BaseButton data-close aria-label="Close modal" text="no" class="button button--remove" />
            </div>
            <div class="card-section">
                <p class="lead">{{ message }}</p>
                <BaseButton text="Yes" data-close @click="onRemove" />
                <BaseButton data-close  text="No" />
            </div>
        </div>
    </div>
</template>
<script>

    import $ from 'jquery';
    import Foundation from 'foundation-sites';

    export default {

        props: {
            onRemove: {
                type: Function,
                required: true
            },
            message: {
                type: String,
                default: 'Are you sure you want to delete this item?'
            }
        },
        mounted() {
          this.reveal = new Foundation.Reveal($('#removeModal'), {
            // These options can be declarative using the data attributes
            animationIn: 'scale-in-up',
          });
        },
        methods: {
          remove() {
            this.$store.dispatch('content/deleteContent', this.id)
          },
          openReveal() {
            this.reveal.open();
          },
        },
        unmounted() {
          this.reveal.destroy();
        }

    }
</script>
